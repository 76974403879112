import CustomizeAgent from 'components/app/AIAgent/CustomizeAgent'
import HandoffRules from 'components/app/AIAgent/HandoffRules'
import KnowledgeBaseForm from 'components/app/AIAgent/KnowledgeBaseForm'
import KnowledgeBaseTable from 'components/app/AIAgent/KnowledgeBaseTable'
import { Divider, FlexRow } from 'components/lib'
import Tabs from 'components/lib/Navigation/Tabs'
import {
  getAssistantConfiguration,
  getKnowledgeBases,
} from 'api/assistant/knowledgeBase'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  allKnowledgeBasesAtom,
  assistantConfigAtom,
  authenticatedUserAtom,
  knowledgeBaseFormAtom,
} from 'state/atoms'
import { useTheme } from 'styled-components'
import { toastError } from 'components/lib/Toast'

const agentTabs = [
  {
    name: 'Knowledge',
  },
  {
    name: 'Handoff rules',
  },
  {
    name: 'Customize agent',
  },
]

const AIAgent = () => {
  const theme = useTheme()
  const [currentTab, setCurrentTab] = React.useState(0)
  const knowledgeBaseForm = useRecoilValue(knowledgeBaseFormAtom)
  const { selectedContact } = useRecoilValue(authenticatedUserAtom)
  const [, setKnowledgeBases] = useRecoilState(allKnowledgeBasesAtom)
  const [assistantConfig, setAssistantConfig] =
    useRecoilState(assistantConfigAtom)
  const [isKBLoading, setIsKBLoading] = useState(false)
  const [isConfigLoading, setIsConfigLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedContact?.companyId) return
      setIsKBLoading(true)
      setIsConfigLoading(true)
      try {
        const kbResponse = await getKnowledgeBases(selectedContact.companyId)
        setKnowledgeBases(kbResponse)
        setIsKBLoading(false)
      } catch (error) {
        toastError('Error fetching knowledge bases')
        setIsKBLoading(false)
      }

      try {
        const configResponse = await getAssistantConfiguration(
          selectedContact.companyId,
        )
        setAssistantConfig(configResponse)
        setIsConfigLoading(false)
      } catch (error) {
        toastError('Error fetching assistant information')
        setIsConfigLoading(false)
      }
    }

    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact?.companyId])

  return (
    <div
      style={{ padding: 20, paddingTop: 0, marginLeft: 20, marginBottom: 50 }}
    >
      {!knowledgeBaseForm.isOpen ? (
        <>
          <h2 style={{ marginTop: 10 }}>AI agent</h2>
          <p style={{ color: theme.colors.text250, marginBottom: 15 }}>
            Customize your Super AI with knowledge, instructions on how to
            handoff tasks, how it sounds, and what it is and is not allowed to
            answer.
          </p>

          <FlexRow justify="space-between">
            <Tabs
              tabs={agentTabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              customStyle="margin-left: 0;"
            />
            <Divider style={{ marginTop: 45, marginBottom: 0 }} />
          </FlexRow>

          {currentTab === 0 && <KnowledgeBaseTable isLoading={isKBLoading} />}
          {currentTab === 1 && <HandoffRules />}
          {currentTab === 2 && (
            <CustomizeAgent
              initialValues={assistantConfig}
              isLoading={isConfigLoading}
            />
          )}
        </>
      ) : (
        <KnowledgeBaseForm />
      )}
    </div>
  )
}

export default AIAgent
