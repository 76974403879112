import React from 'react'
import styled, { useTheme } from 'styled-components/macro'

import copyToClipboard from 'utils/copyToClipboard'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import { MultilevelItem, TruncatedText, FlexRow } from 'components/lib'
import StyledSelect from './MaterialElements/StyledSelect'

const Link = styled.a`
  color: ${props => props.theme.colors.text0};
`

export const CopyEmailDialog = ({
  email,
  pretext,
}: {
  email?: string
  pretext?: string
}) => {
  const theme = useTheme()
  if (!email) return null

  return (
    <StyledSelect
      selectStyle="textButton"
      defaultValue={undefined}
      renderValue={() => (
        <FlexRow style={{ maxWidth: 290 }}>
          <TruncatedText style={{ fontSize: 14 }}>
            {pretext && `${pretext} `} {email}
          </TruncatedText>
        </FlexRow>
      )}
    >
      <MultilevelItem onClick={() => copyToClipboard(email)}>
        <FlexRow style={{ maxWidth: 175, color: theme.colors.text0 }}>
          Copy {email}
        </FlexRow>
      </MultilevelItem>
      <MultilevelItem>
        <FlexRow style={{ maxWidth: 175 }}>
          <Link href={`mailto:${email}`}>Email {email}</Link>
        </FlexRow>
      </MultilevelItem>
    </StyledSelect>
  )
}

export const CopyPhoneDialog = ({
  phone,
  displayText,
}: {
  phone?: string
  displayText?: string
}) => {
  const theme = useTheme()

  if (!phone) return null

  const formattedPhone = formatPhoneNumber(phone)

  return (
    <StyledSelect
      selectStyle="textButton"
      defaultValue={undefined}
      renderValue={() => (
        <FlexRow style={{ maxWidth: 290 }}>
          <TruncatedText style={{ fontSize: 14 }}>
            {displayText || formattedPhone}
          </TruncatedText>
        </FlexRow>
      )}
    >
      <MultilevelItem onClick={() => copyToClipboard(phone)}>
        <FlexRow style={{ maxWidth: 175, color: theme.colors.text0 }}>
          <TruncatedText>Copy {formattedPhone}</TruncatedText>
        </FlexRow>
      </MultilevelItem>
    </StyledSelect>
  )
}

export default CopyEmailDialog
