import React, { useEffect, useState } from 'react'
import {
  Action,
  APIContact,
  Feature,
  ContactUnit,
  PropertyInfo,
  Unit,
  UnitRelation,
  ResourceType,
  isAllowed,
} from '@super-software-inc/foundation'
import _ from 'lodash'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  authenticatedUserAtom,
  companyAssociationsAtom,
  profileModalAtom,
  unitActionsAtom,
} from 'state/atoms'
import { toAmericanDateStringFromDate, toJSDate } from 'utils/date'
import { formatUnitRelationship } from 'utils/units'
import {
  Divider,
  FlexRow,
  MultilevelDropdown,
  MultilevelItem,
  TextButton,
  TextUnderline,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import { MdMoreHoriz } from 'react-icons/md'
import { getFullUnitDocById, getUnitDocById } from 'api/units'
import { SidebarSection } from '../ProfileSidebar'
import UnitModal from './UnitModal'

const CorpFirstUnitDescription = ({
  unit,
  associationId,
}: {
  unit: ContactUnit
  associationId: string
}) => {
  const { unitId, relationship, startDate, endDate } = unit
  const unitRelationship = formatUnitRelationship(relationship)
  const relationshipStart = startDate
    ? toAmericanDateStringFromDate(toJSDate(startDate))
    : 'n/a'
  const relationshipEnd = endDate
    ? toAmericanDateStringFromDate(toJSDate(endDate))
    : 'n/a'

  const [unitDoc, setUnitDoc] = useState<Unit>()
  useEffect(() => {
    const fetchUnit = async () => {
      setUnitDoc(await getUnitDocById(unitId, associationId))
    }
    fetchUnit()
  }, [unitId, associationId])

  return (
    <div className="leading-5 mb-5">
      <FlexRow className="mb-1" justify="space-between">
        <FlexRow justify="flex-start">
          <div className="font-medium capitalize">
            {unit.endDate && new Date(unit.endDate) < new Date() && 'Past '}
            {unitRelationship}
          </div>
          <div className="mx-1">&#x2022;</div>
          <div className="decoration-bg400">{unitDoc?.name}</div>
        </FlexRow>
      </FlexRow>

      <div className="flex items-center font-normal text-sm text-text250">
        <p>{`${relationshipStart} - ${relationshipEnd}`}</p>
      </div>
    </div>
  )
}

const UnitDescription = ({
  unit,
  associationName,
  associationId,
  onUnitClick,
  index,
  openUnitModal,
  corpFirst = false,
}: {
  unit: ContactUnit
  associationName?: string
  associationId: string
  onUnitClick: (unit?: ContactUnit) => Promise<void>
  index: number
  openUnitModal: Function
  corpFirst?: boolean
}) => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const { unitId, relationship, startDate, endDate } = unit
  const unitRelationship = formatUnitRelationship(relationship)
  const relationshipStart = startDate
    ? toAmericanDateStringFromDate(toJSDate(startDate))
    : '-'
  const relationshipEnd = endDate
    ? toAmericanDateStringFromDate(toJSDate(endDate))
    : '-'

  const [unitDoc, setUnitDoc] = useState<Unit>()
  useEffect(() => {
    const fetchUnit = async () => {
      setUnitDoc(await getUnitDocById(unitId, associationId))
    }
    fetchUnit()
  }, [unitId, associationId])

  return (
    <div className="leading-5 mb-5">
      <FlexRow className="mb-1" justify="space-between">
        <FlexRow className="mb-1" justify="flex-start">
          <div className="font-medium capitalize">
            {unit.endDate && new Date(unit.endDate) < new Date() && 'Past '}
            {unitRelationship}
          </div>
          <div className="mx-1">&#x2022;</div>
          {isAllowed(
            authenticatedUser.selectedContact,
            authenticatedUser.acts,
            [associationId],
            ResourceType.PROPERTY,
            Feature.UNITS,
            Action.VIEW,
          ) && !corpFirst ? (
            <Tooltip overlay="View unit">
              <div
                className="underline underline-offset-4 cursor-pointer decoration-bg400"
                onClick={() => onUnitClick(unit)}
              >
                <TruncatedText>{unitDoc?.name}</TruncatedText>
              </div>
            </Tooltip>
          ) : (
            <div className="decoration-bg400">{unitDoc?.name}</div>
          )}
        </FlexRow>

        {!corpFirst &&
          isAllowed(
            authenticatedUser.selectedContact,
            authenticatedUser.acts,
            [associationId],
            ResourceType.PROPERTY,
            Feature.CONTACTS,
            Action.EDIT,
          ) &&
          (!unit.endDate || !unit.startDate) && (
            <MultilevelDropdown
              trigger={
                <MdMoreHoriz
                  style={{
                    fontSize: 20,
                    cursor: 'pointer',
                    outlineColor: 'transparent',
                  }}
                />
              }
            >
              <MultilevelItem
                onClick={() => {
                  openUnitModal(index)
                }}
              >
                Edit{' '}
                {unit.relationship === UnitRelation.Owner
                  ? 'ownership'
                  : unit.relationship === UnitRelation.Renter
                  ? 'lease'
                  : 'resident'}{' '}
                term
              </MultilevelItem>
            </MultilevelDropdown>
          )}
      </FlexRow>
      {relationship === UnitRelation.Owner && (
        <>
          <div className="flex items-center font-normal text-sm text-text250">
            Purchase date: {relationshipStart}
          </div>
          <div className="flex items-center font-normal text-sm text-text250">
            Sell date: {relationshipEnd}
          </div>
        </>
      )}
      {relationship === UnitRelation.Renter && (
        <>
          <div className="flex items-center font-normal text-sm text-text250">
            Lease start date: {relationshipStart}
          </div>
          <div className="flex items-center font-normal text-sm text-text250">
            Lease end date: {relationshipEnd}
          </div>
        </>
      )}
      {relationship === UnitRelation.Resident && (
        <>
          <div className="flex items-center font-normal text-sm text-text250">
            Move in date: {relationshipStart}
          </div>
          <div className="flex items-center font-normal text-sm text-text250">
            Move out date: {relationshipEnd}
          </div>
        </>
      )}
    </div>
  )
}

interface UnitWithAssociation extends ContactUnit {
  associationId: string
  associationName?: string
}

const ProfileUnitsSection = ({
  contact,
  canEditContacts,
  corpFirst = false,
  associationId,
}: {
  contact: APIContact
  canEditContacts: boolean
  corpFirst?: boolean
  associationId: string
}) => {
  const companyAssociations = useRecoilValue(companyAssociationsAtom)

  const selectedPropertyInfo = contact.propertyInfo.find(
    p => p.associationId === associationId,
  ) as PropertyInfo

  const units = _.flatten(
    contact.propertyInfo
      .find(p => p.associationId === associationId)
      ?.units.map(p => {
        const compAssociation = companyAssociations.associations.find(
          a => a.id === associationId,
        )
        if (compAssociation) {
          return {
            ...p,
            associationId,
            associationName: compAssociation?.name,
          }
        }
        return []
      }),
  ) as UnitWithAssociation[]
  const resetProfileModal = useResetRecoilState(profileModalAtom)
  const [unitActions, setUnitActions] = useRecoilState(unitActionsAtom)
  const [selectedUnitIndex, setSelectedUnitIndex] = useState<
    number | undefined
  >(undefined)
  const [unitModalIsOpen, setUnitModalIsOpen] = useState(false)
  const viewUnit = async (unit?: ContactUnit) => {
    if (!unit) return

    const selectedUnit = await getFullUnitDocById(unit.unitId, associationId)

    setUnitActions({
      ...unitActions,
      view: {
        isOpen: true,
        selectedUnit,
      },
    })
    resetProfileModal()
  }

  const openUnitModal = (index: number | undefined) => {
    setUnitModalIsOpen(true)
    // if(index === unde)
    setSelectedUnitIndex(index)
  }

  if (corpFirst) {
    return (
      <>
        {' '}
        {_.sortBy(units, ['endDate', 'startDate'])
          .reverse()
          .map((unit, index) => (
            <CorpFirstUnitDescription
              unit={unit}
              associationId={unit.associationId}
              key={`${unit.unitId}${unit.startDate}${unit.relationship}${index}`} // eslint-disable-line
            />
          ))}
      </>
    )
  }

  return (
    <SidebarSection>
      <Divider smMargin />
      <FlexRow align="center" justify="space-between" className="mb-2">
        <div className="text-slate-500 text-xs font-semibold uppercase leading-[18px] tracking-wide">
          UNITS ({units.length})
        </div>
        {!corpFirst && canEditContacts && (
          <TextButton
            type="button"
            onClick={() => {
              openUnitModal(undefined)
            }}
          >
            Move in to unit
          </TextButton>
        )}
      </FlexRow>

      {_.sortBy(units, ['endDate', 'startDate'])
        .reverse()
        .map((unit, index) => (
          <UnitDescription
            unit={unit}
            associationName={unit.associationName}
            associationId={unit.associationId}
            onUnitClick={viewUnit}
            key={`${unit.unitId}${unit.startDate}${unit.relationship}${index}`} // eslint-disable-line
            index={index}
            openUnitModal={openUnitModal}
            corpFirst={corpFirst}
          />
        ))}
      {!corpFirst &&
        (units === undefined || units.length === 0) &&
        canEditContacts && (
          <TextUnderline
            style={{
              marginTop: 10,
            }}
            type="button"
            onClick={() => openUnitModal(undefined)}
          >
            Move in to unit
          </TextUnderline>
        )}
      {unitModalIsOpen && (
        <UnitModal
          contact={contact}
          propertyInfo={
            contact.propertyInfo.find(
              p => p.associationId === associationId,
            ) as PropertyInfo
          }
          sortedContactUnits={_.sortBy(selectedPropertyInfo.units, [
            'endDate',
            'startDate',
          ]).reverse()}
          unitIndex={selectedUnitIndex}
          onClose={() => setUnitModalIsOpen(false)}
        />
      )}
    </SidebarSection>
  )
}

export default ProfileUnitsSection
