import {
  getContactDisplayName,
  SearchableTask,
  TaskFilterType,
  Workspace,
} from '@super-software-inc/foundation'
import ContactAvatar from 'components/app/ContactAvatar'
import HumanizedDueDate from 'components/app/HumanizedDueDate'
import HumanizedUpdatedAt from 'components/app/HumanizedUpdatedAt'
import StatusSelector, {
  StatusSelectorOnChange,
} from 'components/app/StatusSelector'
import { TagViewPill } from 'components/app/Tags/TagView'
import { Dot, FlexRow, Pill, Tooltip, TruncatedText } from 'components/lib'
import {
  VirtualizedTableCell,
  VirtualizedTableRow,
} from 'components/lib/NewTable'
import markdownToPlainText from 'lib/markdown/markdownToPlainText'
import { companyTaskCategoriesAtom } from 'pages/Tasks'
import React, { useMemo } from 'react'
import {
  MdCached,
  MdOutlineComment,
  MdOutlineSubdirectoryArrowRight,
} from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled, { useTheme } from 'styled-components/macro'
import { toJSDate } from 'utils/date'
import toSentenceCase from 'utils/toSentenceCase'
import { authenticatedUserAtom, taskFiltersAtom } from 'state/atoms'
import { associationChoicesAtom } from '../../AppRoutes'

const UrgentPill = styled(Pill)`
  color: #ff1d31;
  background: #feecec;
  margin-left: 8px;
`

const TaskRow = ({
  task,
  onClick,
  onStatusChange,
  highlight,
  className,
  style,
  titleColumnWidth,
}: {
  task: SearchableTask
  onClick: Function
  onStatusChange: StatusSelectorOnChange
  highlight?: boolean
  className?: string
  style?: React.CSSProperties
  titleColumnWidth?: number
}) => {
  const theme = useTheme()
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const [companyTaskCategories] = useRecoilState(companyTaskCategoriesAtom)
  const plainText = useMemo(() => {
    if (!task.description100) return ''
    // Use cached plain text if available
    return (
      task.simpleTextDescription || markdownToPlainText(task.description100)
    )
  }, [task.description100, task.simpleTextDescription])

  const todayBegOfDay = new Date(new Date().setUTCHours(0, 0, 0, 0))
  const todayEndOfDay = new Date(new Date().setUTCHours(23, 59, 59, 999))
  const tomorrow = new Date(
    new Date(todayEndOfDay).setDate(todayEndOfDay.getDate() + 1),
  )
  const taskFilters = useRecoilValue(taskFiltersAtom)

  const associationName = useMemo(() => {
    const associationFilter = taskFilters.find(
      filter => filter.type === TaskFilterType.Association,
    )

    return associationFilter?.value?.length === 1 &&
      associationFilter?.value[0] !== 'null'
      ? undefined
      : associationChoices.find(
          association => association.id === task.associationId,
        )?.name
  }, [task.associationId, associationChoices, taskFilters])
  const taskIsUnread = !task.read?.includes(
    authenticatedUser.selectedContact?.id,
  )

  return (
    <VirtualizedTableRow
      style={{ ...style, width: 'initial' }}
      isTaskTable
      key={task.id}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()

        onClick()
      }}
      className={`
        ${highlight ? 'bg-gray-100' : ''}
      `}
    >
      <VirtualizedTableCell
        style={{
          paddingRight: 0,
          width: 30,
        }}
        className="min-w-[20px]"
      >
        <FlexRow align="center" justify="space-between" className="w-full">
          <StatusSelector
            value={task.status}
            task={task}
            onChange={onStatusChange}
          />
        </FlexRow>
      </VirtualizedTableCell>
      <VirtualizedTableCell
        className={className}
        style={
          titleColumnWidth
            ? {
                width: titleColumnWidth,
                minWidth: titleColumnWidth,
              }
            : {
                width: 320,
                minWidth: 320,
              }
        }
      >
        <FlexRow align="center" justify="space-between" className="w-full">
          <FlexRow
            align="center"
            justify="flex-start"
            style={{
              overflow: 'hidden',
              marginRight: 10,
              paddingLeft: 8,
            }}
          >
            <TruncatedText
              style={{
                fontWeight: taskIsUnread ? 'bold' : 'normal',
                flexShrink: 0, // TODO: For mobile this won't work.
                flexGrow: 0,
              }}
            >
              {task.title}
            </TruncatedText>
            {task.isUrgent && <UrgentPill>Urgent</UrgentPill>}
            {plainText && plainText.length > 0 && (
              <>
                &nbsp;-&nbsp;
                <TruncatedText
                  style={{ flexShrink: 20 }}
                  className="text-gray-400"
                >
                  {plainText}
                </TruncatedText>
              </>
            )}
          </FlexRow>
          <FlexRow>
            {task.subTaskCount && task.subTaskCount > 0 ? (
              <FlexRow align="center" style={{ color: theme.colors.text300 }}>
                <MdOutlineSubdirectoryArrowRight
                  size={16}
                  style={{ marginRight: 5 }}
                />
                {task.subTaskCount}
              </FlexRow>
            ) : null}
            {(task.internalCommentsCount > 0 ||
              task.externalCommentsCount > 0) && (
              <FlexRow
                align="center"
                style={{ color: theme.colors.text300, marginLeft: 8 }}
              >
                <MdOutlineComment
                  size={16}
                  style={{ marginLeft: 5, marginRight: 5 }}
                />
                {(task.internalCommentsCount || 0) +
                  (task.externalCommentsCount || 0)}
              </FlexRow>
            )}
          </FlexRow>
        </FlexRow>
      </VirtualizedTableCell>
      <VirtualizedTableCell
        style={{
          width: 160,
          minWidth: 160,
        }}
      >
        <Tooltip
          overlay={`    
            ${task.locationsString}      ${
            associationName && task.locationsString ? ' - ' : ''
          }    
            ${associationName || ''}
            
            
          `}
        >
          <FlexRow className="w-full" align="center">
            <TruncatedText
              style={{
                fontWeight: taskIsUnread ? 'bold' : 'normal',
              }}
            >
              {task.locationsString}
              {associationName && task.locationsString && ' - '}
              {associationName}
            </TruncatedText>
          </FlexRow>
        </Tooltip>
      </VirtualizedTableCell>

      <VirtualizedTableCell
        style={{
          width: 90,
          minWidth: 90,
          textAlign: 'left',
          paddingRight: 12,
          fontWeight: taskIsUnread ? 'bold' : 'normal',
        }}
        className="flex items-center"
      >
        {task.updatedAt ? (
          <HumanizedUpdatedAt date={new Date(Number(`${task.updatedAt}`))} />
        ) : (
          '-'
        )}
      </VirtualizedTableCell>

      <VirtualizedTableCell
        style={{
          width: 150,
          minWidth: 150,
          textAlign: 'left',
          fontWeight: taskIsUnread ? 'bold' : 'normal',
          overflow: 'hidden',
        }}
        className="items-center"
      >
        {task.modifiedBy ? (
          <FlexRow>
            {/* <ContactAvatar data={task.modifiedBy} small /> */}
            <TruncatedText
              style={{
                fontWeight: taskIsUnread ? 'bold' : 'normal',
              }}
            >
              {getContactDisplayName(task.modifiedBy)}
            </TruncatedText>
          </FlexRow>
        ) : (
          ''
        )}
      </VirtualizedTableCell>

      <VirtualizedTableCell style={{ width: 130, minWidth: 130 }}>
        <FlexRow align="center">
          <Tooltip
            overlay={
              task.workspace
                ? toSentenceCase(task.workspace).replace('-', ' ')
                : 'General'
            }
          >
            <Pill>
              <TruncatedText
                className="flex items-center"
                style={{
                  maxWidth: 100,
                  paddingRight: 5,
                }}
              >
                <Dot
                  color={
                    !task.workspace
                      ? theme.colors.text300
                      : task.workspace === Workspace.WorkOrders
                      ? theme.colors.blue
                      : theme.colors.orange
                  }
                />
                {task.workspace
                  ? toSentenceCase(task.workspace).replace('-', ' ')
                  : 'General'}
              </TruncatedText>
            </Pill>
          </Tooltip>
        </FlexRow>
      </VirtualizedTableCell>

      <VirtualizedTableCell style={{ width: 120, minWidth: 120 }}>
        <FlexRow align="center">
          <TruncatedText className="flex items-center">
            {task.taskCategories && task.taskCategories.length >= 1 && (
              <Tooltip
                overlay={companyTaskCategories
                  .filter(category =>
                    task.taskCategories?.includes(category.id),
                  )
                  .map(t => t.name)
                  .join(', ')}
              >
                <TagViewPill
                  tags={companyTaskCategories.filter(category =>
                    task.taskCategories?.includes(category.id),
                  )}
                />
              </Tooltip>
            )}
          </TruncatedText>
        </FlexRow>
      </VirtualizedTableCell>

      <VirtualizedTableCell
        style={{ width: 108, minWidth: 108 }}
        className="items-center"
      >
        {task.dueDate && (
          <Pill
            color={
              toJSDate(task.dueDate) <= todayBegOfDay
                ? 'red'
                : new Date(task.dueDate) > todayEndOfDay &&
                  new Date(task.dueDate) <= tomorrow
                ? 'green'
                : 'default'
            }
          >
            <HumanizedDueDate date={toJSDate(task.dueDate)} />
            {task.schedule && <MdCached style={{ marginLeft: 4 }} />}
          </Pill>
        )}
      </VirtualizedTableCell>

      <VirtualizedTableCell style={{ width: 32, minWidth: 32 }}>
        {task.assignee && (
          <FlexRow align="center">
            <ContactAvatar data={task.assignee} small />
          </FlexRow>
        )}
      </VirtualizedTableCell>
    </VirtualizedTableRow>
  )
}

export default TaskRow
