import { APIContact } from '@super-software-inc/foundation'
import SimplePage from 'components/app/SimplePage'
import { PrimaryButton, TextButton } from 'components/lib'
import { toastError } from 'components/lib/Toast'
import { signOut } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { isFeatureEnabled } from 'lib/featureFlags'
import React from 'react'
import { useAuth, useFunctions } from 'reactfire'
import { useRecoilState } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import getAccessControlTypes from 'api/accessControl'
import { AuthCompanyContacts } from '../AppRoutes'

const ContainerTextTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${props => props.theme.colors.text100};
  margin-bottom: 24px;
`

const NothingPage = () => {
  const firebaseAuth = useAuth()
  const functions = useFunctions()
  functions.region = 'us-east1'
  const [isSeeding, setIsSeeding] = React.useState(false)
  const seedDB = httpsCallable(functions, 'seedDB')
  const getAuthenticatedContacts = httpsCallable(
    functions,
    'getAuthenticatedContacts',
  )

  const seedDatabase = async () => {
    setIsSeeding(true)
    await seedDB()
    setIsSeeding(false)
  }

  const [, setAuthenticatedUser] = useRecoilState(authenticatedUserAtom)

  const loadData = async () => {
    getAuthenticatedContacts()
      .then(async data => {
        const { companies, contacts, secrets } =
          data.data as AuthCompanyContacts

        // get acts
        const acts = await getAccessControlTypes()

        setAuthenticatedUser({
          taskFilters: [],
          companies,
          contacts,
          selectedCompany: companies[0],
          selectedContact:
            contacts.find((c: APIContact) =>
              c.associationIds.some((id: string) =>
                companies[0].associationIds.includes(id),
              ),
            ) || ({} as APIContact),
          secrets,
          acts,
        })
      })
      .catch(e => {
        toastError('There was a problem loading your account.')
      })
  }

  return (
    <SimplePage style={{ padding: 16, textAlign: 'center' }}>
      <ContainerTextTitle>No access found</ContainerTextTitle>
      <p style={{ maxWidth: 500 }}>
        We couldn&apos;t find any active companies or properties associated with
        your account. If you think you received this message in error, please
        <br />
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() =>
            window.open(
              'mailto:help@hiresuper.com?subject=No%20&access%20found',
            )
          }
        >
          contact Super
        </span>
        .
      </p>
      <PrimaryButton onClick={() => signOut(firebaseAuth)}>
        Return home
      </PrimaryButton>
      {process.env.NODE_ENV === 'development' &&
        isFeatureEnabled('REACT_APP_USE_EMULATORS') && (
          <div>
            <p>Dev Environment</p>
            <TextButton disabled={isSeeding} onClick={seedDatabase}>
              Seed Database
            </TextButton>
            <TextButton disabled={isSeeding} onClick={loadData}>
              Load Data
            </TextButton>
          </div>
        )}
    </SimplePage>
  )
}

export default NothingPage
