import {
  Action,
  CheckType,
  Feature,
  isAllowed,
  ResourceType,
} from '@super-software-inc/foundation'
import VerticalNavLink from 'components/lib/Navigation/VerticalNavLink'
import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, currentAssociationTabAtom } from 'state/atoms'
import { selectedFolderIdAtom } from '../../../pages/Files'

/**
 * A dropdown component that allows the switching of association choices
 */
const PropertySpecificSection = () => {
  const [, setCurrentAssociationTab] = useRecoilState(currentAssociationTabAtom)

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  // if they have access to files in any association, they can view this tab
  const hasFileAccess = useMemo(
    () =>
      isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        authenticatedUser.selectedContact.associationIds,
        ResourceType.PROPERTY,
        Feature.ASSOCIATION_FILES,
        Action.VIEW,
        CheckType.ANY,
      ),
    [authenticatedUser.selectedContact, authenticatedUser.acts],
  )

  // if they have access to finances in any association, they can view this tab
  const hasFinanceAccess = useMemo(
    () =>
      isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        authenticatedUser.selectedContact.associationIds,
        ResourceType.PROPERTY,
        Feature.ASSOCIATION_FINANCES,
        Action.VIEW,
        CheckType.ANY,
      ),

    [authenticatedUser.selectedContact, authenticatedUser.acts],
  )

  const [, setFolderId] = useRecoilState(selectedFolderIdAtom)

  return (
    <div>
      {hasFinanceAccess && (
        <VerticalNavLink
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              attach_money
            </span>
          }
          navBaseProps={{
            to: `/finances`,
          }}
          navBaseAs={NavLink}
          label="Finances"
        />
      )}
      {hasFileAccess && (
        <VerticalNavLink
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              folder_copy
            </span>
          }
          navBaseProps={{
            to: `/files`,
          }}
          navBaseAs={NavLink}
          label="Files"
          onClick={() => setFolderId(null)}
        />
      )}

      <VerticalNavLink
        icon={
          <span
            className="material-symbols-rounded"
            style={{ fontSize: 16, marginTop: 6 }}
          >
            door_front
          </span>
        }
        navBaseProps={{
          to: `/association`,
        }}
        navBaseAs={NavLink}
        label="Property details"
        onClick={() => setCurrentAssociationTab(0)}
      />

      {process.env.NODE_ENV === 'development' && (
        <VerticalNavLink
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              experiment
            </span>
          }
          navBaseProps={{
            to: `/sandbox`,
          }}
          navBaseAs={NavLink}
          label="Sandbox"
        />
      )}
    </div>
  )
}

export default PropertySpecificSection
