import React from 'react'
import { Select, SelectProps } from '@mui/material'
import styled from 'styled-components/macro'
import { MultilevelDropdownFixedFooter } from '../MultilevelDropdown/MultilevelDropdown'
import Divider from '../Divider'

const StyledSelectContainer = styled(Select)<
  SelectProps & { selectstyle?: 'pill' | 'rectangle' | 'textButton' }
>(({ selectstyle }) => ({
  height: selectstyle === 'pill' ? 30 : 40,
  '& .MuiSvgIcon-root': {
    fontSize: selectstyle === 'pill' ? 14 : undefined,
    marginRight: selectstyle === 'pill' ? 6 : undefined,
  },
  '& .MuiSelect-select[aria-expanded="false"]::placeholder': {
    color: 'rgb(138, 148, 166)',
    fontWeight: 400,
  },

  '& .MuiSelect-select': {
    paddingRight: 0,
    padding: selectstyle === 'pill' ? '0 10px !important' : undefined,
  },

  '& .MuiOutlinedInput-input': {
    padding: selectstyle === 'textButton' ? '0 !important' : 'initial',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
    paddingRight: 'unset',
  },
  ...(selectstyle === 'pill' && {
    '&:hover': {
      backgroundColor: '#d4d7de',
      color: '#0A1F44',
      transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
    },
  }),
}))

const StyledSelect = ({
  children,
  defaultValue,
  value = '',
  renderValue,
  disabled = false,
  selectStyle,
  icon = undefined,
  placeholder,
  fixedFooter,
}: {
  children: any
  defaultValue: any
  value?: any
  renderValue: any
  disabled?: boolean
  selectStyle?: 'pill' | 'rectangle' | 'textButton'
  icon?: React.ElementType
  placeholder?: string
  fixedFooter?: React.ReactNode
}) => (
  <StyledSelectContainer
    selectstyle={selectStyle}
    sx={
      selectStyle === 'pill'
        ? {
            border: '#E1E4E8 1px solid',
            borderRadius: 20,
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
          }
        : selectStyle === 'rectangle'
        ? {
            border: '#E1E4E8 1px solid',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            width: '100%',
          }
        : undefined
    }
    disabled={disabled}
    defaultValue={defaultValue}
    notched={false}
    placeholder={placeholder}
    inputProps={{
      IconComponent: icon,
    }}
    displayEmpty
    SelectDisplayProps={{
      style: { display: 'flex', alignItems: 'center' },
    }}
    value={value}
    renderValue={renderValue}
    onChange={() => {}} // empty function b/c we dont want the select to store the value, in order to control the dropdown
  >
    {children}
    {fixedFooter && (
      <MultilevelDropdownFixedFooter>
        <Divider style={{ marginBottom: 10, width: '100%' }} />
        {fixedFooter}
      </MultilevelDropdownFixedFooter>
    )}
  </StyledSelectContainer>
)

export default StyledSelect
