import {
  ContactGroup,
  createContactReference,
  DateFilterOption,
  SearchableTask,
  TaskFilterType,
  TaskStatus,
  TaskStatusChangeAction,
  Workspace,
} from '@super-software-inc/foundation'
import {
  changeTaskStatus,
  getTasksFromTypesense,
  GetTasksFromTypesenseProps,
} from 'api/tasks'
import placeholder from 'assets/images/loading-placeholder.gif'
import { SortOption } from 'components/app/TaskViewOptions'
import TaskViewOptions from 'components/app/FilterAndSort/TaskViewOptions'
import { FlexRow, TruncatedText } from 'components/lib'
import { VirtualizedSortableTableHeader } from 'components/lib/NewTable'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MdOutlineCircle } from 'react-icons/md'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  authenticatedUserAtom,
  searchableTasksAtom,
  showTaskFormAtom,
  taskFiltersAtom,
  tasksCountAtom,
} from 'state/atoms'
import styled from 'styled-components/macro'
import closeAllSubtasks from 'utils/closeAllSubtasks'
import LoadingIcon from 'components/lib/LoadingIcon'
import { StatusSelectorOnChange } from 'components/app/StatusSelector'
import calculateTypesenseFilterProps from 'components/app/FilterAndSort/calculateTypesenseFilterProps'
import { associationChoicesAtom, windowDimensionsAtom } from '../../AppRoutes'
import { selectedTaskAtom } from './index'
import TaskRow from './TaskRow'

export const TasksContent = styled.div<{ noTasksAvailable?: boolean }>`
  padding: 0px 20px;
  overflow-y: ${noTasksAvailable => (noTasksAvailable ? 'visible' : 'scroll')};
  overflow-x: ${noTasksAvailable => (noTasksAvailable ? 'visible' : 'hidden')};

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1350px) {
    padding-right: 0;
  }

  @media only screen and (max-width: 600px) {
    p:first-of-type {
      margin-left: 5px;
    }
    padding: 0;
  }
`

export const SectionHeader = styled.div`
  height: 60px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  position: relative;
`

export const ViewOptionsContainer = styled.div`
  display: flex;
  position: fixed;
  right: 20px;
  top: 56px;
  z-index: 200;

  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 110px;
  }
`

const TaskListTableHeader = ({
  sortKey,
  sortOrder,
  handleSort,
  totalTasks,
  titleColumnWidth,
  className,
  style,
}: {
  style: React.CSSProperties
  sortKey: SortOption
  sortOrder: 'asc' | 'desc'
  handleSort: Function
  totalTasks: number
  titleColumnWidth: number
  className?: string
}) => (
  <div
    style={{
      ...style,
      display: 'flex',
      width: 'fit-content',
      borderBottom: '1px solid #E5E5E5',
      borderTop: '1px solid #E5E5E5',
    }}
  >
    <VirtualizedSortableTableHeader
      className={className}
      active={sortKey === SortOption.Status}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Status)}
      leftHandIndicator
      style={{
        paddingLeft: 15,
        minWidth: 30,
        width: 30,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 0,
      }}
    >
      <MdOutlineCircle
        style={{
          display: 'block',
        }}
        color="#9daabf"
        size={20}
      />
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      className={className}
      active={sortKey === SortOption.Title}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Title)}
      style={{
        textAlign: 'left',
        width: titleColumnWidth,
        minWidth: titleColumnWidth,
        paddingLeft: 14,
      }}
    >
      {`TASKS (${totalTasks})`}
    </VirtualizedSortableTableHeader>

    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.Locations}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Locations)}
      style={{
        textAlign: 'left',
        width: 160,
        minWidth: 160,
      }}
    >
      Location
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      active={
        sortKey === SortOption.UpdatedAt || sortKey === SortOption.Default
      }
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.UpdatedAt)}
      style={{ textAlign: 'left', width: 90, minWidth: 90 }}
    >
      UPDATED
    </VirtualizedSortableTableHeader>

    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.ModifiedBy}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.ModifiedBy)}
      style={{ textAlign: 'left', width: 150, minWidth: 150 }}
    >
      <TruncatedText>UPDATED BY</TruncatedText>
    </VirtualizedSortableTableHeader>

    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.Workspaces}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Workspaces)}
      style={{ textAlign: 'left', width: 130, minWidth: 130 }}
    >
      WORKSPACE
    </VirtualizedSortableTableHeader>

    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.Category}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Category)}
      style={{ textAlign: 'left', width: 120, minWidth: 120 }}
    >
      CATEGORIES
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.DueDate}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.DueDate)}
      style={{ textAlign: 'left', width: 108, minWidth: 108 }}
    >
      DUE DATE
    </VirtualizedSortableTableHeader>

    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.Assignee}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Assignee)}
      style={{ textAlign: 'left', width: 32, minWidth: 32 }}
    />
  </div>
)

const TaskListRenderer = ({
  index,
  data,
  style,
}: {
  index: number
  data: {
    data: SearchableTask[]
    className: string
    selectedTask: SearchableTask | undefined
    taskDrawerIsOpen: boolean
    setTaskStatus: StatusSelectorOnChange
    navigateToTask: (task: SearchableTask) => void
    // table header props
    sortKey?: SortOption
    sortOrder?: 'asc' | 'desc'
    handleSort?: Function
    totalTasks: number
    associations?: string[]
    titleColumnWidth: number
  }
  style: React.CSSProperties
}) => {
  const {
    className,
    selectedTask,
    taskDrawerIsOpen,
    setTaskStatus,
    navigateToTask,
    sortKey,
    sortOrder,
    handleSort,
    totalTasks,
    titleColumnWidth,
  } = data
  const task = data.data[index]

  if (index === 0) {
    return (
      <TaskListTableHeader
        style={{ ...style, width: 'unset' }}
        sortKey={sortKey || SortOption.Default}
        sortOrder={sortOrder || 'asc'}
        handleSort={handleSort || (() => {})}
        totalTasks={totalTasks || 0}
        titleColumnWidth={titleColumnWidth}
        className={className}
      />
    )
  }

  if (!task || 'id' in task === false) {
    return (
      <FlexRow
        align="center"
        justify="center"
        style={{ ...style, height: 50 }}
        className="w-full"
      >
        <LoadingIcon />
      </FlexRow>
    )
  }

  return (
    <TaskRow
      key={task.id}
      titleColumnWidth={titleColumnWidth}
      style={{ ...style, width: '100%' }}
      className={className}
      highlight={selectedTask?.id === task.id && taskDrawerIsOpen}
      task={task}
      onClick={async () => {
        navigateToTask(task)
      }}
      onStatusChange={setTaskStatus}
    />
  )
}

interface VirtualizedTaskListViewProps {
  onRequestSheetClose: (
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void
}

const VirtualizedTaskListView = ({
  onRequestSheetClose,
}: VirtualizedTaskListViewProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)

  const [searchParams] = useSearchParams()
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const associationIds = useMemo(
    () => [...associationChoices.map(c => c.id), 'null'],
    [associationChoices],
  )
  const setShowTaskForm = useSetRecoilState(showTaskFormAtom)
  const [windowDimensions] = useRecoilState(windowDimensionsAtom)
  const [className] = useState('anchorTableCell')
  const [selectedTask, setSelectedTask] = useRecoilState(selectedTaskAtom)
  const [tasksCount, setTasksCount] = useRecoilState(tasksCountAtom)
  const [tasksPage, setTasksPage] = useState(1)

  const [filters, setFilters] = useRecoilState(taskFiltersAtom)

  const contactSecrets = authenticatedUser.secrets?.find(
    secret => secret.companyId === selectedCompany.id,
  )

  const taskDrawerIsOpen = location.pathname.includes('/tasks/')

  const [sortKey, setSortKey] = useState(
    filters.find(f => f.type === TaskFilterType.Workspaces)?.value ===
      Workspace.WorkOrders
      ? SortOption.DueDate
      : SortOption.Default,
  )
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const prevContactSecrets = usePrevious(contactSecrets)

  const handleSort = (nextSort: SortOption) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const [searchableTasks, setSearchableTasks] =
    useRecoilState(searchableTasksAtom)

  const [loading, setLoading] = useState<boolean>(false)

  // Add a timestamp to track when tasks were last modified locally
  const [recentlyModifiedTasks, setRecentlyModifiedTasks] = useState<{
    [key: string]: number
  }>({})

  // Helper to mark a task as recently modified
  const markTaskAsModified = (taskId: string) => {
    setRecentlyModifiedTasks(prev => ({
      ...prev,
      [taskId]: Date.now(),
    }))
  }

  // Update the task status change handler to mark tasks as modified
  const setTaskStatus = async ({
    task,
    newStatus,
    action,
  }: {
    task: SearchableTask
    newStatus: TaskStatus
    action: TaskStatusChangeAction
  }) => {
    markTaskAsModified(task.id) // Mark as modified

    // Change task status locally immediately:
    const taskIdx = searchableTasks.findIndex(t => t.id === task.id)
    const changedTask = { ...task, status: newStatus }
    const updatedTasks = [...searchableTasks]

    if (taskIdx !== -1) {
      updatedTasks[taskIdx] = changedTask
      setSearchableTasks(updatedTasks)
    }

    const contactReference = createContactReference(
      authenticatedUser.selectedContact,
    )

    await changeTaskStatus({
      companyId: authenticatedUser.selectedCompany.id,
      task,
      newStatus,
      contact: authenticatedUser.selectedContact,
      action,
    })

    if (selectedTask?.id === task.id) {
      setSelectedTask({ ...task, status: newStatus })
    }

    if (newStatus === 'closed') {
      setSearchableTasks(searchableTasks.filter(apitask => apitask !== task))
      closeAllSubtasks(
        authenticatedUser.selectedCompany.id,
        task.id,
        contactReference,
      )
    }
  }

  // Calculate the filter options for the API call to Typesense:
  const apiFilterOptions = useMemo(
    (): GetTasksFromTypesenseProps =>
      calculateTypesenseFilterProps(
        filters,
        [...associationChoices.map(c => c.id), 'null'], // pass all possible association ids
        sortKey,
        sortOrder,
        authenticatedUser.selectedContact,
      ),
    [
      associationChoices,
      filters,
      sortKey,
      sortOrder,
      authenticatedUser.selectedContact,
    ],
  )
  const prevApiFilterOptions = usePrevious(apiFilterOptions)

  const includeInternalComments = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo?.some(
        p =>
          (associationIds.includes(p.associationId) &&
            p?.groups.some(
              g => g === ContactGroup.Staff || g === ContactGroup.Management,
            )) ||
          false,
      ),
    [associationIds, authenticatedUser.selectedContact.propertyInfo],
  )

  const [spinnerTimeout, setSpinnerTimeout] = useState(false)
  const spinnerTimer = useRef<any>(null)

  useEffect(() => {
    clearTimeout(spinnerTimer.current)
    if (loading) {
      setSpinnerTimeout(false)

      spinnerTimer.current = setTimeout(() => {
        setSpinnerTimeout(true)
      }, 1500)
    }
    return () => {
      clearTimeout(spinnerTimer.current)
    }
  }, [loading, spinnerTimer])

  const titleColumnWidth = useMemo(
    () =>
      windowDimensions.isIframe
        ? 300
        : windowDimensions.width > 1350
        ? windowDimensions.width - 1115
        : 200,
    [windowDimensions],
  )

  // Define getTaskPage function before useEffect
  async function getTaskPage(page: number) {
    if (!contactSecrets) {
      return []
    }

    setLoadingNextPage(true)

    const params: GetTasksFromTypesenseProps = {
      ...apiFilterOptions,
      includeInternalComments,
      page,
    }

    const result = await getTasksFromTypesense(params, contactSecrets)

    setTasksCount(result.totalTasks)
    setLoadingNextPage(false)

    return result.tasks
  }

  // add one for the header row, and one if we need the loading spinner
  const itemCount =
    tasksCount > searchableTasks.length
      ? searchableTasks.length + 2
      : searchableTasks.length + 1

  const loadNextPage = async () => {
    if (searchableTasks.length === 0) {
      return
    }

    if (!loadingNextPage && tasksCount > searchableTasks.length) {
      const nextPage = tasksPage + 1
      setTasksPage(nextPage)

      const tasks = await getTaskPage(nextPage)

      setSearchableTasks(searchableTasks.concat(tasks))
    }
  }

  // Periodic refresh of tasks
  useEffect(() => {
    let intervalId: number | null = null

    if (!taskDrawerIsOpen) {
      intervalId = window.setInterval(async () => {
        if (contactSecrets) {
          const pagePromises = []
          for (let page = 1; page <= tasksPage + 1; page += 1) {
            pagePromises.push(getTaskPage(page))
          }

          const newTasks = (await Promise.all(pagePromises)).flat()

          setSearchableTasks(prevTasks => {
            const now = Date.now()
            const RECENT_MODIFICATION_THRESHOLD = 10000 // 10 seconds

            return newTasks.map(newTask => {
              const lastModified = recentlyModifiedTasks[newTask.id]

              if (
                lastModified &&
                now - lastModified < RECENT_MODIFICATION_THRESHOLD
              ) {
                const existingTask = prevTasks.find(t => t.id === newTask.id)
                return existingTask || newTask
              }

              return newTask
            })
          })
        }
      }, 10000)
    }

    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tasksPage,
    contactSecrets,
    apiFilterOptions,
    includeInternalComments,
    taskDrawerIsOpen,
  ])

  const loadTasks = ({
    page,
    showLoading,
  }: {
    page: number
    showLoading?: boolean
  }) => {
    if (showLoading) {
      setLoading(true)
    }

    const params: GetTasksFromTypesenseProps = {
      ...apiFilterOptions,
      includeInternalComments,
      page,
    }

    getTasksFromTypesense(params, contactSecrets).then(res => {
      setTasksCount(res.totalTasks)
      setSearchableTasks(res.tasks)
      setLoading(false)
    })
  }

  // Initial load when contact secrets become available

  useEffect(() => {
    if (!prevContactSecrets && contactSecrets) {
      loadTasks({ page: 1, showLoading: true })
    }
    // Exclude loadTasks from the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevContactSecrets, contactSecrets])

  // Handle filter/association changes

  useEffect(() => {
    if (!contactSecrets) {
      return
    }

    if (
      prevApiFilterOptions &&
      !isEqual(prevApiFilterOptions, apiFilterOptions)
    ) {
      setSearchableTasks([])
      setTasksPage(1)

      loadTasks({ page: 1, showLoading: true })
    }
    // Exclude loadTasks from the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevApiFilterOptions, apiFilterOptions, contactSecrets])

  // Handle URL params for subscribed/summary filters
  useEffect(() => {
    const subscribed = searchParams.get('subscribed') as string
    const summary = searchParams.get('summary') as string
    if (subscribed) {
      setFilters([
        { type: TaskFilterType.Subscriber, value: subscribed },
        { type: TaskFilterType.Status, value: TaskStatus.CLOSED },
      ])
    } else if (summary) {
      setFilters([
        { type: TaskFilterType.DueDate, value: DateFilterOption.Next30Days },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  // Update the effect that handles selected task changes
  useEffect(() => {
    if (selectedTask) {
      markTaskAsModified(selectedTask.id)

      const taskIdx = searchableTasks.findIndex(t => t.id === selectedTask.id)
      const updatedTasks = [...searchableTasks]

      if (taskIdx !== -1) {
        updatedTasks[taskIdx] = selectedTask
        setSearchableTasks(updatedTasks)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask])

  const navigateToTask = (task: SearchableTask) => {
    if (!task.read?.includes(authenticatedUser.selectedContact.id)) {
      // If the task was not previously read, mark it as read locally. The tasksheet
      // will deal with updating the read status remotely:
      const taskWithUpdatedReadStatus = {
        ...task,
        read: [...(task.read || []), authenticatedUser.selectedContact.id],
      }
      const taskIdx = searchableTasks.findIndex(t => t.id === task.id)
      const updatedTasks = [...searchableTasks]
      if (taskIdx !== -1) {
        updatedTasks[taskIdx] = taskWithUpdatedReadStatus
        setSearchableTasks(updatedTasks)
      }
    }

    navigate(`/tasks/${task.id}`)
  }

  if (loading && spinnerTimeout) {
    return (
      <TasksContent noTasksAvailable={!loading && searchableTasks.length === 0}>
        <SectionHeader>
          <p style={{ position: 'absolute', bottom: 0, left: 0 }}>
            LOADING TASKS
          </p>
        </SectionHeader>
        <img
          src={placeholder}
          alt="loading placeholder"
          style={{
            width: '95%',
            height: windowDimensions.isMobile
              ? windowDimensions.width * 2
              : windowDimensions.width * 0.5,
            marginTop: windowDimensions.isMobile
              ? -windowDimensions.width * 0.6
              : -windowDimensions.width * 0.15,
            marginLeft: windowDimensions.isMobile ? 5 : 0,
          }}
        />
      </TasksContent>
    )
  }

  return (
    <TasksContent onClick={onRequestSheetClose}>
      <FlexRow align="center" justify="flex-end">
        <TaskViewOptions />
      </FlexRow>

      <InfiniteLoader
        isItemLoaded={index => index < searchableTasks.length}
        itemCount={itemCount}
        loadMoreItems={loadNextPage}
        minimumBatchSize={40}
        threshold={25}
      >
        {({ onItemsRendered, ref }) => (
          <List
            ref={ref}
            height={
              searchableTasks.length === 0 ? 50 : windowDimensions.height - 140
            }
            width="100%"
            itemCount={searchableTasks.length + 1}
            onItemsRendered={onItemsRendered}
            overscanCount={12}
            itemSize={42}
            itemData={{
              data: [{} as SearchableTask, ...searchableTasks],
              selectedTask,
              navigateToTask,
              setTaskStatus,
              className,
              taskDrawerIsOpen,
              titleColumnWidth,
              sortKey,
              sortOrder,
              handleSort,
              totalTasks: tasksCount,
            }}
          >
            {TaskListRenderer}
          </List>
        )}
      </InfiniteLoader>
      {!loading &&
        searchableTasks &&
        searchableTasks.length === 0 &&
        (filters.length > 0 ? (
          <FlexRow
            style={{
              textTransform: 'none',
              textAlign: 'left',
              fontWeight: 500,
              width: 'fit-content',
              bottom: -100,
            }}
          >
            No results.
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                paddingLeft: 3,
                paddingRight: 3,
              }}
              onClick={() => setShowTaskForm(true)}
            >
              Add a task
            </span>
            or{' '}
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                paddingLeft: 3,
              }}
              onClick={() => setFilters([])}
            >
              Clear {filters.length} filter{filters.length > 1 && 's'}
            </span>
            {' .'}
          </FlexRow>
        ) : (
          <FlexRow
            style={{
              textTransform: 'none',
              textAlign: 'left',
              fontWeight: 500,
            }}
          >
            No tasks to display.
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                margin: '0 5px',
              }}
              onClick={() => setShowTaskForm(true)}
            >
              Add your first task
            </span>
            to get started.
          </FlexRow>
        ))}
    </TasksContent>
  )
}

export default VirtualizedTaskListView
