import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import FlexRow from './FlexRow'

export const TableWrapper = styled.div`
  position: relative;
  overflow: scroll;
  padding-bottom: 200px;
  margin-top: 40px;
`

export const Table = styled.table`
  @media only screen and (min-width: 1350px) {
    table-layout: fixed;
  }

  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  max-width: 100%;
`

export const TableHead = styled.thead`
  tr {
    border-top: none;
  }
  background-color: white;
`

export const TableBody = styled.tbody`
  tr {
    &:hover {
      background-color: ${props =>
        transparentize(0.7, props.theme.colors.bg300)};
    }
  }
`

interface TableRowProps {
  isTaskTable?: boolean
}

export const TableRow = styled.tr<TableRowProps>`
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
`

export const VirtualizedTableRow = styled.div<TableRowProps>`
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  display: flex;
  width: initial;
  alignitems: flex-start;
  height: unset;
  cursor: pointer;
  background-color: white;

  :hover {
    background-color: ${props => transparentize(0.7, props.theme.colors.bg300)};
  }
`

export const VirtualizedTableCell = styled.div`
  padding: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: white;
  cursor: default;
  display: flex;
  border-right: 1px solid ${props => props.theme.colors.bg300};

  :first-of-type {
    border-left: none;
    border-right: none;
  }
  :nth-of-type(2) {
    border-left: none;
  }
  :last-of-type {
    border-right: none;
  }
  @media only screen and (min-width: 1350px) {
    :nth-of-type(2) {
      max-width: 700px;
      min-width: 320px;
      width: 500px;
      left: 0;
    }
  }
  @media only screen and (max-width: 1349px) {
    :nth-of-type(2) {
      min-width: 120px;
      max-width: 320px;
      // position: sticky;
      width: 120px;
      left: 0;
      padding-left: 8px;
      // outline: 1px solid ${props => props.theme.colors.bg300};
    }
  }
`

export const TableCell = styled.td`
  padding: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.bg300};

  :first-of-type {
    border-left: none;
    border-right: none;
  }
  :nth-of-type(2) {
    border-left: none;
  }
  :last-of-type {
    border-right: none;
  }
  @media only screen and (min-width: 1350px) {
    :nth-of-type(1) {
      width: 100px;
    }
    :nth-of-type(2) {
      max-width: 500px;
      min-width: 320px;
      left: 0;
    }
  }
  @media only screen and (max-width: 1349px) {
    :nth-of-type(2) {
      min-width: 120px;
      max-width: 320px;
      // position: sticky;
      left: 0;
      padding-left: 8px;
      outline: 1px solid ${props => props.theme.colors.bg300};
      border-right: 2px solid ${props => props.theme.colors.bg300};
    }
  }
`

export const TableHeaderCell = styled.th`
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text250};
  background: white;
  border: 1px solid ${props => props.theme.colors.bg300};

  :first-of-type {
    border-left: none;
    border-right: none;
  }
  :nth-of-type(2) {
    border-left: none;
  }
  :last-of-type {
    border-right: none;
  }

  @media only screen and (min-width: 1350px) {
    :nth-of-type(1) {
      width: 100px;
    }
    :nth-of-type(2) {
      max-width: 500px;
      min-width: 320px;
      left: 0;
    }
  }
  @media only screen and (max-width: 1349px) {
    :nth-of-type(2) {
      min-width: 120px;
      max-width: 320px;
      // position: sticky;
      left: 0;
      outline: 1px solid ${props => props.theme.colors.bg300};
    }
    :nth-of-type(3) {
      border-left: 2px solid ${props => props.theme.colors.bg300};
    }
  }
`

export const VirtualizedTableHeaderCell = styled.div`
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text250};
  background-color: white;
  display: flex;
  align-items: center;
  border-right: 1px solid ${props => props.theme.colors.bg300};

  :first-of-type {
    border-left: none;
    border-right: none;
  }
  :nth-of-type(2) {
    border-left: none;
  }
  :last-of-type {
    border-right: none;
  }

  @media only screen and (min-width: 1350px) {
    :nth-of-type(1) {
      width: 100px;
    }
    :nth-of-type(2) {
      max-width: 500px;
      width: 320px;
      left: 0;
    }
  }
  @media only screen and (max-width: 1349px) {
    :nth-of-type(2) {
      min-width: 120px;
      max-width: 320px;
      // position: sticky;
      left: 0;
      outline: 1px solid ${props => props.theme.colors.bg300};
    }
  }
`

export const SectionHeaderCell = styled.th`
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text250};
  background: white;

  :first-of-type {
    border-left: none;
    border-right: none;
  }
  :nth-of-type(2) {
    border-left: none;
  }
  :last-of-type {
    border-right: none;
  }

  @media only screen and (min-width: 1350px) {
    :nth-of-type(1) {
      width: 100px;
    }
    :nth-of-type(2) {
      max-width: 500px;
      min-width: 320px;
      left: 0;
    }
  }
  @media only screen and (max-width: 1349px) {
    :nth-of-type(2) {
      min-width: 120px;
      max-width: 320px;
      // position: sticky;
      left: 0;
    }
    :nth-of-type(3) {
      border-left: 2px solid ${props => props.theme.colors.bg300};
    }
  }
`

export const SortableTableHeaderCell = ({
  active,
  sortOrder,
  children,
  onClick,
  style,
  leftHandIndicator,
  justifyContent,
  className,
}: {
  active: boolean
  sortOrder: 'asc' | 'desc'
  children?: ReactNode
  onClick: () => void
  style?: React.CSSProperties
  leftHandIndicator?: boolean
  justifyContent?: string
  className?: string
}) => (
  <TableHeaderCell
    onClick={event => {
      event.stopPropagation()
      onClick()
    }}
    style={{ cursor: 'pointer', ...style }}
    className={className}
  >
    <FlexRow
      align="center"
      style={{ justifyContent: justifyContent || 'flex-start' }}
    >
      {active && leftHandIndicator && (
        <FlexRow style={{ marginRight: 4 }}>
          {sortOrder === 'desc' ? '↑' : '↓'}
        </FlexRow>
      )}
      {children}{' '}
      {active && !leftHandIndicator && (
        <FlexRow style={{ marginLeft: 4 }}>
          {sortOrder === 'desc' ? '↑' : '↓'}
        </FlexRow>
      )}
    </FlexRow>
  </TableHeaderCell>
)

export const VirtualizedSortableTableHeader = ({
  active,
  sortOrder,
  children,
  onClick,
  style,
  className,
  leftHandIndicator,
}: {
  active: boolean
  sortOrder: 'asc' | 'desc'
  children?: ReactNode
  onClick: () => void
  style?: React.CSSProperties
  className?: string
  leftHandIndicator?: boolean
}) => (
  <VirtualizedTableHeaderCell
    onClick={event => {
      event.stopPropagation()
      onClick()
    }}
    style={{
      cursor: 'pointer',
      maxWidth: 'unset',
      outline: 'none',
      ...style,
    }}
    className={className}
  >
    <FlexRow justify="flex-start" align="center">
      {active && leftHandIndicator && (
        <FlexRow style={{ marginRight: 4 }}>
          {sortOrder === 'desc' ? '↑' : '↓'}
        </FlexRow>
      )}
      {children}{' '}
      {active && !leftHandIndicator && (
        <FlexRow style={{ marginLeft: 4 }}>
          {sortOrder === 'desc' ? '↑' : '↓'}
        </FlexRow>
      )}
    </FlexRow>
  </VirtualizedTableHeaderCell>
)
