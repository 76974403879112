import React, { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import NiceModal from '@ebay/nice-modal-react'
import {
  DocumentData,
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
} from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import {
  TaskDropdownDatePicker,
  DropdownTriggerButton,
  FlexRow,
  MultilevelDropdown,
  MultilevelHeader,
  MultilevelItem,
  MultilevelNoResults,
} from 'components/lib'
import {
  APIContact,
  APISchedule,
  AccessLevel,
  Category,
  ContactReference,
  Feature,
  LocationReference,
  ResourceType,
  PropertyRelation,
  SearchableTask,
  Workspace,
  Action,
  isAllowed,
} from '@super-software-inc/foundation'
import { authenticatedUserAtom } from 'state/atoms'
import { Positions } from 'components/lib/MultilevelDropdown'
import { getContacts } from 'api/contacts/getContacts'
import ConfirmationModal from '../ConfirmationModal'
import TagSelector from '../Tags/TagSelector'
import AssigneeDropdown from './AssigneeDropdown'
import RadioChoiceModal from '../RadioChoiceModal'
import {
  associationChoicesAtom,
  windowDimensionsAtom,
} from '../../../AppRoutes'
import LocationSelector from './LocationSelector'
import WorkspaceSelector from './WorkspaceSelector'
import BudgetSelector from './BudgetSelector'
import TimelogSelector from './TimelogSelector'
import VendorSelector from './VendorSelector'
import InspectionTypeSelector from './InspectionTypeSelector'
import SuggestDueDate from './SuggestDueDate'
import PropertySelector from './PropertySelector'
import Alert from '../Alert'

export const pillTitle = (title: string) => (
  <p style={{ color: '#627088', fontWeight: 400, width: 85 }}>{title}</p>
)

const statusOptions = [
  {
    id: 0,
    value: false,
    text: 'Not urgent',
  },
  {
    id: 1,
    value: true,
    text: 'Urgent',
  },
]

interface TaskActionRowProps {
  task: SearchableTask
  schedule: DocumentData
  onFieldChange: Function
  children?: React.ReactNode
  style?: any
  contactsCache: {
    associationContacts: APIContact[]
    isLoading: {
      isLoadingFirstTime: boolean
      isLoadingFinalTime: boolean
    }
    isError: boolean
  }
}

const TaskActionRow: React.FC<TaskActionRowProps> = ({
  task,
  onFieldChange,
  children,
  style,
  contactsCache,
}) => {
  const [statusSearchValue, setStatusSearchValue] = useState('')
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const firestore = useFirestore()
  const [keepDateDropdownOpen, setKeepDateDropdownOpen] =
    useState<boolean>(false)
  const [suggestedDateStr, setSuggestedDateStr] = useState<string | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState<boolean>(false)

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const associationChoices = useRecoilValue(associationChoicesAtom)

  // if task associationId is null, any user with staff or PM role can edit the task
  const canEditTaskDetails = useMemo(
    () =>
      task.associationId === null
        ? authenticatedUser?.selectedContact.propertyInfo.find(
            info =>
              info.propertyRelation &&
              [
                PropertyRelation.Staff,
                PropertyRelation.PropertyManager,
              ].includes(info.propertyRelation),
          )
        : isAllowed(
            authenticatedUser.selectedContact,
            authenticatedUser.acts,
            [task.associationId],
            ResourceType.PROPERTY,
            Feature.TASKS,
            Action.EDIT,
          ),
    [task.associationId, authenticatedUser],
  )

  return (
    <div style={style}>
      <div>
        <h3>Details</h3>
      </div>
      <FlexRow align="center" style={{ marginTop: 5 }}>
        {pillTitle('Workspace')}
        <WorkspaceSelector
          isDisabled={!canEditTaskDetails}
          value={task.workspace}
          onChange={(newValue: Workspace | null) => {
            onFieldChange('workspace', newValue)
          }}
        />
      </FlexRow>
      <FlexRow align="center" style={{ marginTop: 5 }}>
        {pillTitle('Assignee')}
        <AssigneeDropdown
          isDisabled={!canEditTaskDetails || !task.associationId}
          value={task.assignee}
          onChange={(newValue: string | null) =>
            onFieldChange('assignee', newValue)
          }
          style={{}}
          contactsCache={contactsCache}
        />
      </FlexRow>

      <FlexRow
        align={windowDimensions.isMobile ? 'flex-start' : 'center'}
        style={{ marginTop: 5 }}
      >
        {pillTitle('Location')}
        <div
          style={{
            display: 'flex',
            flexDirection: windowDimensions.isMobile ? 'column' : 'row',
          }}
        >
          <PropertySelector
            isDisabled={!canEditTaskDetails}
            value={[task.associationId]}
            loading={loading}
            onChange={async (newValue: string[]) => {
              // in the task sheet, we can assume that the user is only selecting one property, and it's the last in the array
              const newAssociationId = newValue.pop()

              const newAssociationName = newAssociationId
                ? associationChoices.filter(a => a.id === newAssociationId)[0]
                    ?.name
                : null

              setLoading(true)

              const secrets = authenticatedUser.secrets.find(
                secret =>
                  secret.companyId === authenticatedUser.selectedCompany.id,
              )

              // Get contacts for the target property, so we can work out if the subscribers + assignee
              // are associated with it
              // NOTE: this call is only made when a property is changed, but
              // we could move all this logic to the BE, would probably be
              // better for a few reasons

              // TODO #contacts - this should automatically update in useContactsCache
              const contactsInNewAssociation = await getContacts(
                secrets,
                authenticatedUser.selectedCompany.id,
                newAssociationId
                  ? [newAssociationId]
                  : authenticatedUser.selectedCompany.associationIds, // Where no property is selected, use all associations
              )

              setLoading(false)

              if (task.associationId) {
                // If we're moving property->property
                if (newAssociationId) {
                  const subscribersMissingInTarget = !task.subscriptions?.every(
                    subscriber =>
                      contactsInNewAssociation
                        .find(contact => contact.id === subscriber.contactId)
                        ?.propertyInfo.find(
                          property =>
                            property.associationId === newAssociationId &&
                            property.accessLevel !== AccessLevel.NoAccess,
                        ),
                  )
                  const assigneeMissingInTarget =
                    task.assignee &&
                    !contactsInNewAssociation
                      .find(contact => contact.id === task.assignee?.contactId)
                      ?.propertyInfo.find(
                        property =>
                          property.associationId === newAssociationId &&
                          property.accessLevel !== AccessLevel.NoAccess,
                      )

                  // If there are subscribers or assignees that are not associated with the new property, show an alert
                  // and prevent the user from changing the association
                  if (subscribersMissingInTarget || assigneeMissingInTarget) {
                    NiceModal.show(Alert, {
                      message: `This task has subscribers and/or assignees that are not associated with ${newAssociationName}. Remove these users from this task first.`,
                    }).then(() => {})
                    return
                  }

                  // If there are subtasks then, for now, prevent the user from moving the task
                  if (task.subTasks.length) {
                    NiceModal.show(Alert, {
                      message: `This task has sub tasks and cannot be moved.`,
                    }).then(() => {})
                    return
                  }
                }

                // If we're moving property->no-property
                if (!newAssociationId) {
                  // All subscribers and assignees need to be a PM or staff in at least one property, and have no "no access" properties
                  const subscribersWithNoNoPropertyAccess =
                    !task.subscriptions?.every(subscriber =>
                      contactsInNewAssociation
                        .find(contact => contact.id === subscriber.contactId)
                        ?.propertyInfo.find(
                          property =>
                            property.accessLevel !== AccessLevel.NoAccess &&
                            property.propertyRelation &&
                            [
                              PropertyRelation.Staff,
                              PropertyRelation.PropertyManager,
                            ].includes(property.propertyRelation),
                        ),
                    )

                  const assigneeWithNoNoPropertyAccess =
                    task.assignee &&
                    !contactsInNewAssociation
                      .find(contact => contact.id === task.assignee?.contactId)
                      ?.propertyInfo.find(
                        property =>
                          property.accessLevel !== AccessLevel.NoAccess &&
                          property.propertyRelation &&
                          [
                            PropertyRelation.Staff,
                            PropertyRelation.PropertyManager,
                          ].includes(property.propertyRelation),
                      )

                  // If there are subscribers or assignees in the task that won't be able to see the task after the move, show an alert
                  // and prevent the user from changing the association
                  if (
                    subscribersWithNoNoPropertyAccess ||
                    assigneeWithNoNoPropertyAccess
                  ) {
                    NiceModal.show(Alert, {
                      message: `This task has subscribers and/or assignees that cannot see company-level tasks. Remove these users from this task first.`,
                    }).then(() => {})
                    return
                  }

                  // If there are subtasks then, for now, prevent the user from moving the task
                  if (task.subTasks.length) {
                    NiceModal.show(Alert, {
                      message: `This task has sub tasks and cannot be moved.`,
                    }).then(() => {})
                    return
                  }
                }
              }

              onFieldChange('associationId', newAssociationId)
            }}
            allowMultiSelect={false}
          />
          <span style={{ width: 5 }} />
          <LocationSelector
            isDisabled={!canEditTaskDetails || !task.associationId === null}
            associationIds={[task.associationId]}
            locations={task.locations || []}
            onChange={(newValue: LocationReference[] | undefined) => {
              onFieldChange('locations', newValue)
            }}
          />
        </div>
      </FlexRow>
      <FlexRow align="center" style={{ marginTop: 5 }}>
        {pillTitle('Category')}
        <TagSelector
          isDisabled={!canEditTaskDetails}
          value={task.taskCategories}
          onChange={(newValue: Category[] | undefined) =>
            onFieldChange('taskCategories', newValue)
          }
        />
      </FlexRow>

      <FlexRow align="center" style={{ marginTop: 5 }}>
        {pillTitle('Due date')}
        {!task.dueDate && canEditTaskDetails && (
          <SuggestDueDate
            selectedTask={task as SearchableTask}
            onChange={(newValue: any) => {
              onFieldChange('dueDate', newValue)
            }}
            setSuggestedDateString={setSuggestedDateStr}
          />
        )}
        {!suggestedDateStr && (
          <TaskDropdownDatePicker
            isDisabled={!canEditTaskDetails}
            label={
              <FlexRow align="center">
                <span>Set due date</span>
              </FlexRow>
            }
            keepDropdownOpen={keepDateDropdownOpen}
            associationId={task.associationId}
            schedule={task.schedule}
            setSchedule={async (newSchedule: APISchedule | null) => {
              onFieldChange('schedule', newSchedule)
            }}
            value={task.dueDate || undefined}
            onChange={async (newValue: any) => {
              // Prompt the user: change just for this task or for all tasks in the series
              // On cancel - do nothing
              if (task.schedule && newValue === null && task.associationId) {
                setKeepDateDropdownOpen(true)
                // check if the most recent task in the schedule
                const companySnapshots = await getDocs(
                  query(
                    collection(firestore, 'companies'),
                    where(
                      'associationIds',
                      'array-contains',
                      task.associationId,
                    ),
                    limit(1),
                  ),
                )

                if (companySnapshots.empty) {
                  return
                }

                const companyId = companySnapshots.docs[0].id

                const taskSnapshots = await getDocs(
                  query(
                    collection(
                      firestore,
                      'companies',
                      companyId,
                      'companyTasks',
                    ),
                    where('schedule', '==', task.schedule.id),
                    orderBy('dueDate', 'desc'),
                    limit(1),
                  ),
                )

                if (taskSnapshots.empty) {
                  return
                }

                // if the current task is the most recent in the schedule,
                // confirm cancelling the schedule
                if (taskSnapshots.docs[0].id === task.id) {
                  NiceModal.show(ConfirmationModal, {
                    title: 'Remove due date',
                    message: 'This task will no longer be recurring.',
                  }).then(async (result: any) => {
                    if (result === 'confirm' && task.schedule?.id) {
                      onFieldChange('schedule', null) // TODO; would be nice to combine updates into one call (updateTask API does support this)
                      onFieldChange('dueDate', newValue)
                      setKeepDateDropdownOpen(false)
                    } else {
                      setKeepDateDropdownOpen(false)
                    }
                  })
                } else {
                  await onFieldChange('dueDate', newValue)
                  setKeepDateDropdownOpen(false)
                }
              } else if (task.schedule && newValue != null) {
                setKeepDateDropdownOpen(true)
                // if just updating the due date
                NiceModal.show(RadioChoiceModal, {
                  title: 'Edit recurring task',
                  choices: [
                    { id: 'this', label: 'This task' },
                    { id: 'future', label: 'This and following tasks' },
                  ],
                }).then(async (result: any) => {
                  if (
                    result &&
                    task.associationId &&
                    task.schedule &&
                    task.dueDate
                  ) {
                    if (result.id === 'this') {
                      // Create a hole in the schedule for the previous previous due date.
                      onFieldChange('schedule', {
                        ...task.schedule,
                        skipDates: [
                          ...(task.schedule?.skipDates || []),
                          task.dueDate,
                        ],
                      })
                    } else if (result.id === 'future') {
                      onFieldChange('schedule', {
                        ...task.schedule,
                        startDate: newValue,
                      })
                    }
                    onFieldChange('dueDate', newValue)
                    setKeepDateDropdownOpen(false)
                  }
                })
              } else {
                await onFieldChange('dueDate', newValue)
              }
            }}
          />
        )}
      </FlexRow>
      <FlexRow align="center" style={{ marginTop: 5 }}>
        {pillTitle('Priority')}
        <MultilevelDropdown
          isDisabled={!canEditTaskDetails}
          trigger={
            <DropdownTriggerButton
              type="button"
              hasValue
              destructive={task.isUrgent}
            />
          }
          position={Positions.Right}
          title={task.isUrgent ? 'Urgent' : 'Not urgent'}
        >
          <MultilevelHeader
            onChange={setStatusSearchValue}
            isDisabled={false}
            value={statusSearchValue}
            placeholder="Change priority"
            clearValue={() => setStatusSearchValue('')}
          />
          {statusOptions
            .filter(option =>
              option.text
                .toLowerCase()
                .includes(statusSearchValue.toLowerCase()),
            )
            .map(option => (
              <MultilevelItem
                key={option.id}
                onClick={() => onFieldChange('isUrgent', option.value)}
                destructive={option.value}
              >
                {option.text}
              </MultilevelItem>
            ))}
          {statusOptions.filter(option =>
            option.text.toLowerCase().includes(statusSearchValue.toLowerCase()),
          ).length < 1 && <MultilevelNoResults />}
        </MultilevelDropdown>
      </FlexRow>
      {task.workspace != null && (
        <>
          {task.workspace === Workspace.Compliance && (
            <FlexRow align="center" style={{ marginTop: 5 }}>
              {pillTitle('Type')}
              <InspectionTypeSelector
                isDisabled={!canEditTaskDetails}
                value={
                  task.inspectionType !== undefined ? task.inspectionType : ''
                }
                onChange={(value: string) =>
                  onFieldChange('inspectionType', value)
                }
                workspace={task.workspace}
              />
            </FlexRow>
          )}
          <FlexRow align="center" style={{ marginTop: 5 }}>
            {pillTitle('Vendor')}
            <VendorSelector
              isDisabled={!canEditTaskDetails || !task.associationId}
              value={
                task.vendors !== undefined
                  ? task.vendors
                  : ([] as ContactReference[])
              }
              onChange={(newValue: ContactReference[] | []) =>
                onFieldChange('vendors', newValue)
              }
              workspace={task.workspace}
              style={{}}
              associationIds={[task.associationId]}
              maxHeight="calc(100vh - 30vh - 100px)"
              contactsCache={{
                contacts: contactsCache.associationContacts,
                isLoading: contactsCache.isLoading,
                isError: contactsCache.isError,
              }}
            />
          </FlexRow>
          <FlexRow align="center" style={{ marginTop: 5 }}>
            {pillTitle('Budget')}
            <BudgetSelector
              isDisabled={!canEditTaskDetails}
              value={task.budget !== undefined ? task.budget : ''}
              onChange={(value: string) => onFieldChange('budget', value)}
              workspace={task.workspace}
            />
          </FlexRow>
          <FlexRow align="center" style={{ marginTop: 5 }}>
            {pillTitle('Time log')}
            <TimelogSelector
              isDisabled={!canEditTaskDetails}
              value={task.timeLog !== undefined ? task.timeLog : ''}
              onChange={(value: string) => onFieldChange('timeLog', value)}
              workspace={task.workspace}
            />
          </FlexRow>
        </>
      )}

      {children}
    </div>
  )
}

export default TaskActionRow
