import {
  DeliveryStatusType,
  getContactDisplayName,
  ResourceType,
  isAllowed,
  Feature,
  Action,
} from '@super-software-inc/foundation'
import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from 'components/lib/NewTable'
import { toastError } from 'components/lib/Toast'
import { getDeliveries } from 'api/deliveries'
import { isEqual, orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  deliveriesAtom,
  deliveryFormAtom,
  DeliveryFormStage,
} from 'state/atoms'
import LoadingIcon from 'components/lib/LoadingIcon'
import styled from 'styled-components'
import { FlexRow, Switch } from 'components/lib'
import Search from 'components/lib/Search'
import { usePrevious } from 'react-use'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import { TuneOutlined } from '@mui/icons-material'
import {
  selectedAssociationChoicesAtom,
  windowDimensionsAtom,
} from '../../AppRoutes'
import DeliveryRow from './DeliveryRow'

const DeliveriesContent = styled.div`
  padding: 0px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1350px) {
    padding-right: 0;
  }

  @media only screen and (max-width: 600px) {
    p:first-of-type {
      margin-left: 5px;
    }
    padding: 0;
  }
`

enum SortOption {
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Recipient = 'recipient',
  Locations = 'locations',
  ModifiedBy = 'modifiedBy',
}

const DeliveriesListView = () => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const selectedAssociations = useRecoilValue(selectedAssociationChoicesAtom)
  const [windowDimensions] = useRecoilState(windowDimensionsAtom)
  const [deliveryForm, setDeliveryForm] = useRecoilState(deliveryFormAtom)

  const [searchText, setSearchText] = useState('')
  const [sortKey, setSortKey] = useState(SortOption.UpdatedAt)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [deliveries, setDeliveries] = useRecoilState(deliveriesAtom)
  const [includeClosed, setIncludeClosed] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)

  const handleSort = (nextSort: SortOption) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const filteredDeliveries = useMemo(
    () =>
      orderBy(
        (deliveries || []).filter(
          ({ destinations }) =>
            destinations?.some(d =>
              d.unit?.name?.toLowerCase().includes(searchText.toLowerCase()),
            ) ||
            destinations?.some(d =>
              `${getContactDisplayName(d.contact).toLowerCase()}`.includes(
                searchText.toLowerCase(),
              ),
            ),
        ),
        ['updatedAt', 'createdAt'],
        ['asc', 'asc'],
      ),
    [searchText, deliveries],
  )

  const prevSelectedAssociations = usePrevious(selectedAssociations)

  useEffect(() => {
    setDeliveries([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAPIDeliveries = async () => {
    try {
      const d = await getDeliveries(
        authenticatedUser.selectedCompany.id,
        false,
        // An empty array will return all statuses
        includeClosed
          ? [
              DeliveryStatusType.OPEN,
              DeliveryStatusType.CLOSED,
              DeliveryStatusType.VOID,
            ]
          : [DeliveryStatusType.OPEN],
        selectedAssociations.map(a => a.id),
      )
      setDeliveries(d)
      if (initialLoad) {
        setInitialLoad(false)
      }
    } catch (e) {
      // TODO - add sentry logging
      toastError('Failed to load deliveries')
    }
  }

  useEffect(() => {
    if (
      selectedAssociations.length > 0 &&
      !isEqual(selectedAssociations, prevSelectedAssociations)
    ) {
      getAPIDeliveries()
    }

    // Reset the delivery form and deliveries state when the user navigates away from the page
    return () => {
      setDeliveryForm({
        isOpen: false,
        deliveryFormStage: DeliveryFormStage.SelectAction,
        delivery: {},
        labelDestinationOptions: [],
      })

      setDeliveries([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser.selectedCompany.id, selectedAssociations])

  useEffect(() => {
    // don't run on initial load
    if (!initialLoad) {
      if (includeClosed) {
        getAPIDeliveries()
      } else {
        setDeliveries(
          (deliveries || []).filter(
            d => d.status.type === DeliveryStatusType.OPEN,
          ),
        )
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeClosed])

  const canCreateDelivery = useMemo(
    () =>
      authenticatedUser.selectedContact &&
      isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        selectedAssociations.map(a => a.id),
        ResourceType.PROPERTY,
        Feature.DELIVERIES,
        Action.CREATE,
      ),

    [
      authenticatedUser.selectedContact,
      selectedAssociations,
      authenticatedUser.acts,
    ],
  )

  const sortedDeliveries = useMemo(() => {
    if (sortKey === SortOption.UpdatedAt) {
      return orderBy(
        filteredDeliveries,
        d => (d.updatedAt ? d.updatedAt : d.createdAt),
        sortOrder,
      )
    }

    if (sortKey === SortOption.Title) {
      return orderBy(filteredDeliveries, 'title', sortOrder)
    }

    if (sortKey === SortOption.Recipient) {
      return orderBy(
        filteredDeliveries,
        d =>
          d.destinations.map(x =>
            getContactDisplayName(x.contact).toLowerCase(),
          ),
        sortOrder,
      )
    }

    if (sortKey === SortOption.Locations) {
      return orderBy(
        filteredDeliveries,
        d => d.destinations.map(x => x.unit?.name?.toLowerCase()),
        sortOrder,
      )
    }

    if (sortKey === SortOption.ModifiedBy) {
      return orderBy(
        filteredDeliveries,
        d => getContactDisplayName(d.modifiedBy).toLowerCase(),
        sortOrder,
      )
    }

    return filteredDeliveries
  }, [sortKey, sortOrder, filteredDeliveries])

  return (
    <DeliveriesContent>
      <FlexRow
        align="center"
        justify="flex-end"
        style={{ zIndex: 11, position: 'fixed', right: -10 }}
      >
        <Search
          text={searchText}
          changeText={setSearchText}
          placeholder="Search by unit or recipient"
        />

        <StyledSelect
          value={null}
          defaultValue={null}
          renderValue={() => (
            <TuneOutlined style={{ color: '#8a94a6', fontSize: 20 }} />
          )}
        >
          <p style={{ padding: '7px 10px', color: '#8a94a6' }}>
            Display settings
          </p>
          <FlexRow style={{ padding: '7px 10px' }}>
            <p style={{ marginRight: 35 }}>Include closed</p>
            <Switch
              checked={includeClosed}
              onChange={() => setIncludeClosed(!includeClosed)}
            />
          </FlexRow>
        </StyledSelect>
      </FlexRow>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                active={sortKey === SortOption.Title}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Title)}
                style={{
                  textAlign: 'left',
                  width: '50%',
                }}
              >
                Title
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                active={sortKey === SortOption.Locations}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Locations)}
                style={{ textAlign: 'left' }}
              >
                Location
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                active={sortKey === SortOption.Recipient}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Recipient)}
                style={{ textAlign: 'left' }}
              >
                Recipient
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                active={sortKey === SortOption.UpdatedAt}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.UpdatedAt)}
                style={{ textAlign: 'left' }}
              >
                Updated
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                active={sortKey === SortOption.ModifiedBy}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.ModifiedBy)}
                style={{ textAlign: 'left' }}
              >
                Updated by
              </SortableTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDeliveries &&
              sortedDeliveries.length > 0 &&
              sortedDeliveries.map(d => (
                <React.Fragment key={d.id}>
                  <DeliveryRow delivery={d} />
                </React.Fragment>
              ))}
          </TableBody>

          {sortedDeliveries && sortedDeliveries.length === 0 && (
            <TableRow>
              <TableHeaderCell
                colSpan={windowDimensions.isMobile ? 3 : 5}
                style={{
                  textTransform: 'none',
                  textAlign: 'left',
                  fontWeight: 500,
                }}
              >
                {initialLoad ? (
                  <FlexRow align="center" justify="center">
                    <LoadingIcon />
                    <p style={{ marginLeft: 10 }}>Loading...</p>
                  </FlexRow>
                ) : (
                  <>
                    No deliveries to display.{' '}
                    {canCreateDelivery && (
                      <>
                        <span
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            setDeliveryForm({
                              ...deliveryForm,
                              isOpen: true,
                              deliveryFormStage: DeliveryFormStage.SelectAction,
                            })
                          }
                        >
                          Add your first delivery
                        </span>{' '}
                        to get started.
                      </>
                    )}
                  </>
                )}
              </TableHeaderCell>
            </TableRow>
          )}
        </Table>
      </TableWrapper>
    </DeliveriesContent>
  )
}

export default DeliveriesListView
