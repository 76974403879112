import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { APIACT } from '@super-software-inc/foundation'
import { firebaseApp } from '../firebase/setup'

const getAccessControlTypes = async (): Promise<APIACT[]> => {
  const db = getFirestore(firebaseApp)
  const accessControlTypesRef = collection(db, 'accessControlTypes')

  try {
    const snapshot = await getDocs(accessControlTypesRef)
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    })) as APIACT[]
  } catch (error) {
    return []
  }
}

export default getAccessControlTypes
