import {
  APIContact,
  APISchedule,
  ContactReference,
  Feature,
  isAllowed,
  LocationReference,
  ResourceType,
  Task,
  Workspace,
  Action,
} from '@super-software-inc/foundation'
import Editor from 'components/app/Editor/Editor'
import { EditorRef } from 'components/app/Editor/types'
import {
  DropdownTriggerButton,
  FlexColumn,
  FlexRow,
  GhostTextInput,
  MultilevelDropdown,
  MultilevelHeader,
  MultilevelItem,
  MultilevelNoResults,
  PrimaryButton,
  TaskDropdownDatePicker,
  TextButton,
} from 'components/lib'
import { Positions } from 'components/lib/MultilevelDropdown'
import { toastError } from 'components/lib/Toast'
import { Field, Form, Formik } from 'formik'
import useContactsCache from 'hooks/useContactsCache'
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom, taskInitialValuesAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import {
  associationChoicesAtom,
  selectedAssociationChoicesIdsSelector,
  windowDimensionsAtom,
} from '../../AppRoutes'
import RollupAssigneeDropdown from './RollupAssigneeDropdown'
import TagSelector from './Tags/TagSelector'
import BudgetSelector from './Tasks/BudgetSelector'
import InspectionTypeSelector from './Tasks/InspectionTypeSelector'
import LocationSelector from './Tasks/LocationSelector'
import PropertySelector from './Tasks/PropertySelector'
import { pillTitle } from './Tasks/TaskActionRow'
import TimelogSelector from './Tasks/TimelogSelector'
import VendorSelector from './Tasks/VendorSelector'
import WorkspaceSelector from './Tasks/WorkspaceSelector'

export interface TaskFormTask extends Task {
  scheduleData: APISchedule | null
  associationIds?: string[]
}

const EditorWrapper = styled.div`
  width: 100%;
  min-height: 128px;
  max-height: 300px;
  flex: 1;
  border-radius: 4;
  overflow: auto;

  @media (min-width: 1000px) {
    width: 480;
  }
`

const statusOptions = [
  {
    id: 0,
    value: false,
    text: 'Not urgent',
  },
  {
    id: 1,
    value: true,
    text: 'Urgent',
  },
]

const TaskForm = ({
  type,
  onSubmit,
  onCancel,
}: {
  type?: 'meeting' | 'task' | undefined
  onSubmit: Function
  onCancel: Function
}) => {
  const editorRef = useRef<EditorRef>(null)
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const taskInitialValues = useRecoilValue(taskInitialValuesAtom)
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const associationIds = useRecoilValue(selectedAssociationChoicesIdsSelector)

  const { data, isLoading, isError } = useContactsCache(associationIds)
  const contacts = data

  const [statusSearchValue, setStatusSearchValue] = useState('')
  const [selectedAssociations, setSelectedAssociations] = useState<string[]>(
    taskInitialValues?.associationId
      ? [taskInitialValues.associationId] || []
      : [],
  )
  const [availableContacts, setAvailableContacts] = useState<APIContact[]>([])

  useEffect(() => {
    if (selectedAssociations.length === 1) {
      setAvailableContacts(
        contacts.filter(c =>
          c.propertyInfo.find(p => p.associationId === selectedAssociations[0]),
        ),
      )
    } else {
      setAvailableContacts([])
    }
  }, [selectedAssociations, contacts])

  const TaskSchema = Yup.object({
    title: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .required('Required'),
    description: Yup.string(),
    status: Yup.string(),
    isUrgent: Yup.bool(),
    associationIds: Yup.array().of(Yup.string()),
  })

  return (
    <Formik
      initialValues={{
        ...taskInitialValues,
        associationIds: taskInitialValues?.associationId
          ? [taskInitialValues.associationId]
          : [],
      }}
      validationSchema={TaskSchema}
      onSubmit={async (values, { setSubmitting }) => {
        if (values.associationIds.length === 0) {
          toastError('Select a property first.')
          return
        }

        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ isValid, isSubmitting, setFieldValue, values, dirty }) => (
        <Form
          style={{
            width: 600,
            maxWidth: '80vw',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <FlexColumn style={{ height: '100%' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <FlexRow align="center">
                <Field
                  autoFocus
                  name="title"
                  type="text"
                  placeholder="Add title"
                  as={GhostTextInput}
                  style={{ fontSize: '20px', fontWeight: 'bold' }}
                />
              </FlexRow>
              <EditorWrapper style={{ marginBottom: 16 }}>
                <Editor
                  ref={editorRef}
                  placeholder="Add a description, drag and drop a file, or @ mention groups and individuals."
                  value={taskInitialValues?.description}
                  onChange={val => setFieldValue('description', val)}
                  associationId={
                    values.associationIds.length === 1
                      ? values.associationIds[0]
                      : undefined
                  }
                  // FIXME: this doesn't work quite right; mentions text
                  // disappears from the Editor but still exists and the
                  // contact is subscribed to the task
                  // disableMentions={values.associationIds.length !== 1}
                  contacts={availableContacts}
                  style={{ overflow: 'scroll' }}
                  isTaskForm
                />
              </EditorWrapper>
            </div>
            <div>
              <div>
                <h4>Details</h4>
              </div>
              <FlexRow align="center" style={{ marginTop: 5 }}>
                {pillTitle('Workspace')}
                <WorkspaceSelector
                  isDisabled={
                    values.associationIds.length === 0 ||
                    !isAllowed(
                      authenticatedUser.selectedContact,
                      authenticatedUser.acts,
                      values.associationIds,
                      ResourceType.PROPERTY,
                      Feature.TASKS,
                      Action.EDIT,
                    )
                  }
                  value={values.workspace || null}
                  onChange={(newValue: Workspace | null) => {
                    setFieldValue('workspace', newValue)
                    // TODO - remove after implementing createTask API
                    if (values.budget && values.budget?.length) {
                      setFieldValue('budget', '')
                    }

                    if (values.timeLog && values.timeLog?.length) {
                      setFieldValue('timeLog', '')
                    }

                    if (values.vendors?.length) {
                      setFieldValue('vendors', [] as ContactReference[])
                    }
                  }}
                />
              </FlexRow>
              <FlexRow
                align={windowDimensions.isMobile ? 'flex-start' : 'center'}
                style={{ marginTop: 5 }}
              >
                {pillTitle('Location')}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: windowDimensions.isMobile ? 'column' : 'row',
                  }}
                >
                  <Field name="associationIds">
                    {({
                      field: { value = [] },
                    }: {
                      /* eslint-disable-next-line react/no-unused-prop-types */
                      field: { value: string[] }
                    }) => (
                      <PropertySelector
                        value={value}
                        onChange={(newValue: string[]) => {
                          setSelectedAssociations(newValue)
                          setFieldValue('associationIds', newValue)
                        }}
                        allowMultiSelect={associationChoices.length > 1}
                        isDisabled={false}
                      />
                    )}
                  </Field>
                  <LocationSelector
                    associationIds={values.associationIds}
                    locations={values.locations || []}
                    onChange={(newValue: LocationReference | null) => {
                      setFieldValue('locations', newValue)
                    }}
                    isDisabled={values.associationIds[0] === null}
                  />
                </div>
              </FlexRow>
              <FlexRow align="center" style={{ marginTop: 5 }}>
                {pillTitle('Assigned')}
                <Field name="assignee">
                  {({ field: { value } }: any) => {
                    if (values.associationIds?.length === 0) {
                      return (
                        <DropdownTriggerButton
                          type="button"
                          style={{
                            cursor: 'default',
                          }}
                          onClick={() => toastError('Select a property first.')}
                        >
                          <>
                            <span
                              className="material-symbols-rounded"
                              style={{ fontSize: 16, paddingRight: 6 }}
                            >
                              account_circle
                            </span>
                            <p>Assignee</p>
                          </>
                        </DropdownTriggerButton>
                      )
                    }
                    return (
                      <RollupAssigneeDropdown
                        value={value}
                        onChange={(newValue: ContactReference | null) => {
                          setFieldValue('assignee', newValue)
                        }}
                        associationIds={values.associationIds}
                        maxHeight="calc(100vh - 55vh - 100px)"
                      />
                    )
                  }}
                </Field>
              </FlexRow>
              <FlexRow align="center" style={{ marginTop: 5 }}>
                {pillTitle('Category')}
                <Field name="taskCategories">
                  {({ field: { value } }: any) => (
                    <TagSelector
                      value={value}
                      onChange={(newValue: any) => {
                        setFieldValue('taskCategories', newValue)
                      }}
                    />
                  )}
                </Field>
              </FlexRow>
              <FlexRow align="center" style={{ marginTop: 5 }}>
                {pillTitle('Due date')}
                <Field name="dueDate">
                  {({ field: { value } }: any) => (
                    <TaskDropdownDatePicker
                      positions={['top', 'right']}
                      label={
                        <FlexRow align="center">
                          <span>Set due date</span>
                        </FlexRow>
                      }
                      value={value}
                      schedule={values.scheduleData}
                      setSchedule={v => {
                        setFieldValue('scheduleData', v)
                      }}
                      onChange={(newValue: string) => {
                        if (typeof newValue === 'object') {
                          setFieldValue('dueDate', undefined)
                        } else {
                          setFieldValue('dueDate', newValue)
                          if (values.scheduleData) {
                            setFieldValue('scheduleData', {
                              ...values.scheduleData,
                              startDate: newValue,
                            })
                          }
                        }
                      }}
                    />
                  )}
                </Field>
              </FlexRow>
              <FlexRow align="center" style={{ marginTop: 5 }}>
                {pillTitle('Priority')}
                <Field name="isUrgent">
                  {({ field: { value } }: any) => (
                    <MultilevelDropdown
                      position={Positions.Right}
                      title={
                        <>
                          <span
                            className="material-symbols-rounded"
                            style={{ fontSize: 16, paddingRight: 6 }}
                          >
                            error
                          </span>
                          {value ? 'Urgent' : 'Not urgent'}
                        </>
                      }
                      trigger={
                        <DropdownTriggerButton
                          type="button"
                          hasValue
                          destructive={value}
                        />
                      }
                    >
                      <MultilevelHeader
                        onChange={setStatusSearchValue}
                        isDisabled={false}
                        value={statusSearchValue}
                        placeholder="Change priority"
                        clearValue={() => setStatusSearchValue('')}
                      />
                      {statusOptions
                        .filter(option =>
                          option.text
                            .toLowerCase()
                            .includes(statusSearchValue.toLowerCase()),
                        )
                        .map(option => (
                          <MultilevelItem
                            key={option.id}
                            onClick={() =>
                              setFieldValue('isUrgent', option.value)
                            }
                            destructive={option.value}
                          >
                            {option.text}
                          </MultilevelItem>
                        ))}
                      {statusOptions.filter(option =>
                        option.text
                          .toLowerCase()
                          .includes(statusSearchValue.toLowerCase()),
                      ).length < 1 && <MultilevelNoResults />}
                    </MultilevelDropdown>
                  )}
                </Field>
              </FlexRow>
              {values.workspace != null && (
                <>
                  {values.workspace === Workspace.Compliance && (
                    <FlexRow align="center" style={{ marginTop: 5 }}>
                      {pillTitle('Type')}
                      <InspectionTypeSelector
                        value={values.inspectionType || ''}
                        onChange={(newValue: string) => {
                          setFieldValue('inspectionType', newValue)
                        }}
                        workspace={values.workspace}
                      />
                    </FlexRow>
                  )}

                  <FlexRow align="center" style={{ marginTop: 5 }}>
                    {pillTitle('Vendor')}
                    <VendorSelector
                      value={
                        values.vendors !== undefined
                          ? values.vendors
                          : ([] as ContactReference[])
                      }
                      onChange={(newValue: ContactReference[] | []) =>
                        setFieldValue('vendors', newValue)
                      }
                      style={{}}
                      associationIds={values.associationIds}
                      maxHeight="calc(100vh - 30vh - 100px)"
                      workspace={values.workspace}
                      contactsCache={{
                        contacts,
                        isLoading,
                        isError,
                      }}
                    />
                  </FlexRow>
                  <FlexRow align="center" style={{ marginTop: 5 }}>
                    {pillTitle('Budget')}
                    <BudgetSelector
                      value={values.budget || ''}
                      onChange={(newValue: string) => {
                        setFieldValue('budget', newValue)
                      }}
                      workspace={values.workspace}
                    />
                  </FlexRow>
                  <FlexRow align="center" style={{ marginTop: 5 }}>
                    {pillTitle('Time log')}
                    <TimelogSelector
                      value={values.timeLog || ''}
                      onChange={(newValue: string) => {
                        setFieldValue('timeLog', newValue)
                      }}
                      workspace={values.workspace}
                    />
                  </FlexRow>
                </>
              )}
            </div>
          </FlexColumn>
          <FlexRow align="center">
            <TextButton
              type="button"
              onClick={() => onCancel()}
              style={{ marginLeft: 'auto', marginRight: 4 }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              type="submit"
              disabled={!isValid || isSubmitting || !dirty}
            >
              Submit
            </PrimaryButton>
          </FlexRow>
        </Form>
      )}
    </Formik>
  )
}

export default TaskForm
