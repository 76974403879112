import {
  AccessLevel,
  Action,
  ContactGroup,
  isAllowed,
  Feature,
  getContactDisplayName,
  ResourceType,
  Task,
  TaskFilterType,
} from '@super-software-inc/foundation'
import {
  CopyEmailDialog,
  CopyPhoneDialog,
  Divider,
  FlexRow,
  IconButton,
  TextButton,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import React, { useMemo } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  profileModalAtom,
  taskFiltersAtom,
} from 'state/atoms'
import styled from 'styled-components/macro'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import {
  associationChoicesAtom,
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
} from '../../../AppRoutes'
import FirebaseUserAvatar from '../FirebaseUserAvatar'
import AccessDropdown from './AccessDropdown'
import BoardSection from './Board/BoardSection'
import MultiHOABoardSection from './Board/MultiHOABoardSection'
import ContactActionDropdown from './ContactActionDropdown'
import FilesSection from './FilesSection'
import MultiHOAProfileUnitsSection from './Units/MultiHOAProfileUnitsSection'
import ProfileUnitsSection from './Units/ProfileUnitsSection'
import {
  AutoCreatedTooltip,
  AutoCreatedLabel,
} from '../Directory/UncategorizedContacts'
import PropertiesSection from './Properties/PropertiesSection'

const groupsWithUnits = [
  ContactGroup.Sponsors,
  ContactGroup.Board,
  ContactGroup.Owners,
  ContactGroup.Renters,
  ContactGroup.Residents,
  ContactGroup.PastOwners,
  ContactGroup.PastRenters,
  ContactGroup.PastResidents,
]

const groupsWithBoard = [
  ContactGroup.Board,
  ContactGroup.Owners,
  ContactGroup.Sponsors,
]

const ProfileSidebarContent = styled.div`
  font-size: 14px;
  height: 100%;
  overflow: scroll;
`

export const SidebarSection = styled.div`
  line-height: 20px;
  margin-bottom: 24px;
`

interface SectionTitleProps {
  noMargin?: boolean
}

export const SectionTitle = styled.div<SectionTitleProps>`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: ${props => (props.noMargin ? '0' : '4px')};
`

const SectionInfo = styled.div<SectionTitleProps>`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${props => props.theme.colors.text250};
`

interface ProfileSidebarProps {
  tasks: Task[]
}

const ProfileSidebar: React.FC<ProfileSidebarProps> = () => {
  const navigate = useNavigate()
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const [, setTaskFilters] = useRecoilState(taskFiltersAtom)
  const [, setSelectedAssociations] = useRecoilState(
    selectedAssociationChoicesAtom,
  )
  const associationChoices = useRecoilValue(associationChoicesAtom)

  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)

  const isViewingSingleAssociation = useMemo(
    () => selectedAssociationChoices.length === 1,
    [selectedAssociationChoices],
  )

  const { selectedContact: contact, corpFirst } = profileModal

  const selectedPropertyInfo = useMemo(() => {
    if (isViewingSingleAssociation) {
      return (
        contact?.propertyInfo?.find(
          p => p.associationId === primaryAssociation.id,
        ) ?? null
      )
    }

    return null
  }, [contact, primaryAssociation, isViewingSingleAssociation])

  const canEditContacts = useMemo(
    () =>
      isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        [primaryAssociation.id],
        ResourceType.PROPERTY,
        Feature.CONTACTS,
        Action.EDIT,
      ),
    [
      authenticatedUser.selectedContact,
      primaryAssociation.id,
      authenticatedUser.acts,
    ],
  )

  const isAssistant = useMemo(
    () =>
      contact?.id ===
      authenticatedUser.selectedCompany.assistantContact?.contactId,
    [contact, authenticatedUser],
  )

  // check the loading status
  if (!contact) {
    return null
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FlexRow align="center" justify="space-between">
        <h3>Profile</h3>
        <FlexRow>
          {!corpFirst && !isAssistant && (
            <div>
              <ContactActionDropdown contact={contact} />
            </div>
          )}
          <IconButton
            onClick={() =>
              setProfileModal({ ...profileModal, sidebarIsOpen: false })
            }
            style={{ marginLeft: 'auto' }}
          >
            <MdClose />
          </IconButton>
        </FlexRow>
      </FlexRow>

      <FlexRow justify="center" align="center" style={{ marginBottom: 16 }}>
        {contact && (
          <FirebaseUserAvatar
            key={contact.photoURL}
            photoURL={contact.photoURL}
            displayName={getContactDisplayName(contact)}
            size={64}
          />
        )}
      </FlexRow>
      <FlexRow justify="center" align="center" style={{ fontWeight: 600 }}>
        <TruncatedText>{getContactDisplayName(contact)}</TruncatedText>
      </FlexRow>
      {isViewingSingleAssociation && selectedPropertyInfo && !corpFirst && (
        <FlexRow justify="center" align="center" className="mb-2">
          {!selectedPropertyInfo.groups?.includes(
            ContactGroup.Uncategorized,
          ) ? (
            selectedPropertyInfo.title
          ) : (
            <AutoCreatedTooltip>
              (<AutoCreatedLabel />)
            </AutoCreatedTooltip>
          )}
        </FlexRow>
      )}
      {isViewingSingleAssociation &&
        selectedPropertyInfo &&
        !corpFirst &&
        !isAssistant &&
        !selectedPropertyInfo.groups?.includes(ContactGroup.Uncategorized) && (
          <FlexRow justify="center" align="center">
            <AccessDropdown contact={contact} isSidebar />
          </FlexRow>
        )}
      <div style={{ height: corpFirst ? 10 : 24 }} />
      <FlexRow justify="center" align="center">
        <FlexRow align="center" style={{ color: '#627088' }}>
          <TextButton
            type="button"
            onClick={() => {
              setTaskFilters([
                { type: TaskFilterType.Subscriber, value: [contact.id] },
              ])
              setSelectedAssociations(associationChoices)
              navigate('/tasks')
            }}
          >
            <span>View tasks</span>
          </TextButton>
        </FlexRow>
      </FlexRow>
      <div style={{ height: 24 }} />
      <Divider smMargin />
      {!isAssistant && (
        <ProfileSidebarContent>
          <FlexRow
            justify="space-between"
            align="center"
            style={{ marginBottom: 20 }}
          >
            <div className="text-slate-500 text-xs font-semibold uppercase leading-[18px] tracking-wide">
              Details
            </div>
            {(authenticatedUser.selectedContact?.id ===
              profileModal.selectedContact.id ||
              isAllowed(
                authenticatedUser.selectedContact,
                authenticatedUser.acts,
                [primaryAssociation.id],
                ResourceType.PROPERTY,
                Feature.CONTACTS,
                Action.EDIT,
              ) ||
              (corpFirst &&
                authenticatedUser.selectedContact.propertyInfo.find(
                  p => p.accessLevel === AccessLevel.AdminAccess,
                ))) && (
              <TextButton
                type="button"
                onClick={() =>
                  setProfileModal({
                    ...profileModal,
                    editProfileIsOpen: true,
                  })
                }
              >
                Edit
              </TextButton>
            )}
          </FlexRow>
          {contact?.businessName && (
            <SidebarSection>
              <SectionTitle>Company Name</SectionTitle>
              <SectionInfo>{contact.businessName}</SectionInfo>
            </SidebarSection>
          )}
          {contact?.ein && (
            <SidebarSection>
              <SectionTitle>Vendor EIN</SectionTitle>
              <SectionInfo>{contact.ein}</SectionInfo>
            </SidebarSection>
          )}
          {contact.mailingAddress &&
            (contact.mailingAddress.city?.length > 1 ||
              contact.mailingAddress.line1?.length > 1 ||
              (contact.mailingAddress.line2 &&
                contact.mailingAddress.line2?.length > 1) ||
              contact.mailingAddress.state?.length > 1 ||
              contact.mailingAddress.zip?.length > 1) && (
              <SidebarSection>
                <SectionTitle>Address</SectionTitle>
                <SectionInfo>
                  {contact.mailingAddress.line1
                    ? contact.mailingAddress.line1
                    : ''}
                  {contact.mailingAddress.line2
                    ? `, ${contact.mailingAddress.line2}`
                    : ''}
                </SectionInfo>
                <SectionInfo>
                  {contact.mailingAddress.city
                    ? contact.mailingAddress.city
                    : ''}
                  {contact.mailingAddress.state
                    ? `, ${contact.mailingAddress.state}`
                    : ''}{' '}
                  {contact.mailingAddress.zip ? contact.mailingAddress.zip : ''}
                </SectionInfo>
              </SidebarSection>
            )}
          {contact.email && (
            <SidebarSection>
              <SectionTitle>Email</SectionTitle>
              <Tooltip overlay={contact.email} placement="top">
                <SectionInfo>
                  <CopyEmailDialog
                    email={contact.email}
                    pretext="Primary email:"
                  />
                </SectionInfo>
              </Tooltip>
              {contact.secondaryEmails?.map(secondaryEmail => (
                <Tooltip overlay={secondaryEmail} placement="top">
                  <SectionInfo key={secondaryEmail}>
                    <CopyEmailDialog
                      email={secondaryEmail}
                      pretext="Secondary email:"
                    />
                  </SectionInfo>
                </Tooltip>
              ))}
            </SidebarSection>
          )}
          {((contact.phone?.number && contact.phone?.number.length > 0) ||
            (contact.secondaryPhones?.length > 0 &&
              contact.secondaryPhones[0].number.length > 0)) && (
            <SidebarSection>
              <SectionTitle>Phone</SectionTitle>
              {contact.phone?.number && (
                <Tooltip
                  placement="top"
                  overlay={formatPhoneNumber(contact.phone?.number)}
                >
                  <SectionInfo>
                    <CopyPhoneDialog
                      phone={contact.phone?.number}
                      displayText={`Primary ${
                        contact.phone.type
                      }: ${formatPhoneNumber(contact.phone.number)}`}
                    />
                  </SectionInfo>
                </Tooltip>
              )}
              {contact.secondaryPhones?.map(secondaryPhone => (
                <Tooltip
                  placement="top"
                  overlay={formatPhoneNumber(secondaryPhone.number)}
                >
                  <SectionInfo key={secondaryPhone.number}>
                    <CopyPhoneDialog
                      phone={secondaryPhone.number}
                      displayText={`Alternate ${
                        secondaryPhone.type
                      }: ${formatPhoneNumber(secondaryPhone.number)}`}
                    />
                  </SectionInfo>
                </Tooltip>
              ))}
            </SidebarSection>
          )}
          {contact.website && contact.website.length > 0 && (
            <SidebarSection>
              <SectionTitle>Website</SectionTitle>
              <SectionInfo style={{ textDecoration: 'underline' }}>
                {contact.website}
              </SectionInfo>
            </SidebarSection>
          )}

          {contact.emergencyContact && (
            <SidebarSection>
              <SectionTitle>Emergency contact</SectionTitle>
              <SectionInfo>
                {contact.emergencyContact.firstName}{' '}
                {contact.emergencyContact.lastName}
              </SectionInfo>
              <SectionInfo>{contact.emergencyContact.relationship}</SectionInfo>
              <SectionInfo>{contact.emergencyContact.email}</SectionInfo>
              <SectionInfo>
                {contact.emergencyContact.phone &&
                  formatPhoneNumber(contact.emergencyContact.phone)}
              </SectionInfo>
            </SidebarSection>
          )}
          {!corpFirst && (
            <>
              {' '}
              {!corpFirst &&
                !isViewingSingleAssociation &&
                selectedPropertyInfo?.groups?.some(g =>
                  groupsWithUnits.includes(g),
                ) && <MultiHOAProfileUnitsSection />}
              {isViewingSingleAssociation &&
                selectedPropertyInfo?.groups?.some(g =>
                  groupsWithUnits.includes(g),
                ) && (
                  <ProfileUnitsSection
                    contact={contact}
                    canEditContacts={canEditContacts}
                    corpFirst={corpFirst}
                    associationId={primaryAssociation.id}
                  />
                )}
              {isViewingSingleAssociation &&
                selectedPropertyInfo?.groups.some(g =>
                  groupsWithBoard.includes(g),
                ) && (
                  <BoardSection
                    contact={contact}
                    canEditContacts={canEditContacts}
                    corpFirst={corpFirst}
                    associationId={primaryAssociation.id}
                  />
                )}
              {contact.propertyInfo.some(p =>
                p.groups?.some(g => groupsWithBoard.includes(g)),
              ) &&
                !corpFirst &&
                !isViewingSingleAssociation && <MultiHOABoardSection />}
            </>
          )}

          {contact.propertyInfo.some(p => p.propertyRelation) && (
            <FilesSection
              path={`companies/${contact.companyId}/companyContactFiles/${contact.id}/relevantFiles`}
            />
          )}
          {corpFirst && <PropertiesSection />}
        </ProfileSidebarContent>
      )}
    </div>
  )
}

export default ProfileSidebar
