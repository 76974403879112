import { FlexRow, MultilevelItem } from 'components/lib'
import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useFunctions } from 'reactfire'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { sortSubscriberAssigneeContacts } from 'utils/sortSubscriberAssigneeContacts'
import { FixedSizeList as List } from 'react-window'
import {
  APIContact,
  createContactReference,
  getContactDisplayName,
  PropertyInfo,
} from '@super-software-inc/foundation'
import LoadingIcon from 'components/lib/LoadingIcon'
import StyledSelectContainer from 'components/lib/MaterialElements/StyledSelect'
import { selectedTaskAtom } from 'pages/Tasks'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import AddUncategorizedContact from '../AddUncategorizedContact'
import {
  ContactRow,
  DropdownFixedHeader,
} from '../Dropdowns/ContactSelectEmailSms'

const ItemRenderer = ({
  index,
  data,
  style,
}: {
  index: number
  data: {
    data: any[] // TODO - update type
    onChange: Function
    setSearchAssignee: Function
    allowAiAssistant?: boolean
  }
  style: React.CSSProperties
}) => {
  const item = data.data[index]
  return (
    <div style={style}>
      <MultilevelItem key={item.id} active={item.selected}>
        <ContactRow
          option={item}
          toggleContact={() => {
            if (item.id) {
              data.onChange(createContactReference(item.data))
              data.setSearchAssignee('')
            } else {
              data.onChange(null)
            }
          }}
          allowSms={false}
          hideAiAssistant={false}
        />
      </MultilevelItem>
    </div>
  )
}

const AssigneeDropdown = ({
  value,
  onChange,
  style,
  isDisabled = false,
  contactsCache,
}: {
  value: any
  onChange: Function
  style: any
  isDisabled?: boolean
  contactsCache: {
    associationContacts: APIContact[]
    isLoading: {
      isLoadingFirstTime: boolean
      isLoadingFinalTime: boolean
    }
    isError: boolean
  }
}) => {
  const selectedTask = useRecoilValue(selectedTaskAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const { associationContacts, isLoading } = contactsCache
  const [searchAssignee, setSearchAssignee] = useState<string>('')
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [contactOptions, setContactOptions] = useState<any[]>([])

  const onNewContactSuccess = (newContact: APIContact) => {
    onChange(
      createContactReference(
        newContact as APIContact,
        selectedTask?.associationId,
      ),
    )
  }

  const functions = useFunctions()
  functions.region = 'us-east1'

  useEffect(() => {
    const options =
      associationContacts && associationContacts.length > 0
        ? [
            {
              id: 0,
              value: null,
              label: 'Unassigned',
              selected: value === null,
            },
            ...(
              associationContacts.filter(
                contact =>
                  getContactDisplayName(contact)
                    .toLowerCase()
                    .includes(searchAssignee.toLowerCase()) ||
                  (!!contact.email &&
                    contact.email.toLowerCase().includes(searchAssignee)),
              ) as APIContact[]
            ).map(p => ({
              id: p.id,
              data: p,
              value: p.id,
              label: getContactDisplayName(p),
              title: p.propertyInfo.find(
                (info: PropertyInfo) =>
                  info.associationId === selectedTask?.associationId,
              )?.title,
              selectedType:
                value != null && p.id === value.contactId ? ['email'] : [],
              photoURL: p.photoURL,
            })),
          ]
        : []
    setContactOptions(sortSubscriberAssigneeContacts(options))
  }, [
    value,
    associationContacts,
    searchAssignee,
    authenticatedUser.selectedContact,
    selectedTask?.associationId,
  ])

  return (
    <>
      {modalIsOpen && selectedTask?.associationId && (
        <AddUncategorizedContact
          onClose={() => setModalIsOpen(false)}
          pageTitle="Assign"
          instructions="An assignee is able to view task history and will be notified of any future updates."
          value={searchAssignee}
          valueOnChange={setSearchAssignee}
          associationId={selectedTask.associationId}
          onSuccess={onNewContactSuccess}
        />
      )}

      <StyledSelectContainer
        disabled={isDisabled}
        defaultValue={null}
        value={value ? getContactDisplayName(value) : 'Unassigned'}
        renderValue={() => (
          <FlexRow align="center" justify="space-between">
            <AccountCircleOutlinedIcon />
            {value ? getContactDisplayName(value) : 'Unassigned'}
          </FlexRow>
        )}
        selectStyle="pill"
      >
        <DropdownFixedHeader
          search={searchAssignee}
          setSearch={setSearchAssignee}
          handleReset={() => setSearchAssignee('')}
          searchPlaceHolder="Assign to"
          allowSms={false}
        />
        {isLoading.isLoadingFirstTime && <LoadingIcon />}
        {contactOptions.length === 1 && selectedTask?.associationId && (
          <MultilevelItem
            key={searchAssignee}
            onClick={() => {
              setModalIsOpen(true)
            }}
          >
            <FlexRow align="center">
              <MdAdd style={{ fontSize: 16, marginRight: 5 }} />
              Assign to {searchAssignee}
            </FlexRow>
          </MultilevelItem>
        )}
        <List
          itemData={{
            data: contactOptions,
            onChange,
            setSearchAssignee,
          }}
          height={300}
          itemCount={contactOptions.length}
          itemSize={40}
          width={430}
        >
          {ItemRenderer}
        </List>
      </StyledSelectContainer>
    </>
  )
}

export default AssigneeDropdown
