import React from 'react'
import DeleteableSelectChip from 'components/lib/MaterialElements/DeletableSelectChip'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  taskFiltersAtom,
  taskFilterSelectOpenAtom,
} from 'state/atoms'
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { AssociationFilter } from '@super-software-inc/foundation'
import SelectSearchBar from 'components/lib/MaterialElements/SelectSearchBar'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import { associationChoicesAtom } from '../../../AppRoutes'

const PropertyFilter = ({ filter }: { filter: AssociationFilter }) => {
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const [searchText, setSearchText] = React.useState('')
  const [taskFilters, setTaskFilters] = useRecoilState(taskFiltersAtom)
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)
  const [taskFilterSelectOpen, setTaskFilterSelectOpen] = useRecoilState(
    taskFilterSelectOpenAtom,
  )

  const associationChoicesMemo = React.useMemo(
    () =>
      associationChoices
        .filter(c => c.name.toLowerCase().includes(searchText.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [associationChoices, searchText],
  )

  return (
    <DeleteableSelectChip
      label={`Accounts: 
        ${
          filter.value?.length === 1
            ? filter.value[0] === 'null'
              ? selectedCompany.name
              : associationChoices.find(
                  association => association.id === filter.value[0],
                )?.name
            : filter.value?.length > 1
            ? `${filter.value.length}`
            : ''
        }
        `}
      open={taskFilterSelectOpen && taskFilterSelectOpen === filter.type}
      onOpen={() => {
        setTaskFilterSelectOpen(filter.type)
      }}
      onClose={() => {
        setTaskFilterSelectOpen(undefined)
        // remove the selected filter if the user closes the menu without selecting anything
        if (taskFilters.find(f => f.type === filter.type)?.value.length === 0) {
          setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
        }
      }}
      onDelete={() => {
        setTaskFilterSelectOpen(undefined)
        setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
      }}
      handleSelect={(valueArray: string) => {
        const value = valueArray[0]
        if (value === 'all') {
          setTaskFilters(
            taskFilters.map(f =>
              f.type === filter.type
                ? {
                    ...f,
                    value: [...associationChoices.map(c => c.id), 'null'],
                  }
                : f,
            ),
          )
        } else if (value === 'none') {
          setTaskFilters(
            taskFilters.map(f =>
              f.type === filter.type ? { ...f, value: [] } : f,
            ),
          )
        } else {
          const updatedFilter = {
            ...filter,
            value: filter.value?.includes(value)
              ? filter.value.filter((v: string) => v !== value)
              : [...filter.value, value],
          }
          setTaskFilters(
            taskFilters.map(f => (f.type === filter.type ? updatedFilter : f)),
          )
        }
        setSearchText('')
        // prevent the menu from closing after selecting an item
        // event.stopPropagation()
      }}
    >
      <SelectSearchBar
        placeholder="Search properties"
        setSearchText={setSearchText}
      />
      <div style={{ marginTop: 5 }} />
      <StyledMenuItem
        key="all"
        value={
          filter.value.length === associationChoices.length + 1 ? 'none' : 'all'
        }
      >
        <span style={{ marginRight: 8 }}>
          {filter.value.length === associationChoices.length + 1 ? (
            <CheckBoxOutlined />
          ) : (
            <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
          )}
        </span>
        Select/Deselect all ({associationChoices.length + 1})
      </StyledMenuItem>
      {/* corp option */}
      <p
        style={{
          color: '#627088',
          fontSize: 14,
          fontWeight: '400',
          marginLeft: 12,
          marginTop: 6,
          marginBottom: 6,
        }}
      >
        Company
      </p>
      <StyledMenuItem key="null" value="null">
        <span style={{ marginRight: 8 }}>
          {filter.value?.includes('null') ? (
            <CheckBoxOutlined />
          ) : (
            <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
          )}
        </span>
        {selectedCompany.name}
      </StyledMenuItem>
      <p
        style={{
          color: '#627088',
          fontSize: 14,
          fontWeight: '400',
          marginLeft: 12,
          marginTop: 6,
          marginBottom: 6,
        }}
      >
        Properties
      </p>
      {/* associations options */}
      {associationChoicesMemo.map(association => (
        <StyledMenuItem key={association.id} value={association.id}>
          <span style={{ marginRight: 8 }}>
            {filter.value?.includes(association.id) ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
            )}
          </span>

          {association.name}
        </StyledMenuItem>
      ))}
    </DeleteableSelectChip>
  )
}

export default PropertyFilter
