import {
  Action,
  isAllowed,
  ResourceType,
  Feature,
  Category,
  CheckType,
} from '@super-software-inc/foundation'
import { addNewCategory } from 'api/categories/index'
import {
  Divider,
  FlexColumn,
  FlexRow,
  GhostTextInput,
  IconButton,
  LoadingIndicator,
  Modal,
  MultilevelDropdown,
  MultilevelItem,
  MultilevelNoResults,
  PrimaryButton,
  TextButton,
  TruncatedText,
} from 'components/lib'
import StyledSelectContainer from 'components/lib/MaterialElements/StyledSelect'
import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { orderBy, uniqBy } from 'lodash'
import { companyTaskCategoriesAtom } from 'pages/Tasks'
import React, { useEffect, useMemo, useState } from 'react'
import {
  MdAdd,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdClose,
  MdMoreHoriz,
} from 'react-icons/md'
import { useFirestore } from 'reactfire'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTheme } from 'styled-components'
import styled from 'styled-components/macro'
import { authenticatedUserAtom } from 'state/atoms'
import SelectSearchBar from 'components/lib/MaterialElements/SelectSearchBar'
import { KeyboardArrowDown } from '@mui/icons-material'
import TagView, { TagViewPill } from './TagView'
import { windowDimensionsAtom } from '../../../AppRoutes'
import ColorSelector from './ColorSelector'

interface TagSelectorProps {
  value?: string[]
  onChange: Function
  rest?: any
  style?: any
  isDisabled?: boolean
  isTaskSheet?: boolean
}

const Container = styled.div`
  position: relative;
`

const TagSelector = ({
  value,
  onChange,
  isDisabled = false,
  isTaskSheet = true,
  ...rest
}: TagSelectorProps) => {
  const [companyTaskCategories, setCompanyTaskCategories] = useRecoilState(
    companyTaskCategoriesAtom,
  )
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const [editingCategory, setEditingCategory] = useState<Category>(
    {} as Category,
  )

  const [searchValue, setSearchValue] = useState('')
  const [manageCategoriesOpen, setManageCategoriesOpen] = useState(false)
  const [addCategoryOpen, setAddCategoryOpen] = useState(false)
  const [newCategory, setNewCategory] = useState({
    label: '',
  })
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    message: '',
  })
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const { selectedContact, selectedCompany, acts } = authenticatedUser
  const theme = useTheme()
  const firestore = useFirestore()

  const [categories, setCategories] = useState<Category[]>([])

  const canEditCategories = useMemo(
    () =>
      isAllowed(
        selectedContact,
        acts,
        selectedContact.associationIds,
        ResourceType.PROPERTY,
        Feature.CATEGORIES,
        Action.EDIT,
        CheckType.ANY,
      ),
    [selectedContact, acts],
  )

  // save the original values so the order doesn't update in real time.
  // category's that are selected are moved to the top when opening dropdown, and as you search
  const [originalValues, setOriginalValues] = useState<string[]>()
  const updateOrder = () => {
    setOriginalValues(value)
  }

  useEffect(() => {
    // update the order when searching
    updateOrder()
    // eslint-disable-next-line
  }, [searchValue])

  useEffect(() => {
    const sortCategories = function _(tag: any) {
      return [!originalValues?.includes(tag.id), tag.name.toLowerCase()]
    }
    const sortedCategories = orderBy(
      uniqBy(companyTaskCategories, 'name'),
      sortCategories,
    )
    setCategories(sortedCategories)
  }, [selectedCompany, firestore, companyTaskCategories, originalValues])

  const hasTag = (tags: string[] | undefined, tagValue: string) => {
    if (!tags || !tags.length) {
      return false
    }

    const index = tags.findIndex(t => t === tagValue)
    if (index >= 0) {
      return true
    }
    return false
  }

  const toggleValue = (tag: Category) => {
    const { id } = tag
    if (!value || !value.length) {
      onChange([id])
    } else if (hasTag(value, id)) {
      const newValue = value.filter(entry => entry !== id)
      onChange(newValue)
    } else {
      onChange([...value, id])
    }
  }

  const updateCategory = async () => {
    if (editingCategory && editingCategory.name.length < 1) {
      setErrorMessage({
        title: 'Please enter a value',
        message: 'You can’t save an empty category name.',
      })
      setEditingCategory({} as Category)
      // throw error that the name can't be updated
    } else if (editingCategory) {
      // update the category name and close the editing mode
      const catRef = doc(
        firestore,
        'companies',
        selectedCompany.id,
        'companyTaskCategories',
        editingCategory.id,
      )
      const { color, name } = editingCategory

      await updateDoc(catRef, {
        name,
        ...(color && { color }),
      })

      // update in categorty list
      setCompanyTaskCategories(
        companyTaskCategories.map(cat =>
          cat.id === editingCategory.id ? editingCategory : cat,
        ),
      )

      setEditingCategory({} as Category)
    } else {
      setEditingCategory({} as Category)
    }
  }

  const addCategory = async (label: string) => {
    const structuredLabel =
      label.trim().charAt(0).toUpperCase() + label.trim().slice(1).toLowerCase()
    // TODO - validate data before save
    if (label.length < 1) {
      setErrorMessage({
        title: 'Please enter a value',
        message: 'You can’t save an empty category name.',
      })
    } else {
      const newlyAddedCategory = await addNewCategory(
        `${selectedCompany.id}`,
        'companyTaskCategories',
        structuredLabel,
      )
      // automatically show new category as selected in dropdown
      toggleValue(newlyAddedCategory as Category)

      setCompanyTaskCategories([
        ...companyTaskCategories,
        {
          id: newlyAddedCategory.id,
          name: structuredLabel,
          color: '',
        },
      ])
    }

    //  update categories in app state
    setAddCategoryOpen(false)
    setNewCategory({
      label: '',
    })

    // reset the search field
    setSearchValue('')
  }

  const deleteCategory = async (category: Category) => {
    await deleteDoc(
      doc(
        firestore,
        'companies',
        selectedCompany.id,
        'companyTaskCategories',
        category.id,
      ),
    )
    setCompanyTaskCategories(
      companyTaskCategories.filter(cat => cat.id !== category.id),
    )
  }

  const resetNewCategory = () => {
    setNewCategory({
      label: '',
    })
  }

  if (!companyTaskCategories) {
    return <LoadingIndicator />
  }

  return (
    <Container>
      <Modal
        zIndex={10000}
        height="fit-content"
        isOpen={manageCategoriesOpen}
        onRequestClose={() => {
          setManageCategoriesOpen(false)
          resetNewCategory()
        }}
        size="sm"
        isScrollable
      >
        <FlexRow align="center" justify="space-between">
          <h2>Manage Categories</h2>
          <IconButton
            type="button"
            onClick={() => {
              setManageCategoriesOpen(false)
              resetNewCategory()
            }}
          >
            <MdClose style={{ fontSize: 16 }} />
          </IconButton>
        </FlexRow>
        <p
          style={{
            color: theme.colors.text250,
            marginTop: 0,
            marginBottom: 24,
          }}
        >
          You can edit the name of categories, or remove the ones that are no
          longer relevant.
        </p>

        <div
          style={{
            maxHeight: windowDimensions.height * 0.6,
            overflow: 'scroll',
            marginTop: 24,
            marginBottom: 10,
          }}
        >
          {categories &&
            orderBy(categories, c => c.name.toLowerCase()).map(category => (
              <span key={category.id}>
                {editingCategory?.id !== category.id && (
                  <FlexRow
                    align="center"
                    justify="space-between"
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      height: 30,
                    }}
                  >
                    <TagViewPill tags={[category as Category]} />
                    <MultilevelDropdown
                      title={
                        <IconButton as="div">
                          <MdMoreHoriz />
                        </IconButton>
                      }
                    >
                      <MultilevelItem
                        onClick={() => setEditingCategory(category as Category)}
                      >
                        <FlexRow align="center">Edit</FlexRow>
                      </MultilevelItem>
                      <MultilevelItem
                        onClick={() => deleteCategory(category as Category)}
                      >
                        <FlexRow
                          align="center"
                          style={{ color: theme.colors.destructive }}
                        >
                          Delete
                        </FlexRow>
                      </MultilevelItem>
                    </MultilevelDropdown>
                  </FlexRow>
                )}
                {editingCategory?.id === category.id && (
                  <FlexColumn
                    key={category.id}
                    align="center"
                    justify="space-between"
                    style={{
                      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
                      borderRadius: 8,
                      marginTop: 5,
                      marginBottom: 5,
                      width: '90%',
                      alignSelf: 'center',
                      position: 'absolute',
                      zIndex: 2,
                      backgroundColor: 'white',
                      top: 100,
                    }}
                  >
                    <FlexRow
                      justify="space-between"
                      align="center"
                      style={{
                        width: '100%',
                        paddingLeft: 10,
                        margin: 5,
                      }}
                    >
                      <GhostTextInput
                        style={{ marginBottom: 0, width: '90%' }}
                        value={editingCategory?.name}
                        onChange={event =>
                          setEditingCategory({
                            ...editingCategory,
                            name: event.target.value,
                          })
                        }
                      />

                      <IconButton
                        type="button"
                        onClick={() =>
                          setEditingCategory({ ...editingCategory, name: '' })
                        }
                      >
                        {editingCategory.name.length > 0 && (
                          <MdClose style={{ fontSize: 16 }} />
                        )}
                      </IconButton>
                    </FlexRow>
                    <Divider style={{ marginBottom: 0 }} />
                    <ColorSelector
                      selectedColor={editingCategory?.color || ''}
                      onClick={color =>
                        setEditingCategory({
                          ...editingCategory,
                          color,
                        })
                      }
                    />
                    <Divider style={{ marginBottom: 0 }} />
                    <PrimaryButton
                      onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        updateCategory()
                      }}
                      style={{ margin: 5 }}
                    >
                      Save
                    </PrimaryButton>
                  </FlexColumn>
                )}
              </span>
            ))}
          <div style={{ marginLeft: -8 }}>
            <MultilevelItem
              onClick={() => setAddCategoryOpen(!addCategoryOpen)}
            >
              <FlexRow align="center">
                <MdAdd style={{ marginRight: 8, fontSize: 20 }} /> Add category
              </FlexRow>
            </MultilevelItem>
          </div>
        </div>

        {addCategoryOpen && (
          <FlexColumn
            align="center"
            justify="space-between"
            style={{
              boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
              borderRadius: 8,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <FlexRow
              justify="space-between"
              align="center"
              style={{
                width: '100%',
                paddingLeft: 10,
                margin: 5,
              }}
            >
              <GhostTextInput
                style={{ marginBottom: 0, width: '90%' }}
                value={newCategory.label}
                onChange={event =>
                  setNewCategory({
                    label: event.target.value,
                  })
                }
              />
              <IconButton
                type="button"
                onClick={() =>
                  setNewCategory({
                    label: '',
                  })
                }
              >
                {newCategory.label.length > 0 && (
                  <MdClose style={{ fontSize: 16 }} />
                )}
              </IconButton>
            </FlexRow>
            <Divider style={{ marginBottom: 0 }} />
            <TextButton
              onClick={() => addCategory(newCategory.label)}
              style={{ margin: 5 }}
            >
              Save
            </TextButton>
          </FlexColumn>
        )}
        <FlexRow justify="flex-end" style={{ width: '100%' }}>
          <PrimaryButton onClick={() => setManageCategoriesOpen(false)}>
            Done
          </PrimaryButton>
        </FlexRow>
      </Modal>
      <StyledSelectContainer
        disabled={isDisabled}
        defaultValue={value}
        placeholder={isTaskSheet ? 'Categories' : 'Add category'}
        renderValue={() =>
          isTaskSheet ? (
            <TagView
              tags={companyTaskCategories.filter(tag =>
                value?.includes(tag.id),
              )}
            />
          ) : value && value.length > 0 ? (
            <FlexRow justify="space-between" align="center">
              {value && value.length > 0 ? (
                <TruncatedText
                  style={{
                    color: theme.colors.text200,
                  }}
                >
                  {value.length > 1
                    ? `${value.length} categories`
                    : companyTaskCategories
                        .filter(tag => value?.includes(tag.id))
                        .map(tag => tag.name)
                        .join(', ')}
                </TruncatedText>
              ) : (
                <span style={{ color: '#8A94A6', fontWeight: 300 }}>
                  Select categories
                </span>
              )}
            </FlexRow>
          ) : (
            <span style={{ color: '#8A94A6', fontWeight: 300 }}>
              Select categories
            </span>
          )
        }
        icon={!isTaskSheet ? KeyboardArrowDown : undefined}
        selectStyle={isTaskSheet ? 'pill' : 'rectangle'}
        fixedFooter={
          (canEditCategories || !isTaskSheet) && (
            <MultilevelItem
              key={0}
              onClick={e => {
                setManageCategoriesOpen(!manageCategoriesOpen)
              }}
            >
              <FlexRow align="center" justify="center">
                <p>Manage Categories</p>
              </FlexRow>
            </MultilevelItem>
          )
        }
      >
        <SelectSearchBar
          setSearchText={setSearchValue}
          placeholder="Add category"
        />
        {categories
          .filter(option =>
            option.name.toLowerCase().includes(searchValue.toLowerCase()),
          )
          .map(option => (
            <MultilevelItem
              key={option.id}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                toggleValue(option)
              }}
            >
              <FlexRow
                align="center"
                style={{
                  marginTop: '-4px',
                  marginBottom: '-4px',
                  width: 250,
                }}
              >
                {hasTag(value, option.id) ? (
                  <MdCheckBox
                    style={{
                      fontSize: 18,
                      marginRight: 5,
                      marginTop: 1,
                    }}
                  />
                ) : (
                  <MdCheckBoxOutlineBlank
                    style={{
                      fontSize: 18,
                      color: '#C9CED6',
                      marginRight: 5,
                      marginTop: 1,
                    }}
                  />
                )}
                <TagViewPill
                  tags={[option]}
                  style={{ color: option.color || theme.colors.text100 }}
                />
              </FlexRow>
            </MultilevelItem>
          ))}
        {companyTaskCategories.filter(option =>
          option.name.toLowerCase().includes(searchValue.toLowerCase()),
        ).length < 1 && <MultilevelNoResults />}
        {searchValue.length > 0 && (!isTaskSheet || canEditCategories) && (
          <>
            <Divider style={{ marginBottom: 10 }} />
            <MultilevelItem
              key={selectedCompany.id}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                addCategory(searchValue)
              }}
            >
              <FlexRow align="center">
                <MdAdd style={{ fontSize: 20, marginRight: 15 }} />
                Add category{' '}
                <span
                  style={{ fontWeight: 600, marginRight: 5, marginLeft: 5 }}
                >
                  {' "'}
                  {searchValue}
                  {'" '}
                </span>{' '}
              </FlexRow>
            </MultilevelItem>
          </>
        )}
      </StyledSelectContainer>
      {/* </FlexibleMultilevelDropdown> */}
      {errorMessage && (
        <Modal
          isOpen={errorMessage.title.length > 0}
          onRequestClose={() => {
            setErrorMessage({
              title: '',
              message: '',
            })
          }}
          size="sm"
          height="unset"
        >
          <h2>{errorMessage.title}</h2>
          <p style={{ marginTop: 10, marginBottom: 20 }}>
            {errorMessage.message}
          </p>
          <FlexRow justify="flex-end">
            <PrimaryButton
              onClick={() =>
                setErrorMessage({
                  title: '',
                  message: '',
                })
              }
            >
              Okay
            </PrimaryButton>
          </FlexRow>
        </Modal>
      )}
    </Container>
  )
}

export default TagSelector
