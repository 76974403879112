import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  SyntheticEvent,
  ReactNode,
  ReactElement,
  RefObject,
  CSSProperties,
} from 'react'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import LoadingIndicator from '../LoadingIndicator'

export enum Positions {
  Left,
  Right,
  TopRight,
  TopLeft,
}

interface MultilevelDropdownButtonProps {
  inheritStyles?: boolean
}

const MultilevelDropdownContainer = styled.div`
  position: relative;
  overflow: visible;
`

const MultilevelDropdownFixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  padding-top: 10px;
  background-color: white;
`

export const MultilevelDropdownFixedFooter = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 10;
  padding-bottom: 10px;
  background-color: white;
`

export const MultilevelDropdownButton = styled.button<MultilevelDropdownButtonProps>`
  cursor: pointer;
  color: ${props =>
    props.inheritStyles ? 'inherit' : props.theme.colors.text200};
  font-size: 14px;
  font-weight: ${props => (props.inheritStyles ? 'inherit' : 500)};
  height: ${props => (props.inheritStyles ? 'auto' : '41px')};
  background: transparent;
  border-width: 0;
  white-space: nowrap;
  padding: 0;
  width: 100%;
  text-align: left;

  i,
  svg {
    display: block;
    transform: scale(1.2);
  }

  &:hover {
    text-decoration: none;
    color: ${props =>
      props.inheritStyles ? 'inherit' : props.theme.colors.text0};
  }

  &:disabled {
    color: ${props => props.theme.colors.text300};
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }
  }
`

export const MultilevelDropdownField = styled.button<{
  removeBorder?: boolean
}>`
  border: ${props =>
    props.removeBorder ? 'none' : `1px solid ${props.theme.colors.bg300}`};
  padding: 8px;
  font-size: 0.875rem;
  height: 40px;
  border-radius: ${props => props.theme.rounding.medium};
  width: 100%;
  background-color: ${props => props.theme.colors.bg0};
  color: ${props => props.theme.colors.text0};
  text-align: left;

  &:focus {
    border: ${props =>
      props.removeBorder ? 'none' : `1px solid ${props.theme.colors.primary}`};
    box-shadow: ${props =>
      props.removeBorder
        ? 'none'
        : `0 0 2px 2px
      ${transparentize(0.5, props.theme.colors.primary)};`};
    outline: none;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.bg300};
  }

  transition: border ${props => props.theme.transitions.short} ease,
    background-color ${props => props.theme.transitions.short} ease,
    box-shadow ${props => props.theme.transitions.short} ease,
    opacity ${props => props.theme.transitions.short} ease,
    color ${props => props.theme.transitions.short} ease;
`

const MultilevelDropdownMenu = styled.div<{
  position: Positions
  maxHeight?: string | number
}>`
  position: absolute;
  z-index: ${props => props.theme.zIndex.dropdown};
  flex-direction: column;
  min-width: 150px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.bg100};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: ${props => props.maxHeight || 'initial'};
  ${props => props.maxHeight && `overflow-y: auto`};

  top: ${({ position }) => {
    if (position === Positions.Left || position === Positions.Right) {
      return '100%'
    }

    if (position === Positions.TopRight || position === Positions.TopLeft) {
      return 'auto'
    }

    return undefined
  }};
  bottom: ${({ position }) => {
    if (position === Positions.TopRight || position === Positions.TopLeft) {
      return '100%'
    }

    return undefined
  }};
  left: ${({ position }) => {
    if (position === Positions.Right || position === Positions.TopRight) {
      return '0'
    }

    if (position === Positions.TopLeft) {
      return 'auto'
    }

    return undefined
  }};
  right: ${({ position }) => {
    if (position === Positions.Left || position === Positions.TopLeft) {
      return '0'
    }

    if (position === Positions.Right || position === Positions.TopRight) {
      return 'auto'
    }

    return undefined
  }};
`

const MultilevelDropdownUnorderedList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 8px 0;
`

export type MultilevelDropdownHandle = {
  dropdownRef: RefObject<HTMLDivElement>
  toggle: Function
  isOpen: boolean
}

interface MultilevelDropdownProps {
  title?: ReactNode | ReactNode[]
  children?: ReactNode | ReactNode[]
  trigger?: ReactElement
  isDisabled?: boolean
  position?: Positions
  onClick?: Function
  openOnHover?: boolean
  closeOnClick?: boolean
  inheritStyles?: boolean
  maxHeight?: string | number
  fixedHeader?: ReactElement // optional fixed element at top of the dropdown list
  fixedFooter?: ReactElement // optional fixed element at bottom of the dropdown list
  listStyles?: CSSProperties
  afterClose?: Function
  loading?: boolean
}

const MultilevelDropdown = forwardRef<
  MultilevelDropdownHandle,
  MultilevelDropdownProps
>(
  (
    {
      title,
      children,
      isDisabled = false,
      position = Positions.Left,
      onClick = () => null,
      trigger = <MultilevelDropdownButton />,
      openOnHover = false,
      closeOnClick = true,
      fixedHeader,
      fixedFooter,
      listStyles,
      afterClose,
      loading = false,
      ...props
    }: MultilevelDropdownProps,
    ref,
  ) => {
    const [isOpen, setOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const handleClick = useCallback(evt => {
      if (!dropdownRef?.current?.contains(evt.target)) {
        setOpen(false)
        document.removeEventListener('mousedown', handleClick)
      }
    }, [])

    useEffect(
      () => () => document.removeEventListener('mousedown', handleClick),
      [handleClick],
    )

    useEffect(() => {
      if (!isOpen && !!afterClose) {
        afterClose()
      }
      // eslint-disable-next-line
    }, [isOpen])

    const handleButtonOnClick = (evt: SyntheticEvent) => {
      if (evt) {
        evt.preventDefault()
        evt.stopPropagation()
      }

      if (isDisabled) {
        return
      }

      onClick(evt)

      setOpen(!isOpen)

      if (isOpen) {
        document.removeEventListener('mousedown', handleClick)
      } else {
        document.addEventListener('mousedown', handleClick)
      }
    }

    useImperativeHandle(ref, () => ({
      dropdownRef,
      toggle: handleButtonOnClick,
      isOpen,
    }))

    const Trigger = React.cloneElement(trigger, {
      type: 'button',
      disabled: isDisabled,
      tabIndex: 0,
      onClick: !openOnHover ? handleButtonOnClick : undefined,
      onMouseOver: openOnHover ? handleButtonOnClick : undefined,
      children: title,
      ...props,
    })

    return (
      <MultilevelDropdownContainer ref={dropdownRef} className="flex">
        {Trigger}
        {isOpen && (
          <MultilevelDropdownMenu
            position={position}
            onClick={() => closeOnClick && setOpen(false)}
            maxHeight={props.maxHeight}
            className={loading ? `pointer-events-none` : ''}
          >
            <div className="relative">
              {loading && (
                <div
                  className={`
                    flex justify-center items-center
                    absolute top-0
                    w-full h-[260px]
                    z-[10000]
                    bg-[rgba(0,0,0,0.04)]
                    select-none
                  `}
                >
                  <LoadingIndicator />
                </div>
              )}
              {fixedHeader && (
                <MultilevelDropdownFixedHeader>
                  {fixedHeader}
                </MultilevelDropdownFixedHeader>
              )}
              <MultilevelDropdownUnorderedList style={listStyles}>
                {children}
              </MultilevelDropdownUnorderedList>
              {fixedFooter && (
                <MultilevelDropdownFixedFooter>
                  {fixedFooter}
                </MultilevelDropdownFixedFooter>
              )}
            </div>
          </MultilevelDropdownMenu>
        )}
      </MultilevelDropdownContainer>
    )
  },
)

export default MultilevelDropdown
