import {
  ContactGroup,
  ContactSecrets,
  SearchableContact,
} from '@super-software-inc/foundation'
import { getAllContactsFromTypesense } from '.'

// TODO: temporary fix until we figure out why some contacts returned from Typesense are missing fields. Contacts with no boardTerms should have an empty array
// TODO: and contacts with no propertyRelation should have null. These exist in Typesense (as well as the SearchableContact record in Firebase) but for some
// TODO: reason are not being returned in the Typesense response. This function ensures that these fields are present.
export const ensureTypesenseContactsHaveMissingFields = (
  contacts: SearchableContact[],
) =>
  contacts.map(contact => ({
    ...contact,
    propertyInfo:
      contact.propertyInfo?.map(property => ({
        ...property,
        boardTerms: property.boardTerms || [],
        propertyRelation: property.propertyRelation || null,
      })) || [],
  }))

// eslint-disable-next-line import/prefer-default-export
export const getContacts = async (
  contactSecrets: ContactSecrets | undefined,
  companyId: string,
  associationIds: string[],
  groups?: ContactGroup[],
) => {
  const fieldsToInclude = [
    'id',
    'firstName',
    'lastName',
    'email',
    'phone',
    'photoURL',
    'associationIds',
    'propertyInfo',
    'companyId',
    'companyName',
  ]

  const fieldsToExclude = [
    'propertyInfo.acl', // don't need this atm
  ]

  // Using Typesense instead:
  const contacts = await getAllContactsFromTypesense(
    {
      companyId,
      ...(associationIds?.length && { associationIds }), // only pass associationIds if it's not empty
      groups,
      includeFields: fieldsToInclude,
      excludeFields: fieldsToExclude,
    },
    contactSecrets,
  )

  // TODO: Temporarily transform the response to include some fields that are missing from the Typesense response:
  return ensureTypesenseContactsHaveMissingFields(contacts)

  /*
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getAssociationContacts: HttpsCallable<
    { companyId: string; associationIds: string[]; groups?: ContactGroup[] },
    APIContact[]
  > = httpsCallable(functions, 'getContacts')

  const { data } = await getAssociationContacts({
    companyId,
    associationIds,
    groups,
  })

  return data
  */
}
