import {
  Action,
  APIUnit,
  Feature,
  isAllowed,
  LocationReference,
  ResourceType,
} from '@super-software-inc/foundation'
import { getUnitsByAssociationId } from 'api/units'
import { FlexRow, MultilevelItem, TruncatedText } from 'components/lib'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import StyledSelectContainer from 'components/lib/MaterialElements/StyledSelect'
import DropdownTextInput from 'components/lib/MultilevelDropdown/DropdownTextInput'
import React, { useEffect, useState } from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, companyUnitsAtom } from 'state/atoms'
import SelectSearchBar from 'components/lib/MaterialElements/SelectSearchBar'

const LocationSelector = ({
  associationIds,
  locations,
  onChange,
  isDisabled = false,
}: {
  associationIds: (string | null)[]
  locations: LocationReference[]
  onChange: Function
  isDisabled?: boolean
}) => {
  const [units, setUnits] = useState<APIUnit[]>([])
  const [unitSearchValue, setUnitSearchValue] = useState<string>('')
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [companyUnits, setCompanyUnits] = useRecoilState(companyUnitsAtom)

  // used in the task form. If the associations change, update reset location field.
  // If only 1 assocaitionId, get the new association's units

  useEffect(() => {
    let ignore = false
    if (
      associationIds.length === 1 &&
      !isDisabled &&
      associationIds[0] !== null
    ) {
      const canViewUnits = isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        [associationIds[0]],
        ResourceType.PROPERTY,
        Feature.UNITS,
        Action.VIEW,
      )

      if (canViewUnits) {
        if (companyUnits.has(associationIds[0] as string)) {
          setUnits(companyUnits.get(associationIds[0] as string) as APIUnit[])
        } else {
          getUnitsByAssociationId(associationIds[0]).then(apiUnits => {
            if (!ignore) {
              setUnits(apiUnits)
              const newCompanyUnitsMap = companyUnits
              newCompanyUnitsMap.set(associationIds[0] as string, apiUnits)
              setCompanyUnits(newCompanyUnitsMap)
            }
          })
        }
      }
    } else if (units.length > 0) {
      setUnits([])
      onChange([])
    }
    return () => {
      ignore = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associationIds[0]])

  // toggling unit options
  const toggleOption = (unit: APIUnit) => {
    if (locations.find(location => location.unitId === unit.id)) {
      onChange(locations.filter(location => location.unitId !== unit.id))
    } else {
      onChange([...locations, { name: unit.name, unitId: unit.id }])
    }
  }

  // toggling written-in location options
  const toggleInputOption = (location: string, addOption: boolean) => {
    if (addOption) {
      onChange([...locations, { name: location }])
    } else {
      const newLocations = locations.filter(l => l.name !== location)
      onChange(newLocations)
    }
    setUnitSearchValue('')
  }

  return (
    <StyledSelectContainer
      selectStyle="pill"
      disabled={isDisabled}
      defaultValue={units}
      renderValue={() => (
        <TruncatedText>
          <FlexRow align="center" justify="center">
            <PinDropOutlinedIcon />
            {locations.length > 0
              ? locations.map(l => l.name).join(', ')
              : 'Location'}
          </FlexRow>
        </TruncatedText>
      )}
    >
      {(units.length !== 0 || (units.length === 0 && locations.length > 0)) && (
        <SelectSearchBar
          placeholder="Search units"
          setSearchText={setUnitSearchValue}
        />
      )}
      {locations
        .filter(
          location =>
            !location.unitId &&
            location.name.toLowerCase().includes(unitSearchValue.toLowerCase()),
        )
        .map(location => (
          <MultilevelItem
            key={location.name}
            onClick={() => toggleInputOption(location.name, false)}
          >
            <FlexRow align="center">
              {locations.includes(location) ? (
                <MdCheckBox style={{ marginRight: 10, fontSize: 18 }} />
              ) : (
                <MdCheckBoxOutlineBlank
                  style={{
                    fontSize: 18,
                    color: '#C9CED6',
                    marginRight: 5,
                  }}
                />
              )}
              {location.name}
            </FlexRow>
          </MultilevelItem>
        ))}
      {units
        .filter(unit =>
          unit.name.toLowerCase().includes(unitSearchValue.toLowerCase()),
        )
        .map(unit => (
          <MultilevelItem key={unit.id} onClick={() => toggleOption(unit)}>
            <FlexRow align="center">
              {locations.find(l => l.unitId === unit.id) !== undefined ? (
                <MdCheckBox style={{ marginRight: 5, fontSize: 18 }} />
              ) : (
                <MdCheckBoxOutlineBlank
                  style={{
                    fontSize: 18,
                    color: '#C9CED6',
                    marginRight: 5,
                  }}
                />
              )}

              {unit.name}
            </FlexRow>
          </MultilevelItem>
        ))}
      {units.length === 0 && locations.length === 0 && (
        <DropdownTextInput
          value={unitSearchValue}
          onChange={setUnitSearchValue}
          placeholder="Add location"
        />
      )}
      {unitSearchValue.length > 0 && (
        <MultilevelItem
          key={0}
          onClick={() => toggleInputOption(unitSearchValue, true)}
        >
          Use <span className="font-bold">&quot;{unitSearchValue}&quot;</span>
        </MultilevelItem>
      )}
    </StyledSelectContainer>
  )
}
export default LocationSelector
