import {
  AccessLevel,
  APIAssociation,
  APIContact,
  PropertyInfo,
  PropertyRelation,
} from '@super-software-inc/foundation'
import {
  FlexRow,
  MultilevelHeader,
  MultilevelItem,
  MultilevelNoResults,
  PageTitle,
  TruncatedText,
} from 'components/lib'
import { findIndex, isMatch, orderBy } from 'lodash'
import {
  selectedAnnouncementAtom,
  showAnnouncementDrawerAtom,
} from 'pages/Announcements'
import React, { useMemo, useState } from 'react'
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdOutlineIndeterminateCheckBox,
} from 'react-icons/md'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import { useNavigate, useLocation } from 'react-router'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { useTheme } from 'styled-components'
import { ExpandMore } from '@mui/icons-material'
import {
  associationChoicesAtom,
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
  windowDimensionsAtom,
} from '../../../AppRoutes'

// FIXME: temporary solution to handle super admin access; rm after ACL is
// updated & contacts migrated
export const isSuperAdmin = (contact: APIContact) => {
  const domainName = contact.email?.split('@')[1]

  return domainName === 'hiresuper.com'
}

export const noPropertySelectedAtom = atom<boolean>({
  key: 'noPropertySelected',
  default: false,
})

/**
 * A dropdown component that allows the switching of association choices in page headers.
 */
const PageTitleAssociationDropdown = ({
  pageTitle,
  singleProp = true,
}: {
  pageTitle?: string
  singleProp?: boolean
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showAnnouncementDrawer, setShowAnnouncementDrawer] = useRecoilState(
    showAnnouncementDrawerAtom,
  )
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const [, setSelectedAnnouncement] = useRecoilState(selectedAnnouncementAtom)
  const [noPropertySelected, setNoPropertySelected] = useRecoilState(
    noPropertySelectedAtom,
  )

  const [selectedAssociations, setSelectedAssociations] = useRecoilState(
    selectedAssociationChoicesAtom,
  )

  const associationChoices = useRecoilValue(associationChoicesAtom)

  const [associationSearchText, setAssociationSearchText] = useState('')
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const associationsMemo = useMemo(() => {
    const sortAssociations = function _(a: any) {
      return [
        findIndex(selectedAssociations, o => isMatch(o, { id: a.id })) < 0,
        a.name,
      ]
    }
    const associations = orderBy(
      associationChoices,
      [sortAssociations],
      ['asc', 'asc'],
    ).filter(association =>
      association.name
        .toLowerCase()
        .includes(associationSearchText.toLowerCase()),
    )
    return associations
    // eslint-disable-next-line
  }, [associationSearchText, associationChoices])

  const theme = useTheme()

  const canViewNoProperty = useMemo(() => {
    const isStaffOrManage =
      !singleProp &&
      authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) =>
          p.accessLevel !== AccessLevel.NoAccess &&
          p.propertyRelation &&
          [PropertyRelation.Staff, PropertyRelation.PropertyManager].includes(
            p.propertyRelation,
          ),
      ) !== undefined
    return isStaffOrManage
  }, [authenticatedUser.selectedContact, singleProp])

  const toggleAssociation = (association: APIAssociation) => {
    const isDeselect =
      selectedAssociations.map(x => x.id).indexOf(association.id) !== -1
    if (isDeselect) {
      if (selectedAssociations.length === 1) {
        setSelectedAssociations([])
      } else {
        setSelectedAssociations(
          selectedAssociations.filter(a => a.id !== association.id),
        )
      }
    } else {
      setSelectedAssociations(
        singleProp ? [association] : [...selectedAssociations, association],
      )
    }
  }

  return (
    <FlexRow>
      {pageTitle && (
        <PageTitle
          style={{ color: '#627088', fontWeight: '500', marginTop: '.25rem' }}
        >
          {pageTitle}{' '}
          {windowDimensions.isIframe || windowDimensions.isMobile
            ? `${' '}`
            : `${' / '}`}
        </PageTitle>
      )}
      {/* // #1e1p v2 - if a corpFirst company only has 1 property, 
      they can only access No property from the dropdown.
      */}
      {associationChoices.length === 1 &&
      !authenticatedUser.selectedCompany.corpFirst ? (
        <FlexRow
          align="center"
          justify="space-between"
          style={{
            border: `1px solid ${theme.colors.border}`,
            padding: 10,
          }}
        >
          <PageTitle>{associationChoices[0].name}</PageTitle>
        </FlexRow>
      ) : (
        <StyledSelect
          defaultValue={associationChoices[0]}
          renderValue={() => (
            <PageTitle>
              <FlexRow justify="center">
                <FlexRow
                  style={{
                    width:
                      windowDimensions.isIframe || windowDimensions.isMobile
                        ? 60
                        : 'auto',
                  }}
                >
                  {noPropertySelected || selectedAssociations.length > 1 ? (
                    <TruncatedText>
                      {selectedAssociations.length ===
                        associationChoices.length && noPropertySelected
                        ? 'All accounts'
                        : selectedAssociations.length === 0 &&
                          noPropertySelected
                        ? `${authenticatedUser.selectedCompany.name}`
                        : !associationChoices.length && !noPropertySelected
                        ? 'No accounts'
                        : `${
                            selectedAssociations.length +
                            (canViewNoProperty && noPropertySelected ? 1 : 0)
                          }  accounts`}
                    </TruncatedText>
                  ) : (
                    <TruncatedText
                      style={{
                        marginLeft: 5,
                      }}
                    >
                      {primaryAssociation?.name || 'Select a property'}
                    </TruncatedText>
                  )}
                </FlexRow>
                <ExpandMore />
              </FlexRow>
            </PageTitle>
          )}
        >
          <MultilevelHeader
            onChange={setAssociationSearchText}
            isDisabled={false}
            value={associationSearchText}
            placeholder="Search properties"
            clearValue={() => setAssociationSearchText('')}
          />
          {!singleProp && associationSearchText === '' && (
            <MultilevelItem
              key="all-associations"
              onClick={() => {
                if (selectedAssociations.length === associationChoices.length) {
                  setNoPropertySelected(false)
                  setSelectedAssociations([])
                } else {
                  setNoPropertySelected(true)
                  setSelectedAssociations(associationChoices)
                }

                // TODO #filters - remove this?
                if (location.pathname.includes('calendar')) {
                  navigate(`/tasks/calendar${window.location.search}`)
                } else if (location.pathname.includes('tasks')) {
                  navigate(`/tasks${window.location.search}`)
                } else if (showAnnouncementDrawer) {
                  setShowAnnouncementDrawer(false)
                  navigate(`/announcements`)
                }
              }}
            >
              <FlexRow style={{ cursor: 'pointer' }}>
                {noPropertySelected &&
                selectedAssociations.length === associationChoices.length ? (
                  <MdCheckBox
                    style={{ fontSize: 18, marginRight: 15, marginTop: 2 }}
                  />
                ) : !selectedAssociations.length && !noPropertySelected ? (
                  <MdCheckBoxOutlineBlank
                    style={{
                      fontSize: 18,
                      color: '#C9CED6',
                      marginRight: 15,
                      marginTop: 2,
                    }}
                  />
                ) : (
                  <MdOutlineIndeterminateCheckBox
                    style={{ fontSize: 18, marginRight: 15, marginTop: 2 }}
                  />
                )}
                Select/Deselect All ({associationChoices.length + 1})
              </FlexRow>
            </MultilevelItem>
          )}

          {associationsMemo.map(association => (
            <MultilevelItem key={association.id}>
              <FlexRow style={{ height: 16, cursor: 'pointer' }}>
                <span onClick={() => toggleAssociation(association)}>
                  {selectedAssociations
                    .map(x => x.id)
                    .indexOf(association.id) !== -1 ? (
                    <MdCheckBox
                      style={{ fontSize: 18, marginRight: 5, marginTop: -1 }}
                      className="checkbox-hover"
                    />
                  ) : (
                    <MdCheckBoxOutlineBlank
                      style={{
                        fontSize: 18,
                        color: '#C9CED6',
                        marginRight: 5,
                        marginTop: -1,
                      }}
                      className="checkbox-hover"
                    />
                  )}
                </span>
                <span
                  style={{ marginLeft: 10, marginTop: -2 }}
                  onClick={event => {
                    event.stopPropagation()
                    setNoPropertySelected(false)

                    if (location.pathname.includes('announcements')) {
                      setShowAnnouncementDrawer(false)
                      setSelectedAnnouncement(undefined)
                    }
                    setSelectedAssociations([association])
                  }}
                >
                  {association.name}
                </span>
              </FlexRow>
            </MultilevelItem>
          ))}
          {associationsMemo.length < 1 && <MultilevelNoResults />}
        </StyledSelect>
      )}
    </FlexRow>
  )
}

export default PageTitleAssociationDropdown
