import * as Sentry from '@sentry/react'
import {
  Category,
  TaskStatus,
  SearchableTask,
} from '@super-software-inc/foundation'
import { composerValueAtom } from 'components/app/Composer/Composer'
import TaskSheet from 'components/app/TaskSheet'
import { Drawer, FlexRow, PageTitle } from 'components/lib'
import Loading from 'pages/Secondary/Loading'
import React, { useEffect, useState } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import {
  authenticatedUserAtom,
  showTaskFormAtom,
  taskInitialValuesAtom,
} from 'state/atoms'
import styled from 'styled-components/macro'
import GlobalSearch from 'components/app/GlobalSearch'
import AddCommunicationMenu from '../../components/app/AddCommunicationMenu'
import CalendarView from './CalendarView'
import VirtualizedTaskListView from './VirtualizedTaskListView'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const selectedTaskAtom = atom<SearchableTask | undefined>({
  key: 'selectedTask',
  default: undefined,
})

export const companyTaskCategoriesAtom = atom<Category[]>({
  key: 'companyTaskCategories',
  default: [],
})

export const PageHeader = styled.div`
  height: 60px;
  background-color: ${props => props.theme.colors.bg0};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: ${props => props.theme.zIndex.stickyHeader};
`

const TaskDrawer = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { taskId } = useParams()
  const navigate = useNavigate()
  const setSelectedTask = useSetRecoilState(selectedTaskAtom)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  if (!taskId) {
    return null
  }

  return (
    <Drawer isOpen={isOpen}>
      <TaskSheet
        key={taskId} // This is necessary to re-render the TaskSheet when the taskId changes
        taskId={taskId}
        onRequestClose={() => {
          // Get rid of the selected task when closing the sheet:
          setSelectedTask(undefined)

          // get the current location
          const currentLocation = window.location.pathname
          const currentLocationParts = currentLocation.split('/')
          const newLocationParts = currentLocationParts.slice(0, -1)
          const newLocation = newLocationParts.join('/')
          navigate(`${newLocation}${window.location.search}`, {
            relative: 'path',
          })
        }}
      />
    </Drawer>
  )
}

const TasksPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const workspace = searchParams.get('workspace')

  const [, onTaskSelected] = useRecoilState(selectedTaskAtom)
  const [, setShowTaskForm] = useRecoilState(showTaskFormAtom)
  const [, setTaskInitialValues] = useRecoilState(taskInitialValuesAtom)
  const resetComposerValue = useResetRecoilState(composerValueAtom)
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const companySecret = authenticatedUser.secrets?.find(
    secret => secret.companyId === selectedCompany.id,
  )

  const onRequestSheetClose = (e?: React.MouseEvent<HTMLDivElement>) => {
    resetComposerValue()

    const element = e ? (e.target as HTMLDivElement) : null
    const firstChildElement = element?.firstChild
      ? (element.firstChild as HTMLElement)
      : null
    const parentElement = element?.parentElement
      ? (element.parentElement as HTMLElement)
      : null

    // If a skip attribute is on an element itself, its first child, or its parent:
    if (
      element?.attributes.getNamedItem('data-prevent-request-sheet-close')
        ?.value ||
      firstChildElement?.attributes?.getNamedItem(
        'data-prevent-request-sheet-close',
      )?.value ||
      parentElement?.attributes?.getNamedItem(
        'data-prevent-request-sheet-close',
      )?.value
    ) {
      return
    }

    // When closing the sheet from the calendar..
    if (!location.pathname.includes('/calendar')) {
      navigate(`/tasks${workspace ? `?workspace=${workspace}` : ''}`)
    }
  }

  return (
    <>
      <PageHeader>
        <PageTitle>
          {workspace
            ? workspace === 'null'
              ? 'General'
              : workspace === 'compliance'
              ? 'Compliance'
              : 'Work orders'
            : 'Tasks'}
        </PageTitle>
        <GlobalSearch
          searchApiKey={companySecret?.typesense.scopedSearchKey || ''}
        />
        <FlexRow align="center">
          <AddCommunicationMenu />
        </FlexRow>
      </PageHeader>

      <SentryRoutes>
        <Route
          path="/"
          element={
            <VirtualizedTaskListView
              onRequestSheetClose={onRequestSheetClose}
            />
          }
        />
        <Route
          path="/:taskId"
          element={
            <>
              <VirtualizedTaskListView
                onRequestSheetClose={onRequestSheetClose}
              />
              <TaskDrawer />
            </>
          }
        />
        <Route
          path="calendar"
          element={
            <CalendarView
              onTaskSelected={task => onTaskSelected(task)}
              onRequestSheetClose={onRequestSheetClose}
              onDateClicked={(dateString: string) => {
                setTaskInitialValues({
                  title: '',
                  description: '',
                  status: TaskStatus.OPEN,
                  dueDate: dateString,
                })
                setShowTaskForm(true)
              }}
            />
          }
        />
        <Route
          path="calendar/:taskId"
          element={
            <>
              <CalendarView
                onTaskSelected={task => onTaskSelected(task)}
                onRequestSheetClose={onRequestSheetClose}
                onDateClicked={(dateString: string) => {
                  setTaskInitialValues({
                    title: '',
                    description: '',
                    status: TaskStatus.OPEN,
                    dueDate: dateString,
                  })
                  setShowTaskForm(true)
                }}
              />
              <TaskDrawer />
            </>
          }
        />
      </SentryRoutes>
    </>
  )
}

const TasksPageWrapper = () => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  if (!authenticatedUser.contacts.length) {
    return <Loading />
  }

  return <TasksPage />
}

export default TasksPageWrapper
