import {
  APIAssociation,
  PropertyInfo,
  PropertyRelation,
} from '@super-software-inc/foundation'
import {
  Divider,
  FlexRow,
  MultilevelItem,
  RadioButton,
  TruncatedText,
} from 'components/lib'
import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  companyAssociationsAtom,
  getDefaultPropertyInfo,
  profileModalAtom,
} from 'state/atoms'
import { updateContact } from 'api/contacts'
import { toastSuccess } from 'components/lib/Toast'
import toSentenceCase from 'utils/toSentenceCase'
import _ from 'lodash'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import BoardSection from '../Board/BoardSection'
import ProfileUnitsSection from '../Units/ProfileUnitsSection'
import AccessDropdown from '../AccessDropdown'
import UnitModal from '../Units/UnitModal'
import BoardModal from '../Board/BoardModal'

const PropertiesSection = () => {
  const companyAssociations = useRecoilValue(companyAssociationsAtom)
  const [closedSections, setClosedSections] = React.useState<string[]>([])
  const companyAssociationsMap = new Map(
    companyAssociations.associations.map((association: APIAssociation) => [
      association.id,
      association.name,
    ]),
  )
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const contact = profileModal.selectedContact
  const [unitModalIsOpen, setUnitModalIsOpen] = useState('')
  const [boardModalIsOpen, setBoardModalIsOpen] = useState('')

  const updatePropertyRelation = async (
    propertyRelation: PropertyRelation,
    associationId: string,
  ) => {
    const updatedContact = {
      ...contact,
      propertyInfo: contact.propertyInfo.map(p =>
        p.associationId === associationId ? { ...p, propertyRelation } : p,
      ),
    }
    await updateContact(updatedContact)
    toastSuccess('Property relation updated')
    setProfileModal({
      ...profileModal,
      selectedContact: updatedContact,
    })
  }

  const addPropertyToContact = async (association: APIAssociation) => {
    const updatedContact = {
      ...contact,
      associationIds: [...contact.associationIds, association.id],
      propertyInfo: [
        ...contact.propertyInfo,
        {
          ...getDefaultPropertyInfo(),
          associationId: association.id,
          units: [],
          boardTerms: [],
        },
      ],
    }
    await updateContact(updatedContact)
    toastSuccess(`${association.name} added to contact`)
    setProfileModal({
      ...profileModal,
      selectedContact: updatedContact,
    })
  }

  return (
    <>
      <FlexRow align="center" justify="space-between">
        <div className="text-slate-500 text-xs font-semibold uppercase leading-[18px] tracking-wide">
          PROPERTIES ({contact.propertyInfo.length})
        </div>
        <StyledSelect
          selectStyle="textButton"
          value={undefined}
          renderValue={() => (
            <p
              style={{
                color: '#627088',
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              Add
            </p>
          )}
          defaultValue={undefined}
        >
          {companyAssociations.associations
            .filter(a => !contact.associationIds.includes(a.id))
            .map((association: APIAssociation) => (
              <MultilevelItem
                key={association.id}
                onClick={() => addPropertyToContact(association)}
              >
                <FlexRow style={{ maxWidth: 150 }}>
                  <TruncatedText>{association.name}</TruncatedText>
                </FlexRow>
              </MultilevelItem>
            ))}
        </StyledSelect>
      </FlexRow>
      {contact.propertyInfo.map((propertyInfo: PropertyInfo) => (
        <div key={propertyInfo.associationId} className="leading-5 mb-5 mt-5">
          <FlexRow className="mb-1" justify="space-between" align="center">
            <FlexRow className="mb-1" justify="flex-start" align="center">
              <span
                onClick={() => {
                  if (closedSections.includes(propertyInfo.associationId)) {
                    setClosedSections(
                      closedSections.filter(
                        section => section !== propertyInfo.associationId,
                      ),
                    )
                  } else {
                    setClosedSections([
                      ...closedSections,
                      propertyInfo.associationId,
                    ])
                  }
                }}
                className="material-symbols-rounded mr-2"
                style={{ color: '#627088', cursor: 'pointer' }}
              >
                {closedSections.includes(propertyInfo.associationId)
                  ? 'keyboard_arrow_down'
                  : 'keyboard_arrow_up'}
              </span>
              <FlexRow
                className="font-medium capitalize"
                style={{ maxWidth: 190 }}
              >
                <TruncatedText>
                  {companyAssociationsMap.get(propertyInfo.associationId) ||
                    'Unknown'}
                </TruncatedText>
              </FlexRow>
            </FlexRow>
            <StyledSelect
              selectStyle="textButton"
              defaultValue={undefined}
              renderValue={() => (
                <p
                  style={{
                    color: '#627088',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                >
                  Add
                </p>
              )}
            >
              <MultilevelItem
                onClick={() => {
                  setUnitModalIsOpen(propertyInfo.associationId)
                }}
              >
                <FlexRow style={{ width: 170 }}>
                  <TruncatedText>Move in to unit</TruncatedText>
                </FlexRow>
              </MultilevelItem>
              <MultilevelItem
                onClick={() => {
                  setBoardModalIsOpen(propertyInfo.associationId)
                }}
              >
                <FlexRow>
                  <TruncatedText>Add to board</TruncatedText>
                </FlexRow>
              </MultilevelItem>
              <FlexRow style={{ marginLeft: 10, marginTop: 5 }}>
                <Divider />
              </FlexRow>
              <p
                style={{
                  marginTop: -20,
                  marginBottom: 5,
                  marginLeft: 10,
                  color: '#627088',
                }}
              >
                Property relationship
              </p>

              <MultilevelItem
                onClick={() => {
                  updatePropertyRelation(
                    PropertyRelation.Sponsor,
                    propertyInfo.associationId,
                  )
                }}
              >
                <FlexRow align="center">
                  <RadioButton
                    value={PropertyRelation.Sponsor}
                    comparator={
                      propertyInfo.propertyRelation as PropertyRelation
                    }
                    label=""
                  />
                  <p>Sponsor</p>
                </FlexRow>
              </MultilevelItem>
              <MultilevelItem
                onClick={() => {
                  updatePropertyRelation(
                    PropertyRelation.PropertyManager,
                    propertyInfo.associationId,
                  )
                }}
              >
                <FlexRow align="center">
                  <RadioButton
                    value={PropertyRelation.PropertyManager}
                    comparator={
                      propertyInfo.propertyRelation as PropertyRelation
                    }
                    label=""
                  />
                  <p>Property Manager</p>
                </FlexRow>
              </MultilevelItem>
              <MultilevelItem
                onClick={() => {
                  updatePropertyRelation(
                    PropertyRelation.Staff,
                    propertyInfo.associationId,
                  )
                }}
              >
                <FlexRow align="center">
                  <RadioButton
                    value={PropertyRelation.Staff}
                    comparator={
                      propertyInfo.propertyRelation as PropertyRelation
                    }
                    label=""
                  />
                  <p>Staff</p>
                </FlexRow>
              </MultilevelItem>
              <MultilevelItem
                onClick={() => {
                  updatePropertyRelation(
                    PropertyRelation.Vendor,
                    propertyInfo.associationId,
                  )
                }}
              >
                <FlexRow align="center">
                  <RadioButton
                    value={PropertyRelation.Vendor}
                    comparator={
                      propertyInfo.propertyRelation as PropertyRelation
                    }
                    label=""
                  />
                  <p>Vendor</p>
                </FlexRow>
              </MultilevelItem>
            </StyledSelect>
          </FlexRow>
          {unitModalIsOpen === propertyInfo.associationId && (
            <UnitModal
              contact={contact}
              propertyInfo={propertyInfo}
              sortedContactUnits={_.sortBy(propertyInfo.units, [
                'endDate',
                'startDate',
              ]).reverse()}
              unitIndex={undefined}
              onClose={() => setUnitModalIsOpen('')}
            />
          )}
          {boardModalIsOpen && (
            <BoardModal
              contact={contact}
              propertyInfo={propertyInfo}
              onClose={() => {
                setBoardModalIsOpen('')
              }}
              boardTermIndex={undefined}
            />
          )}
          {!closedSections.includes(propertyInfo.associationId) && (
            <div style={{ marginLeft: 30 }}>
              <AccessDropdown
                contact={contact}
                isSidebar={false}
                isCorpSidebar
                associationId={propertyInfo.associationId}
              />
              {propertyInfo.propertyRelation && (
                <p style={{ marginTop: 10, marginBottom: 10, fontWeight: 500 }}>
                  {propertyInfo.propertyRelation ===
                  PropertyRelation.PropertyManager
                    ? 'Property Manager'
                    : toSentenceCase(propertyInfo.propertyRelation)}
                </p>
              )}
              <ProfileUnitsSection
                contact={contact}
                canEditContacts={false}
                corpFirst
                associationId={propertyInfo.associationId}
              />
              <BoardSection
                contact={contact}
                canEditContacts={false}
                corpFirst
                associationId={propertyInfo.associationId}
              />
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default PropertiesSection
