import { uniq, uniqBy } from 'lodash'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import {
  getAllContactsFromTypesense,
  getContactsFromTypesense,
} from 'api/contacts'
import { ensureTypesenseContactsHaveMissingFields } from 'api/contacts/getContacts'
import {
  contactsCacheAtom,
  contactsCacheErrorDataAtom,
  contactsCacheLoadingDataAtom,
  idCacheAtom,
} from './useContactsCache'

function useSetContactsCache() {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [, setCachedContacts] = useRecoilState(contactsCacheAtom)
  const [, setLoading] = useRecoilState(contactsCacheLoadingDataAtom)
  const [cachedIds, setCachedIds] = useRecoilState(idCacheAtom)

  const [, setError] = useRecoilState(contactsCacheErrorDataAtom)

  useEffect(() => {
    if (authenticatedUser.selectedCompany) {
      const secrets = authenticatedUser.secrets.find(
        secret => secret.companyId === authenticatedUser.selectedCompany.id,
      )

      setLoading({
        isLoadingFirstTime: true,
        isLoadingFinalTime: true,
      })

      const fieldsToInclude = [
        'id',
        'firstName',
        'lastName',
        'email',
        'phone',
        'photoURL',
        'associationIds',
        'propertyInfo',
        'companyName',
      ]

      const fieldsToExclude = [
        'propertyInfo.acl', // don't need this atm
      ]

      // Using Typesense instead:

      // Get all the initial contacts, set the cache, and then get the rest:
      getContactsFromTypesense(
        {
          companyId: authenticatedUser.selectedCompany.id,
          associationIds: authenticatedUser.selectedContact.associationIds,
          includeFields: fieldsToInclude,
          excludeFields: fieldsToExclude,
          page: 1,
          pageSize: 250,
        },
        secrets,
      )
        .then(res => {
          setLoading({
            isLoadingFirstTime: false,
            isLoadingFinalTime: true,
          })
          const firstContacts = ensureTypesenseContactsHaveMissingFields(
            res.contacts,
          )
          setCachedContacts(uniqBy([...res.contacts], 'id'))
          setCachedIds(
            uniq([
              ...cachedIds,
              ...authenticatedUser.selectedContact.associationIds,
            ]),
          )

          getAllContactsFromTypesense(
            {
              companyId: authenticatedUser.selectedCompany.id,
              associationIds: authenticatedUser.selectedContact.associationIds,
              includeFields: fieldsToInclude,
              excludeFields: fieldsToExclude,
              page: 2,
            },
            secrets,
          )
            .then(contacts => {
              setCachedContacts(
                uniqBy(
                  [
                    ...firstContacts,
                    ...ensureTypesenseContactsHaveMissingFields(contacts),
                  ],
                  'id',
                ),
              )
              setLoading({
                isLoadingFirstTime: false,
                isLoadingFinalTime: false,
              })
              // do we still need cachedIds? where is this used?
              setCachedIds(
                uniq([
                  ...cachedIds,
                  ...authenticatedUser.selectedContact.associationIds,
                ]),
              )
            })
            .catch(error => {
              setError(true)
              setLoading({
                isLoadingFirstTime: false,
                isLoadingFinalTime: false,
              })
            })
        })
        .catch(error => {
          setError(true)
          setLoading({
            isLoadingFirstTime: false,
            isLoadingFinalTime: false,
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser.selectedCompany.id])

  return null
}

export default useSetContactsCache
