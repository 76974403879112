import {
  AccessLevel,
  APIContact,
  Feature,
  isAllowed,
  PropertyInfo,
  ResourceType,
  Action,
} from '@super-software-inc/foundation'
import { updateContact } from 'api/contacts'
import { Divider, FlexRow, RadioButton } from 'components/lib'
import { MultilevelItem } from 'components/lib/MultilevelDropdown'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import { toastSuccess } from 'components/lib/Toast'
import React, { useEffect, useMemo, useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, profileModalAtom } from 'state/atoms'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import styled from 'styled-components/macro'
import { primaryAssociationSelector } from '../../../AppRoutes'

interface AccessDropdownProps {
  contact: APIContact
  isSidebar?: boolean
  associationId?: string
  isCorpSidebar?: boolean
}

const AccessItemDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 23px;
  color: ${props => props.theme.colors.text200};
  text-wrap: wrap;
  font-size: 14px;
  font-weight: 300;
`

const AccessItem = ({
  onChange,
  currentAccessLevel,
  label,
  itemValue,
  description,
}: {
  onChange: Function
  currentAccessLevel: string
  label: string
  itemValue: string // actId
  description: string
}) => (
  <StyledMenuItem
    key={itemValue}
    onClick={() => onChange(itemValue)}
    value={itemValue}
  >
    <div>
      <FlexRow align="center">
        <RadioButton
          value={itemValue}
          comparator={currentAccessLevel}
          label=""
        />
        <h4 style={{ marginLeft: -3 }}>{label}</h4>
      </FlexRow>
      <AccessItemDescription style={{ width: 250 }}>
        {description}
      </AccessItemDescription>
    </div>
  </StyledMenuItem>
)

const AccessDropdown: React.FC<AccessDropdownProps> = ({
  contact,
  isSidebar = false, // if it's in the sidebar it has diff styling
  associationId,
  isCorpSidebar,
}) => {
  const [newActId, setNewActId] = useState<string | null>(null)

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const primAssociationId = useRecoilValue(primaryAssociationSelector)?.id
  const primaryAssociationId = associationId || primAssociationId
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  const actLabels = (actId: string) => {
    const a = authenticatedUser.acts.find(act => act.id === actId)
    switch (a?.name) {
      case AccessLevel.AdminAccess:
        return 'Admin access'
      case AccessLevel.FullAccess:
        return 'Full access'
      case AccessLevel.StandardAccess:
        return 'Standard access'
      case AccessLevel.NoAccess:
        return 'Notifications only'
      default:
        return 'Custom access'
    }
  }

  const contactPropertyInfo = useMemo(
    () =>
      contact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === primaryAssociationId,
      ),

    [contact, primaryAssociationId],
  )

  useEffect(() => {
    setNewActId(null)
  }, [contact.id])

  const updateAccessLevel = async () => {
    if (newActId !== null) {
      const propertyInfo = contact.propertyInfo.map(p =>
        p.associationId === primaryAssociationId
          ? {
              ...p,
              accessLevel: authenticatedUser.acts.find(
                act => act.id === newActId,
              )?.name as AccessLevel,
              actId: newActId,
            }
          : p,
      )

      await updateContact({
        ...contact,
        propertyInfo,
      })

      if (contact.id === profileModal.selectedContact.id) {
        setProfileModal({
          ...profileModal,
          selectedContact: {
            ...profileModal.selectedContact,
            propertyInfo,
          },
        })
      }

      setNewActId(null)
      toastSuccess('Access level updated.')
    }
  }
  const style = isSidebar
    ? {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 12,
        paddingRight: 10,
        borderRadius: 20,
        border: '1px #E1E4E8 solid',
        fontSize: 12,
        justifyContent: 'center',
      }
    : isCorpSidebar
    ? {
        paddingBottom: 3,
        paddingRight: 10,
        marginBottom: 15,
        borderBottom: '1px #E1E4E8 solid',
        justifyContent: 'flex-start',
        color: '#627088',
        width: 'fit-content',
      }
    : {
        height: 'unset',
        fontSize: 14,
        justifyContent: 'flex-start',
      }

  if (
    !authenticatedUser ||
    (!isCorpSidebar &&
      !isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        [primaryAssociationId],
        ResourceType.PROPERTY,
        Feature.CONTACTS,
        Action.EDIT,
      ))
  ) {
    return (
      <FlexRow align="center" style={style} className="text-slate-500">
        {contactPropertyInfo && actLabels(contactPropertyInfo.actId)}
      </FlexRow>
    )
  }

  return contactPropertyInfo?.accessLevel ? (
    <StyledSelect
      selectStyle="textButton"
      renderValue={() => (
        <FlexRow align="center" style={{ ...style, fontSize: 14 }}>
          {contactPropertyInfo && actLabels(contactPropertyInfo.actId)}
          <MdOutlineKeyboardArrowDown style={{ marginLeft: 5 }} />
        </FlexRow>
      )}
      defaultValue={undefined}
    >
      <AccessItem
        onChange={setNewActId}
        currentAccessLevel={
          newActId != null ? newActId : contactPropertyInfo.actId
        }
        label="Full access"
        itemValue={
          authenticatedUser.acts.find(
            act => act.name === AccessLevel.FullAccess,
          )?.id || ''
        }
        description="Can manage this property, including visibility on all tools and tasks."
      />
      <AccessItem
        onChange={setNewActId}
        currentAccessLevel={
          newActId != null ? newActId : contactPropertyInfo.actId
        }
        label="Standard access"
        itemValue={
          authenticatedUser.acts.find(
            act => act.name === AccessLevel.StandardAccess,
          )?.id || ''
        }
        description="Can view property information, manage their profile, and tasks they are subscribed to."
      />
      <AccessItem
        onChange={setNewActId}
        currentAccessLevel={
          newActId != null ? newActId : contactPropertyInfo.actId
        }
        label="Notifications only"
        itemValue={
          authenticatedUser.acts.find(act => act.name === AccessLevel.NoAccess)
            ?.id || ''
        }
        description="Can communicate by email and text message, but cannot login."
      />
      {newActId != null && (
        <div>
          <Divider style={{ marginBottom: 7, marginTop: 7 }} />
          <MultilevelItem
            onClick={e => {
              e.stopPropagation()
              updateAccessLevel()
            }}
          >
            <FlexRow justify="center">Confirm change</FlexRow>
          </MultilevelItem>
        </div>
      )}
    </StyledSelect>
  ) : null
}

export default AccessDropdown
