import {
  ContactGroup,
  PropertyRelation,
  APIContact,
  UnitRelation,
  ContactUnit,
  isAllowed,
  ResourceType,
  Feature,
  Action,
} from '@super-software-inc/foundation'
import { FAB, MultilevelDropdown, MultilevelItem } from 'components/lib'
import React from 'react'
import { MdAdd } from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  getDefaultAPIContact,
  getDefaultPropertyInfo,
  profileModalAtom,
  unitActionsAtom,
} from 'state/atoms'
import { primaryAssociationSelector } from '../../AppRoutes'

const AddMenu = () => {
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const [unitActions, setUnitActions] = useRecoilState(unitActionsAtom)
  const primaryAssocation = useRecoilValue(primaryAssociationSelector)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  // NOTE - this assumes only 1 association can be selected to view the Add Menu
  const newVendor: APIContact = {
    ...getDefaultAPIContact(),
    propertyInfo: [
      {
        ...getDefaultPropertyInfo(),
        associationId: primaryAssocation.id,
        groups: [ContactGroup.Vendors],
        propertyRelation: PropertyRelation.Vendor,
      },
    ],
  }

  const newResidentUnit = { relationship: UnitRelation.Resident } as ContactUnit

  const newResident: APIContact = {
    ...getDefaultAPIContact(),
    propertyInfo: [
      {
        ...getDefaultPropertyInfo(),
        associationId: primaryAssocation.id,
        groups: [ContactGroup.Residents],
        units: [newResidentUnit],
      },
    ],
  }

  const newStaff: APIContact = {
    ...getDefaultAPIContact(),
    propertyInfo: [
      {
        ...getDefaultPropertyInfo(),
        associationId: primaryAssocation.id,
        groups: [ContactGroup.Staff],
        propertyRelation: PropertyRelation.Staff,
      },
    ],
  }

  return (
    <MultilevelDropdown trigger={<FAB />} title={<MdAdd size={20} />}>
      {isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        [primaryAssocation.id],
        ResourceType.PROPERTY,
        Feature.CONTACTS,
        Action.CREATE,
      ) && (
        <>
          <MultilevelItem
            onClick={() => {
              setProfileModal({
                ...profileModal,
                editProfileIsOpen: true,
                selectedContact: newResident,
              })
            }}
          >
            Add owner, renter, resident
          </MultilevelItem>

          <MultilevelItem
            onClick={() => {
              setProfileModal({
                ...profileModal,
                editProfileIsOpen: true,
                selectedContact: newStaff,
              })
            }}
          >
            Add staff
          </MultilevelItem>
          <MultilevelItem
            onClick={() => {
              setProfileModal({
                ...profileModal,
                editProfileIsOpen: true,
                selectedContact: newVendor,
              })
            }}
          >
            Add vendor
          </MultilevelItem>
        </>
      )}
      {isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        [primaryAssocation.id],
        ResourceType.PROPERTY,
        Feature.UNITS,
        Action.EDIT,
      ) && (
        <MultilevelItem
          onClick={() => {
            setUnitActions({
              ...unitActions,
              edit: {
                isOpen: true,
                selectedUnit: undefined,
              },
            })
          }}
        >
          Add unit
        </MultilevelItem>
      )}
    </MultilevelDropdown>
  )
}

export default AddMenu
