import React, { useMemo, useState } from 'react'
import { Workspace } from '@super-software-inc/foundation'
import { MultilevelItem, TruncatedText, FlexRow } from 'components/lib'
import StyledSelectContainer from 'components/lib/MaterialElements/StyledSelect'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { MultilevelNoResults } from 'components/lib/MultilevelDropdown'
import SelectSearchBar from 'components/lib/MaterialElements/SelectSearchBar'

export const workspaceOptions = [
  {
    name: 'General',
    color: '#8A94A6',
    value: null,
  },
  {
    name: 'Work orders',
    color: '#0B96EC',
    value: Workspace.WorkOrders,
  },
  {
    name: 'Compliance',
    color: '#EB7738',
    value: Workspace.Compliance,
  },
]

const WorkspaceSelector = ({
  value,
  onChange,
  isDisabled = false,
  isTaskSheet = true,
}: {
  value: Workspace | null
  onChange: Function
  isDisabled: boolean
  isTaskSheet?: boolean
}) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const options = useMemo(
    () =>
      isTaskSheet
        ? workspaceOptions.filter(option =>
            option.name.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : workspaceOptions
            .slice(1)
            .filter(option =>
              option.name.toLowerCase().includes(searchValue.toLowerCase()),
            ),
    [searchValue, isTaskSheet],
  )

  const toggleOption = (option: Workspace | null) => {
    onChange(option)
    setSearchValue('')
  }

  return (
    <StyledSelectContainer
      disabled={isDisabled}
      defaultValue={value}
      icon={!isTaskSheet ? KeyboardArrowDownOutlinedIcon : undefined}
      renderValue={() => (
        <TruncatedText>
          <FlexRow
            align="center"
            justify={isTaskSheet ? 'center' : 'flex-start'}
          >
            {(isTaskSheet || value) && (
              <span
                style={{
                  backgroundColor:
                    options.find(w => w.value === value)?.color || '#8A94A6',
                  width: 6,
                  height: 6,
                  borderRadius: 6,
                  marginRight: 6,
                }}
              />
            )}

            {!isTaskSheet && !value ? (
              <span style={{ fontWeight: 400 }}>Select workspace</span>
            ) : value != null ? (
              options.find(w => w.value === value)?.name
            ) : (
              'General'
            )}
          </FlexRow>
        </TruncatedText>
      )}
      selectStyle={isTaskSheet ? 'pill' : 'rectangle'}
    >
      <SelectSearchBar
        placeholder="Search workspaces"
        setSearchText={setSearchValue}
      />

      {options.map(option => (
        <MultilevelItem
          key={option.name}
          onClick={() => toggleOption(option.value)}
        >
          <FlexRow align="center" style={{ width: 150 }}>
            <span
              style={{
                backgroundColor: option.color,
                width: 8,
                height: 8,
                borderRadius: 8,
                marginRight: 10,
              }}
            />

            {option.name}
          </FlexRow>
        </MultilevelItem>
      ))}
      {options.filter(option =>
        option.name.toLowerCase().includes(searchValue.toLowerCase()),
      ).length === 0 && <MultilevelNoResults />}
    </StyledSelectContainer>
  )
}
export default WorkspaceSelector
