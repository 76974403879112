import {
  Action,
  ContactGroup,
  Feature,
  isAllowed,
  ResourceType,
} from '@super-software-inc/foundation'
import HOAEmployees from 'components/app/Directory/HOAEmployees'
import HOAMembers from 'components/app/Directory/HOAMembers'
import Residents from 'components/app/Directory/Residents'
import UncategorizedContacts from 'components/app/Directory/UncategorizedContacts'
import Vendors from 'components/app/Directory/Vendors'
import VirtualizedContactTable from 'components/app/Directory/VirtualizedContactTable'
import { FlexRow } from 'components/lib'
import Search from 'components/lib/Search'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  currentContactTabAtom,
  profileModalAtom,
} from 'state/atoms'
import LoadingIcon from 'components/lib/LoadingIcon'
import {
  contactsCacheAtom,
  contactsCacheLoadingDataAtom,
} from 'hooks/useContactsCache'
import { orderBy } from 'lodash'
import { primaryAssociationSelector } from '../../AppRoutes'

export interface ContactTabProps {
  groups: ContactGroup[]
  includeLocation?: boolean
  includeType?: boolean
  includeCompany?: boolean
  alwaysAutocreated?: boolean
  exhaustive?: true
}

const tabGroups: ContactTabProps[] = [
  {
    groups: [
      ContactGroup.Board,
      ContactGroup.Owners,
      ContactGroup.Renters,
      ContactGroup.Residents,
      ContactGroup.PastOwners,
      ContactGroup.PastRenters,
      ContactGroup.PastResidents,
      ContactGroup.Sponsors,
    ],
    includeLocation: true,
  },
  {
    groups: [ContactGroup.Board],
  },
  {
    groups: [
      ContactGroup.Management,
      ContactGroup.Sponsors,
      ContactGroup.Staff,
    ],
    includeCompany: true,
  },
  {
    groups: [ContactGroup.Vendors],
    includeCompany: true,
  },
  {
    groups: [ContactGroup.Uncategorized],
    alwaysAutocreated: true,
  },
  {
    groups: Object.values(ContactGroup),
    exhaustive: true,
  },
]

const PropFirstContactsTable = ({
  currentContactTab,
}: {
  currentContactTab: number
}) => {
  const params = useParams()
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const [initialContactHandled, setInitialContactHandled] = useState(false)

  const canViewContacts = useMemo(
    () =>
      isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        [primaryAssociation.id],
        ResourceType.PROPERTY,
        Feature.CONTACTS,
        Action.VIEW,
      ),
    [
      authenticatedUser.selectedContact,
      primaryAssociation.id,
      authenticatedUser.acts,
    ],
  )

  const contactsCache = useRecoilValue(contactsCacheAtom)
  const isLoading = useRecoilValue(contactsCacheLoadingDataAtom)

  useEffect(() => {
    if (
      !initialContactHandled &&
      params?.contactId &&
      contactsCache &&
      contactsCache.length > 0
    ) {
      const contact = contactsCache.find(c => c.id === params?.contactId)

      if (contact) {
        setProfileModal({
          ...profileModal,
          selectedContact: contact,
          sidebarIsOpen: true,
        })

        setInitialContactHandled(true)
      }
    }
    // eslint-disable-next-line
  }, [params?.contactId, contactsCache, initialContactHandled])

  const filteredContacts = useMemo(
    () =>
      orderBy(
        (contactsCache || []).filter(({ propertyInfo }) => {
          const currentProperty = propertyInfo.find(
            p =>
              p.associationId === primaryAssociation.id &&
              p.groups.some(group =>
                tabGroups[currentContactTab].groups.includes(group),
              ),
          )
          return tabGroups[currentContactTab].groups.some(group =>
            currentProperty?.groups?.includes(group),
          )
        }),
        ['firstName', 'lastName'],
        ['asc', 'asc'],
      ),
    [contactsCache, primaryAssociation.id, currentContactTab],
  )

  if (isLoading.isLoadingFinalTime) {
    return (
      <FlexRow justify="center" style={{ marginTop: -500 }}>
        <LoadingIcon />
      </FlexRow>
    )
  }

  return (
    <div>
      {currentContactTab === 0 && (
        <Residents
          contacts={filteredContacts}
          isLoading={isLoading.isLoadingFirstTime}
          corpFirst={false}
        />
      )}
      {currentContactTab === 1 && (
        <HOAMembers
          contacts={filteredContacts}
          isLoading={isLoading.isLoadingFirstTime}
          corpFirst={false}
        />
      )}
      {canViewContacts && currentContactTab === 2 && (
        <HOAEmployees
          contacts={filteredContacts}
          isLoading={isLoading.isLoadingFirstTime}
          corpFirst={false}
        />
      )}
      {canViewContacts && currentContactTab === 3 && (
        <Vendors
          contacts={filteredContacts}
          isLoading={isLoading}
          corpFirst={false}
        />
      )}
      {canViewContacts && currentContactTab === 4 && (
        <UncategorizedContacts
          contacts={filteredContacts}
          isLoading={isLoading.isLoadingFirstTime}
          corpFirst={false}
        />
      )}
    </div>
  )
}

const ContactsTable = ({ corpFirst }: { corpFirst?: boolean }) => {
  const [searchText, setSearchText] = useState('')
  const currentContactTab = useRecoilValue(currentContactTabAtom)

  return (
    <div>
      {corpFirst && (
        <FlexRow
          justify="flex-end"
          style={{ zIndex: 11, marginTop: -40, position: 'fixed', right: 10 }}
        >
          <Search
            text={searchText}
            changeText={setSearchText}
            placeholder="Search contacts"
          />
        </FlexRow>
      )}

      {corpFirst ? (
        <VirtualizedContactTable
          tab={tabGroups[currentContactTab]}
          searchText={searchText}
        />
      ) : (
        <PropFirstContactsTable currentContactTab={currentContactTab} />
      )}
    </div>
  )
}

export default ContactsTable
