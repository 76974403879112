// write a new atom to store a knowledge base
// write a form to edit or add a new knowledge base
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { APIKnowledgeBase } from '@super-software-inc/foundation'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {
  FlexRow,
  PrimaryButton,
  TextAreaInput,
  TextInput,
} from 'components/lib'
import { omit } from 'lodash'
import {
  createKnowledgeBase,
  updateKnowledgeBase,
} from 'api/assistant/knowledgeBase'
import { toastError, toastSuccess } from 'components/lib/Toast'
import FixedBottomButtons from 'components/lib/Forms/FixedBottomButtons'

import {
  allKnowledgeBasesAtom,
  authenticatedUserAtom,
  knowledgeBaseFormAtom,
} from '../../../state/atoms'

import PropertySelector from '../Tasks/PropertySelector'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  prompt: Yup.string().required('Prompt is required'),
  associationIds: Yup.array()
    .of(Yup.string())
    .required('Association is required'),
})

const KnowledgeBaseForm = () => {
  const [knowledgeBaseForm, setKnowledgeBaseForm] = useRecoilState(
    knowledgeBaseFormAtom,
  )
  const [, setAllKnowledgeBases] = useRecoilState(allKnowledgeBasesAtom)
  const { selectedContact } = useRecoilValue(authenticatedUserAtom)

  const handleSubmit = async (values: Partial<APIKnowledgeBase>) => {
    const updatedKnowledgeBase = {
      ...(knowledgeBaseForm.knowledgeBase || {}),
      ...omit(values, 'id', 'createdAt', 'updatedAt'),
    }

    if (!selectedContact?.companyId) return

    try {
      if (knowledgeBaseForm.knowledgeBase.id) {
        // Update existing knowledge base
        const updatedKB = await updateKnowledgeBase(
          selectedContact.companyId,
          knowledgeBaseForm.knowledgeBase.id,
          updatedKnowledgeBase,
        )

        setAllKnowledgeBases(prevKBs =>
          prevKBs.map(kb =>
            kb.id === knowledgeBaseForm.knowledgeBase.id ? updatedKB : kb,
          ),
        )

        toastSuccess('Knowledge base updated')
      } else {
        // Create new knowledge base
        const newKB = await createKnowledgeBase(
          selectedContact.companyId,
          updatedKnowledgeBase,
        )

        setAllKnowledgeBases(prevKBs => [...prevKBs, newKB])
        toastSuccess('Knowledge base created')
      }

      setKnowledgeBaseForm({
        knowledgeBase: {},
        isOpen: false,
      })
    } catch (error: any) {
      toastError(error?.message || 'Error saving knowledge base')
    }
  }

  const initialValues = {
    title: knowledgeBaseForm.knowledgeBase?.title || '',
    prompt: knowledgeBaseForm.knowledgeBase?.prompt || '',
    associationIds: knowledgeBaseForm.knowledgeBase?.associationIds || [],
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values, setFieldValue, isSubmitting }) => (
        <Form className="bg-white rounded-lg  ">
          <div className="max-w-[500px] p-6 space-y-6">
            <h2 className="text-lg font-semibold text-gray-900">
              {knowledgeBaseForm.knowledgeBase.id
                ? 'Edit knowledge base'
                : 'Add knowledge base'}
            </h2>
            <div className="space-y-1">
              <label
                htmlFor="title"
                className="block text-sm font-semibold text-gray-900"
              >
                Title
              </label>
              <Field
                id="title"
                name="title"
                as={TextInput}
                type="text"
                placeholder="Add a title"
              />
              {errors.title && touched.title && (
                <div className="text-sm text-red-600">{errors.title}</div>
              )}
            </div>

            <div className="space-y-1">
              <label
                htmlFor="associationIds"
                className="block text-sm font-semibold text-gray-900"
              >
                Accounts
              </label>

              <p className="text-[#627088] text-xs font-normal font-['Inter'] leading-[18px]">
                Leave blank if this knowledge base is for your entire company.
                If it is limited to specific properties, select them below.
              </p>

              <PropertySelector
                width="100%"
                value={values.associationIds as string[]}
                onChange={(associationIds: string[]) => {
                  setFieldValue('associationIds', associationIds)
                }}
                allowMultiSelect
                isTaskSheet={false}
                includeCompany={false}
                placeholder="Company-wide"
              />

              {errors.associationIds && touched.associationIds && (
                <div className="text-sm text-red-600">
                  {errors.associationIds}
                </div>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="prompt"
                className="block text-sm font-semibold text-gray-900"
              >
                Knowledge
              </label>
              <Field
                id="prompt"
                name="prompt"
                as={TextAreaInput}
                type="text"
                placeholder="Add knowledge"
                maxLength={5000}
              />

              {errors.prompt && touched.prompt && (
                <div className="text-sm text-red-600">{errors.prompt}</div>
              )}
              <div className="h-[18px] justify-start items-start gap-2 inline-flex">
                <div className="grow shrink basis-0 h-[18px] justify-start items-start gap-1 flex">
                  <div className="text-[#627088] text-xs font-normal font-['Inter'] leading-[18px]">
                    Limit: {(values.prompt?.length || 0).toLocaleString()}/5,000
                    characters
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FixedBottomButtons>
            <FlexRow style={{ gap: 10 }} justify="flex-end">
              <PrimaryButton
                type="button"
                light
                onClick={() => {
                  setKnowledgeBaseForm({
                    knowledgeBase: {},
                    isOpen: false,
                  })
                }}
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                {knowledgeBaseForm.knowledgeBase.id
                  ? 'Update knowledge base'
                  : 'Add knowledge base'}
              </PrimaryButton>
            </FlexRow>
          </FixedBottomButtons>
        </Form>
      )}
    </Formik>
  )
}

export default KnowledgeBaseForm
