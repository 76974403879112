import { Chip } from '@mui/material'
import {
  FlexRow,
  InputGroup,
  Label,
  PrimaryButton,
  RadioButton,
  TextAreaInput,
  TextButton,
} from 'components/lib'
import StyledSlider from 'components/lib/Forms/Slider'
import { Field, Formik } from 'formik'
import React from 'react'
import {
  AssistantConfiguration,
  AssistantTone,
  AssistantVoice,
} from '@super-software-inc/foundation'
import { updateAssistantConfiguration } from 'api/assistant/knowledgeBase'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { useRecoilState, useRecoilValue } from 'recoil'
import { assistantConfigAtom, authenticatedUserAtom } from 'state/atoms'
import shimmer from 'assets/audio/shimmer.m4a'
import alloy from 'assets/audio/alloy.m4a'
import echo from 'assets/audio/echo.m4a'
import FixedBottomButtons from 'components/lib/Forms/FixedBottomButtons'

const toneChoices = [
  AssistantTone.Professional,
  AssistantTone.Formal,
  AssistantTone.Direct,
  AssistantTone.Polite,
  AssistantTone.Personable,
  AssistantTone.Casual,
  AssistantTone.Friendly,
]

const assistantVoiceChoices = [
  {
    value: AssistantVoice.Alloy,
    label: 'Sloane',
    description: 'Sounds calm and speaks in an even tone',
    audio: alloy,
  },
  {
    value: AssistantVoice.Shimmer,
    label: 'Sadie',
    description: 'Sounds confident and speaks with authority',
    audio: shimmer,
  },
  {
    value: AssistantVoice.Echo,
    label: 'Shawn',
    description: 'Sounds approachable and speaks in a relaxed tone',
    audio: echo,
  },
]

const toSentenceCase = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

const CustomizeAgent = ({
  initialValues,
  isLoading,
}: {
  initialValues: Partial<AssistantConfiguration>
  isLoading: boolean
}) => {
  const { selectedContact } = useRecoilValue(authenticatedUserAtom)
  const [, setAssistantConfig] = useRecoilState(assistantConfigAtom)
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true)
            const updatedConfig = await updateAssistantConfiguration(
              selectedContact.companyId,
              values,
            )
            setAssistantConfig(updatedConfig)
            toastSuccess('Assistant configuration updated')
          } catch (error: any) {
            toastError(
              error.message || 'Error updating assistant configuration',
            )
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setValues }) => (
          <form style={{ marginTop: 20 }} onSubmit={handleSubmit}>
            <InputGroup>
              <Label>Can not answer</Label>
              <TextAreaInput
                name="disallowedTopics"
                placeholder="Describe what your assistant should not answer"
                value={values.disallowedTopics}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup>
              <Label>Can answer</Label>
              <TextAreaInput
                name="allowedTopics"
                placeholder="Describe what your assistant is allowed to answer"
                value={values.allowedTopics}
                onChange={handleChange}
              />
            </InputGroup>

            <InputGroup>
              <Label>Greeting used for calls</Label>
              <TextAreaInput
                name="greeting"
                placeholder="Greeting used for calls"
                value={values.greeting}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup>
              <FlexRow align="center">
                <Label>Creativity:</Label>
                <div className="flex w-[280px] ml-10">
                  <StyledSlider
                    value={values.temperature ? -values.temperature : undefined}
                    valueLabelDisplay="auto"
                    shiftStep={0.0875}
                    step={0.0875}
                    valueLabelFormat={
                      values.temperature === 0.85
                        ? 'Most creative'
                        : values.temperature === 0.7625
                        ? 'Creative'
                        : values.temperature === 0.675
                        ? 'Balanced'
                        : values.temperature === 0.5875
                        ? 'Predictable'
                        : 'Most predictable'
                    }
                    marks
                    min={-0.85}
                    max={-0.5}
                    onChange={(_, n) => {
                      handleChange({
                        target: { name: 'temperature', value: -n },
                      })
                    }}
                  />
                </div>
              </FlexRow>
            </InputGroup>
            <InputGroup>
              <Label>Tone</Label>

              {toneChoices.map(tone => (
                <Chip
                  key={tone}
                  label={toSentenceCase(tone)}
                  style={{
                    margin: 5,
                    backgroundColor: values.tone?.includes(tone)
                      ? '#0A1F44'
                      : 'white',
                    color: values.tone?.includes(tone) ? 'white' : '#627088',
                  }}
                  variant={values.tone?.includes(tone) ? 'filled' : 'outlined'}
                  onClick={() => {
                    const newTones = values.tone?.includes(tone)
                      ? values.tone?.filter(t => t !== tone)
                      : values.tone
                      ? [...values.tone, tone]
                      : [tone]
                    handleChange({
                      target: { name: 'tone', value: newTones },
                    })
                  }}
                />
              ))}
            </InputGroup>
            <InputGroup>
              <Label>Voice used for calls</Label>

              {assistantVoiceChoices.map(
                ({ value, label, description, audio }) => (
                  <FlexRow align="center" key={value}>
                    <Field
                      key={value}
                      value={value}
                      comparator={values.voice}
                      name="voice"
                      label={label}
                      as={RadioButton}
                    />
                    <p className="text-[#627088] text-sm font-normal font-['Inter'] leading-tight pl-2">
                      {description}
                      <TextButton
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          const preview = new Audio(audio)
                          preview.play()
                        }}
                        style={{
                          marginLeft: 8,
                          color: '#627088',
                          fontSize: 14,
                          textDecoration: 'underline',
                        }}
                      >
                        Preview
                      </TextButton>
                    </p>
                  </FlexRow>
                ),
              )}
            </InputGroup>

            <FixedBottomButtons>
              <FlexRow style={{ gap: 10 }} justify="flex-end">
                <PrimaryButton
                  disabled={isSubmitting}
                  type="button"
                  light
                  onClick={() => {
                    // reset the form
                    setValues(initialValues)
                  }}
                >
                  Cancel
                </PrimaryButton>
                <PrimaryButton type="submit">
                  {isSubmitting ? 'Saving...' : 'Save customization'}
                </PrimaryButton>
              </FlexRow>
            </FixedBottomButtons>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CustomizeAgent
