import * as Sentry from '@sentry/react'
import {
  Action,
  APIUnit,
  Feature,
  isAllowed,
  ResourceType,
} from '@super-software-inc/foundation'
import { getUnitsByAssociationId } from 'api/units'
import SinglePropertySelectorPage from 'components/app/SinglePropertySelectorPage'
import { UnitForm, UnitProfileSidebar } from 'components/app/Units'
import {
  Drawer,
  FlexRow,
  IconButton,
  Modal,
  MultilevelDropdown,
  MultilevelItem,
} from 'components/lib'
import Tabs from 'components/lib/Navigation/Tabs'
import { toastError, toastSuccess } from 'components/lib/Toast'
import {
  addDoc,
  collection,
  deleteField,
  doc,
  updateDoc,
} from 'firebase/firestore'
import React, { useCallback, useEffect, useState } from 'react'
import { MdMoreHoriz } from 'react-icons/md'
import { Route, Routes, useNavigate } from 'react-router'
import { useFirestore } from 'reactfire'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  authenticatedUserAtom,
  companyUnitsAtom,
  currentAssociationTabAtom,
  currentContactTabAtom,
  profileModalAtom,
  unitActionsAtom,
} from 'state/atoms'
import _ from 'lodash'
import AssociationDropdown from 'components/app/Dropdowns/PageTitleAssociationDropdown'
import {
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
} from '../../AppRoutes'
import { PageHeader } from '../Tasks'
import AddMenu from './AddMenu'
import ContactsTable from './ContactsTable'
import InformationPage from './Information'
import UnitsPage from './Units'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const selectInitialTab = (path: string | undefined | null) => {
  if (path?.includes('/directory')) {
    return 1
  }

  if (path?.includes('/units')) {
    return 2
  }

  return 0
}

const AssociationDetailPage = () => {
  const navigate = useNavigate()
  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )

  const [currentAssociationTab, setCurrentAssociationTab] = useRecoilState(
    currentAssociationTabAtom,
  )

  useEffect(() => {
    setCurrentAssociationTab(selectInitialTab(window.location.pathname))
    // eslint-disable-next-line
  }, [])
  const [currentContactTab, setCurrentContactTab] = useRecoilState<number>(
    currentContactTabAtom,
  )
  const firestore = useFirestore()
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const [unitActions, setUnitActions] = useRecoilState(unitActionsAtom)
  const resetUnitActions = useResetRecoilState(unitActionsAtom)
  const [units, setUnits] = useState<APIUnit[] | undefined>()
  const [companyUnits, setCompanyUnits] = useRecoilState(companyUnitsAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const fetchUnits = (ignore: boolean) => {
    getUnitsByAssociationId(primaryAssociation.id).then(apiUnits => {
      if (!ignore) {
        setUnits(apiUnits)
        const newCompanyUnitsMap = companyUnits
        newCompanyUnitsMap.set(primaryAssociation.id, apiUnits)
        setCompanyUnits(newCompanyUnitsMap)
      }
    })
  }

  useEffect(() => {
    let ignore = false
    // only fetch units if on tab 2 / units and if feature flag enabled
    if (!primaryAssociation || currentAssociationTab !== 2) {
      return () => {
        ignore = true
      }
    }

    if (companyUnits.has(primaryAssociation.id)) {
      setUnits(companyUnits.get(primaryAssociation.id) as APIUnit[])
    } else {
      fetchUnits(ignore)
    }

    return () => {
      ignore = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryAssociation, currentAssociationTab])

  const resetProfileModal = useResetRecoilState(profileModalAtom)

  useEffect(() => {
    resetProfileModal()
    resetUnitActions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssociationChoices, currentAssociationTab])

  const getUnitActionDropdown = useCallback(
    (unit: APIUnit) => (
      <MultilevelDropdown trigger={<IconButton />} title={<MdMoreHoriz />}>
        <MultilevelItem
          onClick={() => {
            setUnitActions({
              ...unitActions,
              view: {
                isOpen: true,
                selectedUnit: unit,
              },
            })
          }}
        >
          View unit
        </MultilevelItem>
        <MultilevelItem
          onClick={() => {
            window.navigator.clipboard.writeText(
              `${window.location.origin}/association/units/${unit.id}`,
            )
            toastSuccess(`Link copied.`)
          }}
        >
          Copy link
        </MultilevelItem>

        <MultilevelItem
          onClick={() => {
            setUnitActions({
              ...unitActions,
              edit: {
                isOpen: true,
                selectedUnit: unit,
              },
            })
          }}
        >
          Edit unit
        </MultilevelItem>
      </MultilevelDropdown>
    ),
    [unitActions, setUnitActions],
  )

  const tabs = [
    {
      id: 'details',
      name: 'Property details',
      path: './',
    },
  ]
  if (
    isAllowed(
      authenticatedUser.selectedContact,
      authenticatedUser.acts,
      [primaryAssociation.id],
      ResourceType.PROPERTY,
      Feature.CONTACTS,
      Action.VIEW,
    )
  ) {
    tabs.push({
      id: 'contacts',
      name: 'Contacts',
      path: './directory',
    })
  }
  if (
    isAllowed(
      authenticatedUser.selectedContact,
      authenticatedUser.acts,
      [primaryAssociation.id],
      ResourceType.PROPERTY,
      Feature.UNITS,
      Action.VIEW,
    )
  ) {
    tabs.push({
      id: 'units',
      name: 'Units',
      path: './units',
    })
  }

  const contactTabs = [
    {
      name: 'Residents',
    },
    {
      name: 'Board',
    },
    {
      name: 'Management',
    },
    {
      name: 'Vendors',
    },
    {
      name: 'Auto-created',
    },
  ]

  const applyCurrentTab = (indexNumber: number) => {
    // Prevent users from navigating to the units tab if the feature flag is disabled
    navigate(tabs[indexNumber]?.path || './')
    setCurrentAssociationTab(indexNumber)
  }

  return (
    <>
      <PageHeader>
        <FlexRow
          align="center"
          justify="space-between"
          style={{ width: '100%' }}
        >
          <AssociationDropdown pageTitle="Property details" />

          {(isAllowed(
            authenticatedUser.selectedContact,
            authenticatedUser.acts,
            [primaryAssociation.id],
            ResourceType.PROPERTY,
            Feature.CONTACTS,
            Action.CREATE,
          ) ||
            isAllowed(
              authenticatedUser.selectedContact,
              authenticatedUser.acts,
              [primaryAssociation.id],
              ResourceType.PROPERTY,
              Feature.UNITS,
              Action.CREATE,
            )) && <AddMenu />}
        </FlexRow>
      </PageHeader>
      {selectedAssociationChoices.length !== 1 ? (
        <SinglePropertySelectorPage />
      ) : (
        <>
          <Tabs
            tabs={tabs}
            currentTab={currentAssociationTab}
            setCurrentTab={applyCurrentTab}
            customStyle="top-14"
          />
          {tabs[currentAssociationTab]?.id === 'contacts' && (
            <Tabs
              tabs={contactTabs}
              currentTab={currentContactTab}
              setCurrentTab={setCurrentContactTab}
              disableBottomBorder
              breakOnMobile
              breakOnTablet
              customStyle="top-14"
            />
          )}
          <SentryRoutes>
            <Route index element={<InformationPage />} />
            <Route path="directory" element={<ContactsTable />} />
            <Route path="directory/:contactId" element={<ContactsTable />} />
            <Route
              path="units"
              element={
                <UnitsPage
                  units={units}
                  getUnitActionDropdown={getUnitActionDropdown}
                />
              }
            />
            <Route
              path="units/:unitId"
              element={
                <UnitsPage
                  units={units}
                  getUnitActionDropdown={getUnitActionDropdown}
                />
              }
            />
          </SentryRoutes>

          <Modal
            isOpen={unitActions.edit.isOpen}
            onRequestClose={() => {
              setUnitActions({
                ...unitActions,
                edit: {
                  isOpen: false,
                  selectedUnit: undefined,
                },
              })
            }}
            style={{
              content: {
                top: '50%',
              },
            }}
          >
            <UnitForm
              units={units}
              initialValues={unitActions.edit.selectedUnit}
              associationId={primaryAssociation?.id}
              onSubmit={async submittedValues => {
                if (!primaryAssociation) return
                // Create a new unit.
                // TODO - move this to an api
                const unitsCollection = collection(
                  firestore,
                  'associations',
                  primaryAssociation.id,
                  'units',
                )

                // unitContacts is a subcollection and not an array field to a unit doc
                // so we must not send `unitContacts` over to firestore
                const { unitContacts, ...values } = submittedValues

                // assign certain default values
                if (!values.name) {
                  values.name = values.address?.line2 || values.address?.line1
                }

                if (values.id) {
                  // Update an existing unit.
                  try {
                    await updateDoc(doc(unitsCollection, values.id), {
                      ..._.omit(values, 'id', 'createdAt', 'updatedAt'),
                      photoURL: values.photoURL || deleteField(),
                    })
                    toastSuccess('Changes saved.')
                  } catch (error) {
                    // TODO - add better error messaging here
                    toastError('Error saving changes.')
                  }
                } else {
                  try {
                    await addDoc(unitsCollection, {
                      ...values,
                      associationId: primaryAssociation.id,
                    })
                    toastSuccess('Unit added successfully.')
                  } catch (error) {
                    toastError('Error adding unit.')
                  }
                }

                setUnitActions({
                  ...unitActions,
                  edit: {
                    isOpen: false,
                    selectedUnit: undefined,
                  },
                })

                // refetch units
                fetchUnits(false)
              }}
              onCancel={() => {
                setUnitActions({
                  ...unitActions,
                  edit: {
                    isOpen: false,
                    selectedUnit: undefined,
                  },
                })
              }}
            />
          </Modal>
          <Drawer isOpen={unitActions.view.isOpen} width={248}>
            {unitActions.view.selectedUnit && (
              <UnitProfileSidebar
                unit={unitActions.view.selectedUnit}
                actionDropdown={getUnitActionDropdown}
              />
            )}
          </Drawer>
        </>
      )}
    </>
  )
}

export default AssociationDetailPage
